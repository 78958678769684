<template>
  <main-layout>
    <div id="driver-request-view">
      <div class="row">
        <div class="col-md-4 left-part">
          <div class="left-content">
            <img
              src="/static/img/glp-logomark@2x.png"
              alt="GLP logo"
              class="logo center"
            />
            <h2>Visualização de Solicitação</h2>
            <p v-if="!request" style="text-align: center; margin-bottom: 30px">
              <b>{{
                isLoading ? 'Carregando...' : 'Nenhuma visita agendada'
              }}</b>
            </p>

            <div class="request-container" v-else>
              <DriverRequestViewItem
                :request="request"
                :status="status"
                :isReady="isReady"
              />
            </div>
          </div>
          <DriverFooter />
        </div>
        <div class="col-md-8 no-padding">
          <div
            class="image-box"
            style="background-image: url('./static/img/glp-main.png')"
          ></div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import requestMixin from '../../mixins/request.mixin.js'
import MainLayout from '../../layouts/Main.vue'
import DriverFooter from '@/components/DriverFooter.vue'
import DriverRequestViewItem from '@/components/DriverRequestViewItem.vue'
export default {
  mixins: [requestMixin],
  components: {
    MainLayout,
    DriverFooter,
    DriverRequestViewItem,
  },
  data() {
    return {
      request: null,
      isLoading: true,
      status: {
        register: 1,
        request: 1,
        proximity: 0,
        hour: 0,
      },
      userLocation: null,
      isReady: false,
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    async initializeData() {
      try {
        await this.getUserLocation()
        await this.getRequest()
        this.checkProximity()
      } catch (error) {
        console.error('Error initializing data:', error)
      }
    },
    reload() {
      this.initializeData()
    },
    async getRequest() {
      try {
        const id = this.$route.params.id
        const response = await this.getRequestMixin(id)
        this.request = response.request
        this.status.hour = this.canEnterHour()
        this.updateStatus()
      } catch (error) {
        console.error('Error getting next request:', error)
      } finally {
        this.isLoading = false
      }
    },
    async checkIsOk() {
      if (!this.request) {
        this.isReady = false
        return
      }

      const isEntry = this.isAccessIn(this.request)
      const withVehicle = this.request.Vehicle !== null
      const park = this.request.Park

      if (
        !(
          this.status.register === 2 &&
          this.status.request === 2 &&
          this.status.proximity === 1 &&
          (this.status.hour === 1 || this.status.hour === 2)
        )
      ) {
        this.isReady = false
        return
      }

      if (
        park.access_in_qrcode == 0 &&
        park.access_in_scan == 0 &&
        park.access_in_security == 0 &&
        park.access_out_qrcode == 0 &&
        park.access_out_scan == 0 &&
        park.access_out_security == 0 &&
        park.access_user_in_qrcode == 0 &&
        park.access_user_in_scan == 0 &&
        park.access_user_in_security == 0 &&
        park.access_user_out_qrcode == 0 &&
        park.access_user_out_scan == 0 &&
        park.access_user_out_security == 0
      ) {
        this.isReady = true
        return
      }

      if (isEntry) {
        if (withVehicle) {
          if (park.access_in_scan == 1 || park.access_in_security == 1) {
            this.isReady = true
            return
          }
        } else {
          if (
            park.access_user_in_scan == 1 ||
            park.access_user_in_security == 1
          ) {
            this.isReady = true
            return
          }
        }
      } else {
        if (withVehicle) {
          if (park.access_out_scan == 1 || park.access_out_security == 1) {
            this.isReady = true
            return
          }
        } else {
          if (
            park.access_user_out_scan == 1 ||
            park.access_user_out_security == 1
          ) {
            this.isReady = true
            return
          }
        }
      }

      this.isReady = false
    },
    updateStatus() {
      this.status.register = this.request.registration.status
      this.status.request = this.request.status
    },
    canEnterHour() {
      if (!this.request.hour_min || this.request.hour_min === '') {
        this.request.hour_min = this.request.hour
      }

      if (!this.request.hour_max || this.request.hour_max === '') {
        this.request.hour_max = this.request.hour
      }

      const day = this.request.day.substring(0, 10)
      let dayMin = `${day} ${this.request.hour_min}`
      let dayMax = `${day} ${this.request.hour_max}`

      let dateMin = new Date(dayMin)
      let dateMax = new Date(dayMax)

      // Check if we need to extend by 1 day
      if (dateMax < dateMin) {
        dateMax.setDate(dateMax.getDate() + 1)
      }

      // Calculate exit time
      const addHour = 12 * 60 * 60 * 1000 // add 12 hours
      const dateExit = new Date(dateMax.getTime() + addHour)

      const now = new Date()

      if (dateMin > now) return 0 // waiting
      if (dateMin <= now && dateMax > now) return 1 // entry allowed
      if (dateMax <= now && dateExit > now) return 2 // only exit allowed
      if (dateExit <= now) return -1 // finished

      return -1 // finished
    },
    async getUserLocation() {
      return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.userLocation = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              }
              resolve()
            },
            (error) => {
              console.error('Error getting user location:', error.message)
              reject(error)
            }
          )
        } else {
          console.error('Geolocation is not supported by this browser.')
          reject(new Error('Geolocation not supported'))
        }
      })
    },
    checkProximity() {
      if (this.request) {
        const distance = this.calcDistance(this.request)
        this.status.proximity = distance <= 0 ? 1 : 0
      }

      this.checkIsOk()
    },
    calcDistance(currRequest) {
      if (!this.userLocation) {
        this.$toast.error('A precisão do sinal GPS está baixa', {
          position: 'bottom-center',
          duration: 3000,
        })
        return 1000000.0
      }

      let distance = 0.0

      if (currRequest.Park) {
        const parkLocation = {
          latitude: currRequest.Park.lat,
          longitude: currRequest.Park.lng,
        }

        const userDistance = this.calculateDistance(
          this.userLocation.latitude,
          this.userLocation.longitude,
          parkLocation.latitude,
          parkLocation.longitude
        )

        distance = userDistance - currRequest.Park.range
      }

      return distance
    },

    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371e3 // Earth's radius in meters
      const φ1 = (lat1 * Math.PI) / 180
      const φ2 = (lat2 * Math.PI) / 180
      const Δφ = ((lat2 - lat1) * Math.PI) / 180
      const Δλ = ((lon2 - lon1) * Math.PI) / 180

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return R * c // Distance in meters
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    isAccessIn(request) {
      if (!request.entries) return true
      if (request.entries.length === 0) return true

      const lastAccess = request.entries[request.entries.length - 1]
      if (lastAccess.access_out !== null && lastAccess.access_out !== '')
        return true

      return false
    },
  },
}
</script>

<style scooped lang="scss">
@import '../../scss/colors.scss';

#driver-request-view {
  .left-part {
    background-color: $lightgray;
    min-height: 100vh;
    .left-content {
      padding: 90px 70px;
      padding-bottom: 100px;

      .logo {
        height: 45px;
        margin-bottom: 90px;
        object-fit: contain;
      }

      h2 {
        text-align: center;
        margin-bottom: 30px;
      }

      .divider {
        margin-block: 15px;
      }

      .request-container {
        display: grid;
        gap: 20px;

        .request-infos {
          padding: 15px 20px;
          background-color: $white !important;
          cursor: pointer;
          border-radius: 10px;
          box-shadow: 0 1px 4px -3px #000000;

          .top {
            display: flex;
            align-items: center;
            gap: 20px;

            &.with-qrcode {
              flex-direction: column;
              gap: 30px;

              .infos {
                text-align: center;
              }
            }

            .clock-icon {
              color: $gray-dk;
              font-size: 80px;
            }

            .infos {
              color: $span-color;

              .company-name {
                font-weight: 600;
                font-size: 20px;
                padding-block: 8px;
              }
            }
          }

          .dark-text {
            color: $text-color;
          }
        }

        .request-status {
          padding: 15px 20px;
          background-color: $white !important;
          cursor: pointer;
          border-radius: 10px;
          box-shadow: 0 1px 4px -3px #000000;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .status {
              font-weight: 700;

              &.orange {
                color: $orange;
              }

              &.green {
                color: $green;
              }

              &.red {
                color: $red;
              }
            }
          }
        }
      }

      .get-in-btn {
        margin-top: 40px;
        width: 100%;
        font-size: 14px;
      }

      .request-btn {
        margin-top: 50px;
        width: 100%;
      }
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #driver-request-view {
    .left-part {
      .left-content {
        padding: 30px 40px;
        padding-bottom: 100px;

        .logo {
          margin-bottom: 40px;
        }

        h2 {
          margin-bottom: 45px;
        }
      }
    }
  }
}
</style>
