<template>
  <main-layout>
    <div class="content-container">
      <div id="park-register" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Parque</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel left-space" @click="deleteParkMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/parks/new') == -1 && isAdministrator()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && park.idpark == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveParkMixin(park, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/parks/new') == -1 && isGlpMember() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveParkMixin(park, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/parks/new') != -1 && isAdministrator()">Registrar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="tab = 'base'"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                parque</button>
              <button class="app-btn right-btn" @click="tab = 'gate'"
                v-bind:class="{ 'app-btn-primary ': tab == 'gate', 'app-btn-outline ': tab != 'gate' }">Portarias</button>
              <button class="app-btn left-btn" @click="tab = 'companies'"
                v-bind:class="{ 'app-btn-primary ': tab == 'companies', 'app-btn-outline ': tab != 'companies' }"
                v-if="this.$route.fullPath.indexOf('/parks/new') == -1 && !isCompanyMember()">Locatários</button>
              <button class="app-btn right-btn" @click="tab = 'users'"
                v-bind:class="{ 'app-btn-primary ': tab == 'users', 'app-btn-outline ': tab != 'users' }"
                v-if="this.$route.fullPath.indexOf('/parks/new') == -1">Funcionários</button>

              <!-- <button class="app-btn right-btn app-btn-outline" style="float: right;" @click="createGatehouse()" v-if="this.$route.fullPath.indexOf('/parks/new') == -1 && tab == 'gate'">Nova portaria</button>
              <button class="app-btn left-btn app-btn-outline" style="float: right;" @click="createGatezone()" v-if="this.$route.fullPath.indexOf('/parks/new') == -1 && tab == 'gate'">Nova região</button> -->
            </div>
          </div>
        </div>
        <!-- <div class="row " v-if="tab == 'base'"> -->
        <ValidationObserver ref="validator" class="row" v-if="tab == 'base'">
          <!-- <form @submit.prevent="passes(onSubmit)"> -->
          <div class="col-md-3">
            <image-upload v-model="park.image_photo" @uploaded="saveParkMixin(park, $route.params.id, true)"
              @deleted="saveParkMixin(park, $route.params.id, true)" v-if="isAdministrator()"></image-upload>
            <ImageItem :path="park.image_photo" v-else></ImageItem>
          </div>
          <div class="col-md-9">
            <div class="col-md-12">

              <ok-input :model="park.name" @updated="(x) => {
                  park.name = x;
                }
                " :label="'Nome do parque'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-12">
              <ok-input :model="park.resume" @updated="(x) => {
                  park.resume = x;
                }
                " :label="'Descrição'" :placeholder="' '" :id="'resume'" :name="'resume'" :isTextArea="true">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="park.status" :sync="true" />
              </div>
            </div>
          </div>
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h2 class="title-md">Endereço</h2>
          </div>
          <div class="col-md-12">
            <address-form :item="park"></address-form>
          </div>

          <div class="col-md-12 separator-bottom" v-if="isGlpMember()">
            <hr class="separator">
            <h1 class="title-md">Integração com servidor</h1>
          </div>
          <div class="col-md-6" v-if="isGlpMember()">
            <ok-input :model="park.server_url" @updated="(x) => {
                park.server_url = x;
              }
              " :label="'Url'" :placeholder="' '" :id="'server_url'" :name="'server_url'">
            </ok-input>
          </div>
          <div class="col-md-6" v-if="isGlpMember()">
            <ok-input :model="park.server_key" @updated="(x) => {
                park.server_key = x;
              }
              " :label="'Chave'" :placeholder="' '" :id="'server_key'" :name="'server_key'">
            </ok-input>
          </div>

          <!-- </form> -->
        </ValidationObserver>
        <!-- </div> -->
        <div class="row " v-if="tab == 'gate'">
          <div class="col-md-12 last" style="margin-bottom: 10px">
            <button class="app-btn app-btn-outline left-btn-m" v-if="tab2 == 'list'"
              @click="syncGates()">Sincronizar</button>
            <button class="app-btn app-btn-outline left-space right-btn-m" @click="tab2 = 'gatezone'"
              v-if="tab2 == 'list'">Nova região</button>
            <button class="app-btn app-btn-outline left-space left-btn-m" @click="tab2 = 'gatehouse'"
              v-if="tab2 == 'list' && gatezoneList.length > 0">Nova portaria</button>
            <button class="app-btn app-btn-outline left-space right-btn-m" @click="listGatezones()"
              v-if="tab2 != 'list'">Exibir portarias</button>
          </div>
          <div class="col-md-12" v-if="tab2 == 'list'">
            <!-- <div class="lock">
              <button class="clean" @click="selectGatezone(gatezone)" :class="{'hidden': editingGate != null}">
                <i class="fas fa-lock"></i>
              </button>
              <button class="clean" @click="selectGatezone(gatezone)">
                <i class="fas fa-lock-open"></i>
              </button>
            </div> -->

            <div class="gatezone-item">
              <h2 style="color: #888; margin-bottom: 15px">Tipo de acesso</h2>
              <div class="access-item">
                <p class="title-sm">Entrada com veículo</p>
                <div class="access-types">
                  <button class="app-btn app-btn-outline" 
                    :class="{ active: park.access_in_security }" 
                    @click="park.access_in_security = park.access_in_security ? 0 : 1"
                  >
                    Segurança ler QRCode do visitante
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_in_scan }" 
                    @click="park.access_in_scan = park.access_in_scan ? 0 : 1"
                  >
                    Leitura contínua
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_in_qrcode }" 
                    @click="park.access_in_qrcode = park.access_in_qrcode ? 0 : 1"
                  >
                    Visitante ler QRCode do portão
                  </button>
                </div>
              </div>
              <div class="access-item">
                <p class="title-sm">Entrada pedestre</p>
                <div class="access-types">
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_in_security }" 
                    @click="park.access_user_in_security = park.access_user_in_security ? 0 : 1"
                  >
                    Segurança ler QRCode do visitante
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_in_scan }" 
                    @click="park.access_user_in_scan = park.access_user_in_scan ? 0 : 1"
                  >
                    Leitura contínua
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_in_qrcode }" 
                    @click="park.access_user_in_qrcode = park.access_user_in_qrcode ? 0 : 1"
                  >
                    Visitante ler QRCode do portão
                  </button>
                </div>
              </div>
              <div class="access-item">
                <p class="title-sm">Saída com veículo</p>
                <div class="access-types">
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_out_security }" 
                    @click="park.access_out_security = park.access_out_security ? 0 : 1"
                  >
                    Segurança ler QRCode do visitante
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_out_scan }" 
                    @click="park.access_out_scan = park.access_out_scan ? 0 : 1"
                  >
                    Leitura contínua
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_out_qrcode }" 
                    @click="park.access_out_qrcode = park.access_out_qrcode ? 0 : 1"
                  >
                    Visitante ler QRCode do portão
                  </button>
                </div>
              </div>
              <div class="access-item">
                <p class="title-sm">Saída pedestre</p>
                <div class="access-types">
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_out_security }" 
                    @click="park.access_user_out_security = park.access_user_out_security ? 0 : 1"
                  >
                    Segurança ler QRCode do visitante
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_out_scan }" 
                    @click="park.access_user_out_scan = park.access_user_out_scan ? 0 : 1"
                  >
                    Leitura contínua
                  </button>
                  <button class="app-btn app-btn-outline"
                    :class="{ active: park.access_user_out_qrcode }" 
                    @click="park.access_user_out_qrcode = park.access_user_out_qrcode ? 0 : 1"
                  >
                    Visitante ler QRCode do portão
                  </button>
                </div>
              </div>
            </div>

            <div class="gatezone-item" v-for="gatezone in gatezoneList" :key="gatezone.idgatezone">
              <div class="name-with-edit">
                <h2 style="color: #888;">{{ gatezone.name }}</h2>
                <!-- <button class="clean" @click="showHidePassword()">
                  <i class="fas fa-trash"></i>
                </button> -->
                <button class="clean" @click="selectGatezone(gatezone)">
                  <i class="fas fa-pen"></i>
                </button>
              </div>
              <div class="gatehouse-item" v-for="gatehouse in gatezone.gatehouses" :key="gatehouse.idgatehouse">
                <div class="name-with-edit">
                  <h2 class="title-sm">{{ gatehouse.name }}</h2>
                  <!-- <button class="clean" @click="showHidePassword()">
                    <i class="fas fa-trash"></i>
                  </button> -->
                  <button class="clean" @click="selectGatehouse(gatehouse)">
                    <i class="fas fa-pen"></i>
                  </button>
                </div>

                <draggable class="grabbable" v-model="gatehouse.gates" group="people" @start="drag = true"
                  @end="drag = false" @change="gateMoved">
                  <div class="gate-item" v-for="gate in gatehouse.gates" :key="gate.id">
                    <button class="icon" disabled>
                      <i class="fas fa-bars"></i>
                    </button>
                    <input type="text" :ref="'gateInput' + gate.idgate" v-model="gate.name"
                      :disabled="editingGate != gate.idgate" :class="{ 'edit-input': editingGate == gate.idgate }">
                    <p class="genetec-name" :class="{ 'hidden': editingGate == gate.idgate }">{{ gate.genetec_name }}
                    </p>
                    <button class="clean" @click="editGate(gate)" :class="{ 'hidden': editingGate != null }">
                      <i class="fas fa-pen"></i>
                    </button>
                    <button class="clean" @click="updateGate(gate)" :class="{ 'hidden': editingGate != gate.idgate }">
                      <i class="fas fa-check"></i>
                    </button>
                    <button class="clean" v-if="editingGate != null && editingGate != gate.idgate"
                      style="width: 36px"></button>
                    <div class="in-out">
                      <button class="clean" v-bind:style="gate.access_out == 0 ? 'color: #B8BBC0;' : 'color: #FB9801;'"
                        style="margin-left: 25px" :class="{ 'disable-btn': !editingGate }"
                        @click="changeAccessOut(gate)">Saída</button>
                      <button class="clean" v-bind:style="gate.access_in == 0 ? 'color: #B8BBC0;' : 'color: green;'"
                        :class="{ 'disable-btn': !editingGate }" @click="changeAccessIn(gate)">Entrada</button>

                      <div class="genetec-code">
                        <div class="d-none d-md-block d-lg-block">
                          <p>code: {{ gate.genetec_code }}</p>
                        </div>
                      </div>
                      <button class="clean qrcode-btn" @click="printQRCode(gate)">
                        <i class="fas fa-qrcode"></i>
                        Imprimir
                      </button>
                      <!-- <p v-bind:style=" gate.access_in == 0 ? 'color: #B8BBC0;' : 'color: green;' ">Entrada</p>
                      <p  v-bind:style=" gate.access_out == 0 ? 'color: #B8BBC0;' : 'color: green;' " style="margin-left: 25px">Saída</p> -->
                    </div>
                  </div>
                  <div class="no-content" v-if="gatehouse.gates && gatehouse.gates.length == 0">
                    <p>Nenhum portão cadastrado</p>
                  </div>
                </draggable>
              </div>
              <div class="no-content" v-if="gatezone.gatehouses && gatezone.gatehouses.length == 0"
                style="margin-bottom: 12px">
                <p>Nenhuma portaria cadastrada</p>
              </div>
            </div>
            <div class="gatezone-item">
              <div class="name-with-edit">
                <h2>Portões sem agrupamento</h2>
              </div>

              <draggable class="grabbable" v-model="gateFreeList" group="people" @start="drag = true" @end="drag = false"
                @change="gateMoved">
                <div class="gate-item" v-for="gate in gateFreeList" :key="gate.id">
                  <button class="icon" disabled>
                    <i class="fas fa-bars"></i>
                  </button>
                  <!-- <p class="highlight">{{gate.name}}</p> -->
                  <input type="text" :ref="'gateInput' + gate.idgate" v-model="gate.name"
                    :disabled="editingGate != gate.idgate" :class="{ 'edit-input': editingGate == gate.idgate }">
                  <p class="genetec-name" :class="{ 'hidden': editingGate == gate.idgate }">{{ gate.genetec_name }}</p>
                  <button class="clean" @click="editGate(gate)" :class="{ 'hidden': editingGate != null }">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button class="clean" @click="updateGate(gate)" :class="{ 'hidden': editingGate != gate.idgate }">
                    <i class="fas fa-check"></i>
                  </button>
                  <button class="clean" v-if="editingGate != null && editingGate != gate.idgate"
                    style="width: 36px"></button>
                  <div class="in-out">
                    <button class="clean" v-bind:style="gate.access_out == 0 ? 'color: #B8BBC0;' : 'color: #FB9801;'"
                      style="margin-left: 25px" :class="{ 'disable-btn': !editingGate }"
                      @click="changeAccessOut(gate)">Saída</button>
                    <button class="clean" v-bind:style="gate.access_in == 0 ? 'color: #B8BBC0;' : 'color: green;'"
                      :class="{ 'disable-btn': !editingGate }" @click="changeAccessIn(gate)">Entrada</button>
                    <div class="genetec-code">
                      <div class="d-none d-md-block d-lg-block">
                        <p>code: {{ gate.genetec_code }}</p>
                      </div>
                    </div>
                    <button class="clean qrcode-btn" @click="printQRCode(gate)">
                      <i class="fas fa-qrcode"></i>
                      Imprimir
                    </button>
                    <!-- <p v-bind:style=" gate.access_in == 0 ? 'color: #B8BBC0;' : 'color: green;' ">Entrada</p>
                    <p v-bind:style=" gate.access_out == 0 ? 'color: #B8BBC0;' : 'color: green;' " style="margin-left: 25px">Saída</p> -->
                  </div>
                </div>
              </draggable>
              <div class="no-content" v-if="gateFreeList && gateFreeList.length == 0">
                <p>Não há portões sem agrupamento</p>
              </div>
            </div>
          </div>

          <div class="col-md-12" v-if="tab2 == 'gatezone'">
            <h2 class="title-sm" v-if="gatezone && gatezone.idgatezone == null">Cadastro de região</h2>
            <h2 class="title-sm" v-else>Atualização de região</h2>
            <ok-input :model="gatezone.name" @updated="(x) => {
                gatezone.name = x;
              }
              " :label="'Nome*'" :placeholder="' '" :id="'gatezone_name'" :name="'gatezone_name'">
            </ok-input>
            <button class="app-btn app-btn-secondary" style="float: right" @click="createGatezone()"
              v-if="gatezone.idgatezone == null">Cadastrar</button>
            <button class="app-btn app-btn-secondary left-space" style="float: right" @click="updateGatezone()"
              v-if="gatezone.idgatezone != null">Atualizar</button>
            <button class="app-btn app-btn-cancel" style="float: right" @click="deleteGatezone()"
              v-if="gatezone.idgatezone != null">Excluir região</button>
          </div>

          <div class="col-md-12" v-if="tab2 == 'gatehouse'">
            <h2 class="title-sm" v-if="gatehouse && gatehouse.idgatehouse == null">Cadastro de portaria</h2>
            <h2 class="title-sm" v-else>Atualização de portaria</h2>
            <ok-input :model="gatehouse.name" @updated="(x) => {
                gatehouse.name = x;
              }
              " :label="'Nome*'" :placeholder="' '" :id="'gatehouse_name'" :name="'gatehouse_name'">
            </ok-input>
            <div class="select-with-label">
              <v-select label="name" v-model="gatehouse.idgatezone" :options="gatezoneList"
                :reduce="(gatezone) => gatezone.idgatezone" placeholder="Selecione a região"></v-select>
              <label>Região*</label>
            </div>
            <button class="app-btn app-btn-secondary" style="float: right" @click="createGatehouse()"
              v-if="gatehouse.idgatehouse == null">Cadastrar</button>
            <button class="app-btn app-btn-secondary left-space" style="float: right" @click="updateGatehouse()"
              v-if="gatehouse.idgatehouse != null">Atualizar</button>
            <button class="app-btn app-btn-cancel" style="float: right" @click="deleteGatehouse()"
              v-if="gatehouse.idgatehouse != null">Excluir portaria</button>
          </div>

          <!-- <button class="app-btn app-btn-primary" @click="createGate()">gerar portão</button> -->
        </div>
        <div class="row" v-if="tab == 'companies'">
          <div class="col-md-12" v-for="company in park.Companies" :key="company.iduser">
            <company-item :companyItem="company" :gatezoneList="gatezoneList" @loadGatezones="loadParkGatezones()">
            </company-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="park.Companies && park.Companies.length == 0">
              <p>Não há empresas relacionadas a este parque</p>
            </div>
          </div>
        </div>
        <div class="row" v-if="tab == 'users'">
          <div class="col-md-12" v-for="user in park.Users" :key="user.iduser">
            <user-item :userItem="user"></user-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="park.Users && park.Users.length == 0">
              <p>Não há funcionários relacionados a este parque</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import AddressForm from '@/components/AddressForm.vue'
import CompanyItem from '@/components/CompanyItem.vue'
import UserItem from '@/components/UserItem.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import draggable from 'vuedraggable'
import ImageItem from '@/components/ImageItem.vue'
import QRCode from 'qrcode'
// import GmapMap from 'vue2-google-maps'


export default {
  mixins: [authMixin, parkMixin, companyMixin],
  components: {
    MainLayout,
    OkInput,
    AddressForm,
    CompanyItem,
    UserItem,
    ImageUpload,
    draggable,
    ImageItem,
    // GmapMap
  },
  data() {
    return {
      gatezone: {},
      gatehouse: {},
      gate: {},
      parkList: [],
      gatezoneList: [],
      gateFreeList: [],
      tab: "base",
      tab2: "list",
      editingGate: null,
      server: {
        url: null,
        user: null,
        password: null,
      },
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadPark();
      this.loadParkGatezones();
    }
  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadPark() {
      let self = this;
      const id = this.$route.params.id;
      this.getParkMixin(id).then((response) => {
        self.park = response.park;
      });
    },
    loadParkGatezones() {
      let self = this;
      const id = this.$route.params.id;
      this.getParkGatezonesMixin(id).then((response) => {
        self.gateFreeList = response.gateFree;
        self.gatezoneList = response.gatezoneList;
      });
    },
    createGatezone() {
      let self = this;
      const id = this.$route.params.id;
      let gatezone = {
        name: this.gatezone.name,
        idpark: id,
      }
      if (gatezone.name == undefined || gatezone.name == '') {
        self.$swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: 'Verifique se os campos estão corretos.',
        })
      } else {
        this.createGatezoneMixin(id, gatezone).then((response) => {
          if (response) {
            this.getParkGatezonesMixin(id).then((response) => {
              self.gateFreeList = response.gateFree;
              self.gatezoneList = response.gatezoneList;
              self.listGatezones();
              self.$toasted.show('Região criada com sucesso');

            });
          }
        });
      }
    },
    updateGatezone() {
      let self = this;
      const id = this.$route.params.id;

      if (this.gatezone.name == undefined || this.gatezone.name == '') {
        self.$swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: 'Verifique se os campos estão corretos.',
        })
      } else {
        this.updateGatezoneMixin(id, this.gatezone).then((response) => {
          if (response) {
            this.getParkGatezonesMixin(id).then((response) => {
              self.gateFreeList = response.gateFree;
              self.gatezoneList = response.gatezoneList;
              self.listGatezones();
              self.$toasted.show('Região atualizada com sucesso');
            });
          }
        });
      }
    },
    deleteGatezone() {
      let self = this;
      const id = this.$route.params.id;
      this.deleteGatezoneMixin(id, this.gatezone).then((response) => {
        if (response) {
          this.getParkGatezonesMixin(id).then((response) => {
            self.gateFreeList = response.gateFree;
            self.gatezoneList = response.gatezoneList;
            self.listGatezones();
            self.$toasted.show('Região removida com sucesso');
          });
        }
      });
    },
    createGatehouse() {
      let self = this;
      const id = this.$route.params.id;
      let gatehouse = {
        name: this.gatehouse.name,
        idgatezone: this.gatehouse.idgatezone,
      }
      if (gatehouse.name == undefined || gatehouse.idgatezone == undefined || gatehouse.name == '') {
        self.$swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: 'Verifique se os campos estão corretos.',
        })
      } else {
        this.createGatehouseMixin(id, gatehouse).then((response) => {
          if (response) {
            this.getParkGatezonesMixin(id).then((response) => {
              self.gateFreeList = response.gateFree;
              self.gatezoneList = response.gatezoneList;
              self.listGatezones();
              self.$toasted.show('Portaria criada com sucesso');
            });
          }
        });
      }

    },
    updateGatehouse() {
      let self = this;
      const id = this.$route.params.id;
      if (this.gatehouse.name == undefined || this.gatehouse.idgatezone == undefined) {
        self.$swal.fire({
          icon: 'error',
          title: 'Atenção',
          text: 'Verifique se os campos estão corretos.',
        })
      } else {
        this.updateGatehouseMixin(id, this.gatehouse).then((response) => {
          if (response) {
            this.getParkGatezonesMixin(id).then((response) => {
              self.gateFreeList = response.gateFree;
              self.gatezoneList = response.gatezoneList;
              self.listGatezones();
              self.$toasted.show('Portaria atualizada com sucesso');
            });
          }
        });
      }
    },
    deleteGatehouse() {
      let self = this;
      const id = this.$route.params.id;
      this.deleteGatehouseMixin(id, this.gatehouse).then((response) => {
        if (response) {
          this.getParkGatezonesMixin(id).then((response) => {
            self.gateFreeList = response.gateFree;
            self.gatezoneList = response.gatezoneList;
            self.listGatezones();
            self.$toasted.show('Portaria removida com sucesso');
          });
        }
      });
    },
    createGate() {
      let self = this;
      const id = this.$route.params.id;
      let gate = {
        name: 'Portão 3',
        idgatehouse: 50,
        idpark: id,
        device: '001',
        access_in: 1,
        access_out: 0,
      }
      this.createGateMixin(id, gate).then((response) => {
        console.log(response)
        this.getParkGatezonesMixin(id).then((response) => {
          self.gateFreeList = response.gateFree;
          self.gatezoneList = response.gatezoneList;
        });
      });
    },
    editGate(gate) {
      this.editingGate = gate.idgate;
      console.log('editGate, gate', this.editingGate, gate)
      let refNanme = 'gateInput' + gate.idgate;
      console.log('dasdpaskdad', this.$refs[refNanme])
      console.log('refNanme', refNanme)
      this.$refs[refNanme][0].focus();
    },
    updateGate(gate) {
      let self = this;
      const id = this.$route.params.id;
      console.log('updateGate, gate', this.editingGate, gate)
      this.updateGateMixin(id, gate).then((response) => {
        console.log(response)
        self.editingGate = null;
      });
    },
    async printQRCode(item) {
      if (!item) return
      const canvas = document.createElement('canvas')
      const credentials = `${process.env.VUE_APP_QRCODE_ENCONDE_KEY}:${item.idpark}:${item.idgate}:${item.genetec_code}`
      const encodedCredentials = btoa(credentials)
      await QRCode.toCanvas(canvas, encodedCredentials)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(`
        <html>
          <head>
            <title>Impressão de QRCode</title>
            <style>
              @page { size: A5; margin: 0; }
              body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; flex-direction: column; }
              canvas { max-width: 100%; max-height: 100%; width: 300px !important; height: 300px !important; }
              p { margin-top: 20px; font-size: 16px; }
            </style>
          </head>
          <body>
          </body>
        </html>
      `)
      printWindow.document.body.appendChild(canvas)
      const paragraph = document.createElement('p')
      paragraph.textContent = `Este é o QRCode para o portão ${item.name}`
      printWindow.document.body.appendChild(paragraph)
      printWindow.document.close()
      printWindow.print()
    },
    gateMoved(elementMoved) {
      const id = this.$route.params.id;
      let idgatehouse;
      if (elementMoved.added != undefined) {
        // console.log('mexeu gate', elementMoved.added)
        let gate = JSON.parse(JSON.stringify(elementMoved.added.element));
        // idgate = elementMoved.added.element.idgate;
        // console.log('antes ');
        idgatehouse = this.getGatehouseGate(this.gatezoneList, gate.idgate);
        // console.log('depois ');
        // console.log('gatehouse ' + idgatehouse + 'e gate ' + gate.idgate + 'gateee' + gate);
        gate.idgatehouse = idgatehouse;
        this.updateGateMixin(id, gate).then((response) => {
          console.log(response)
        });
      }
    },
    syncGates() {
      let self = this;
      const id = this.$route.params.id;
      this.syncGateMixin(id).then((response) => {
        self.loadParkGatezones()
        console.log(response)
      });
    },
    listGatezones() {
      this.tab2 = 'list'
      this.gatezone = {};
      this.gatehouse = {};
    },
    selectGatezone(gatezone) {
      this.gatezone = JSON.parse(JSON.stringify(gatezone));
      this.tab2 = 'gatezone'
    },
    selectGatehouse(gatehouse) {
      this.gatehouse = JSON.parse(JSON.stringify(gatehouse));
      this.tab2 = 'gatehouse'
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#park-register {
  .gatezone-item {

    background-color: $white;
    border-radius: 14px;
    box-shadow: $shadow-border;
    border: 1px solid transparent;
    padding: 15px;
    margin-bottom: 15px;

    .access-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background-color: #F9F9F9;
      padding: 12px 20px;
      border-radius: 12px;
      margin-bottom: 6px;

      p{
        font-weight: bold;
      }
      .access-types {
        display: flex;
        gap: 10px;

        .active{
          background-color: $primary;
          color: $white;
        }
      }
    }

  }

  .gatehouse-item {
    margin-bottom: 20px;
    // margin-left: 20px;
    // border: 1px solid $gray-md;
    border-radius: 10px;
    padding: 12px;

    // background-color: $white;
    // border-radius: 14px;
    // box-shadow: $shadow-border;
    // border: 1px solid transparent;
  }

  .lock {
    position: relative;

    .clean {
      position: absolute;
      right: 18px;
      top: 10px;
      font-size: 20px;
      color: #B8BBC0;
    }
  }

  .gate-item {
    background-color: $lightgray;
    padding: 5px 20px;
    border-radius: 12px;
    margin-bottom: 5px;
    position: relative;

    p {
      padding-left: 15px;
    }

    i {
      display: inline-block;
    }

    svg {
      font-size: 18px;
    }

    input {
      display: inline-block;
      background-color: transparent;
      height: 35px;
      box-shadow: none;
      border: none;
      padding: 0px;
      margin-left: 15px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 8px;
      width: 100%;
      max-width: calc(100% - 525px)
    }

    button {
      padding: 10px;
      margin-left: 10px;
      float: right;

      svg {
        font-size: 16px;
        color: $gray-md;

        &:hover {
          color: $blue;

        }
      }
    }

    .icon {
      background-color: transparent;
      border: none;
      border-radius: 0px;
      color: var(--main-text-color);
      width: auto;
      padding: 10px;
      margin-left: 0px;
      float: none;
    }

    .in-out {
      width: auto;
      float: right;
      margin-right: 15px;
      margin-top: 6px;

      p {
        display: inline-block;
      }

      button {
        font-size: 14px;
        padding: 4px 13px;
        padding-bottom: 7px;
      }

      .disable-btn {
        cursor: move;
        /* fallback if grab cursor is unsupported */
        cursor: grab;
        pointer-events: none;
      }

      .genetec-code {
        display: inline-block;
        padding-left: 20px;

        p {
          width: 100px;
          line-height: 25px;
        }
      }

      .qrcode-btn{
        background-color: $gray-bg;
        color: $darkgray;
        border-radius: 3em;
        padding: 0px 13px;
        height: 27px;
        display: flex;
        align-items: center;
        gap: 10px;
        float: left;

        &:hover{
          color: $blue;
        }
        
        svg {
          color: inherit;

          &:hover {
            color: inherit;
          }
        }
      }
    }

    .genetec-name {
      display: none;
      position: absolute;
      top: 15px;
      left: 60px;
      background-color: $lightgray;
      width: calc(100% - 565px);
    }

    .edit-input {
      background-color: white;
      width: calc(100% - 390px);
    }

    &:hover {
      .genetec-name {
        display: inherit;
      }

      // input{
      //   display: none;
      // }
    }

  }

  .grabbable {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  .grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .name-with-edit {
    min-height: 33px;

    h2 {
      display: inline-block;
    }

    .gatezone-title {
      margin-top: 25px;
    }

    button {
      padding: 0px 6px;
      padding-bottom: 5px;
      margin-left: 10px;

      svg {
        font-size: 16px;
        color: $gray-md;
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  #park-register {

    .gatezone-item {
      .access-item {
        flex-direction: column;
        
        .access-types{
          flex-direction: column;
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    .gate-item {
      height: 90px;

      input {
        display: inline-block;
        background-color: transparent;
        height: 35px;
        box-shadow: none;
        border: none;
        padding: 0px;
        margin-left: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 8px;
        width: auto;
        max-width: calc(100% - 96px)
      }

      .icon {
        padding: 0px;
        float: left;
        margin-top: 32px;
        margin-left: 10px;
      }

      button {
        margin-top: 21px;
      }

      .in-out {
        margin-top: 12px;
        margin-right: 0px;
        vertical-align: middle;
        position: absolute;
        width: 168px;
        left: 50%;
        transform: translateX(-50%);

        button {
          margin-top: 0px;
          margin: 0px !important;
          width: 50%;
        }
      }

      .edit-input {
        width: calc(100% - 96px);
      }
    }
  }
}
</style>