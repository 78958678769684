<template>
  <main-layout>
    <div id="reset-password">
        <div class="reset-content">
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h1>Reset de senha</h1>
          <p>Preencha as informações abaixo para efetuar alteração da sua senha</p>
          <div class="reset-inputs"> 
            <ok-input
              :model="reset.email"
              @updated="
                (x) => {
                  reset.email = x;
                }
              "
              :label="'Email'"
              :placeholder="'nome@email.com'"
              :id="'email_reset'"
              :name="'email_reset'"
            >
            </ok-input>
            
            <ok-input
              :model="reset.password"
              @updated="
                (x) => {
                  reset.password = x;
                }
              "
              :label="'Senha'"
              :placeholder="' '"
              :type="'password'"
              :id="'password_reset'"
              :name="'password_reset'"
            >
            </ok-input>
            
            <ok-input
              :model="reset.confirm"
              @updated="
                (x) => {
                  reset.confirm = x;
                }
              "
              :label="'Repetir a senha'"
              :placeholder="' '"
              :type="'password'"
              :id="'confirm_reset'"
              :name="'confirm_reset'"
              @onEnter="resetPassword()"
            >
            </ok-input>
          </div>
          <div class="reset-btns">
            <button class="app-btn app-btn-text forgot-password-btn" @click="goTo('/login')">Início</button>
            <button class="app-btn app-btn-primary login-btn" id="login-btn" @click="resetPassword()" style="margin-left: 50px;">Alterar</button>
          </div>
        </div>
    </div>
    
  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import MainLayout from "../layouts/Main.vue";
import OkInput from '@/components/OkInput.vue'

export default {
  mixins: [authMixin],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      reset: {
        email: "",
        password: "",
        confirm: "",
        token: ""
      },
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.reset.token = id;
  },
  methods: {
    resetPassword() {
      let self = this;
      this.resetPasswordMixin(self.reset);
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";
#reset-password{
  height: 100vh;

  .reset-content{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    h1{
      text-align: center;
      margin-top: 35px;
      margin-bottom: 35px;
    }
    
    p{
      text-align: center;
      margin-bottom: 25px;
    }

    .reset-btns{
      text-align: center;
      margin-top: 30px;
    }
  }

}
</style>