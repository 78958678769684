import axios from 'axios';
import { server } from '../js/variables.js'
// import {required} from "vuelidate/lib/validators";
// import moment from 'moment'

var parkMixin = {
  data: function () {
    return {
      park: {
        name: null,
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
        range: null,
      },
    }
  },
  // validations: {
  //   park: {
  //     name: {required},
  //     status: false,
  //     street: null,
  //     state: null,
  //     city: null,
  //     district: null,
  //     range: null,
  //   },
  // },
  methods: {
    listParksMixin(limit, page, search, order, sort, status) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
      };

      return axios.get(server.api_url + '/parks', config)
        .then((response) => {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getParkMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/parks/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.park.status = self.fixStatus(responseData.park.status);

          if (responseData.park.range) {
            responseData.park.range = responseData.park.range.toString();
          }

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveParkMixin(park, param, photo) {
      const self = this;
      if (photo == true && param === 'new') return
      let parkToSend = JSON.parse(JSON.stringify(park));
      if (parkToSend.zipcode) parkToSend.zipcode = self.removeMaskZipcode(parkToSend.zipcode);
      parkToSend.status = self.fixStatus(parkToSend.status);
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          // if(photo == undefined) return
          // console.log('aqui validate', this.$v.$invalid)
          this.axios.post(server.api_url + '/parks/', {
            park: parkToSend,
          }).then((response) => {
            let responseData = response.data.data;
            // responseData.park.status = self.fixStatus(responseData.park.status);
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/parks/' + responseData.park.idpark, '_self');
              }
            })

          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          // if(parkToSend.lat == null || parkToSend.lng == null){
          //   parkToSend.lat = 0;
          //   parkToSend.lng = 0;
          // }
          this.axios.put(server.api_url + '/parks/' + parkToSend.idpark, {
            park: parkToSend,
          }).then((response) => {
            // responseData.park.status = self.fixStatus(responseData.park.status);
            if (photo == '' || photo == null) {
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: response.data.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              })
            }
          })
            .catch(function (error) {
              self.showError(error);
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }

    },
    deleteParkMixin(id) {
      const self = this;
      this.$swal.fire({
        title: 'Realmente deseja excluir este parque?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/parks/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/parks/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },

    //Gatezone
    getParkGatezonesMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/parks/' + id + '/gatezone', server.config)
        .then((response) => {
          let responseData = response.data.data;

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    createGatezoneMixin(id, gatezone) {
      const self = this;
      return axios.post(server.api_url + '/parks/' + id + '/gatezone/', {
        gatezone: gatezone,
      }).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    updateGatezoneMixin(id, gatezone) {
      const self = this;
      return axios.put(server.api_url + '/parks/' + id + '/gatezone/' + gatezone.idgatezone, {
        gatezone: gatezone,
      }).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    deleteGatezoneMixin(id, gatezone) {
      const self = this;
      return axios.delete(server.api_url + '/parks/' + id + '/gatezone/' + gatezone.idgatezone).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
        });
    },

    //Gatehouse
    createGatehouseMixin(id, gatehouse) {
      const self = this;
      return axios.post(server.api_url + '/parks/' + id + '/gatehouse/', {
        gatehouse: gatehouse,
      }).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    updateGatehouseMixin(id, gatehouse) {
      const self = this;
      return axios.put(server.api_url + '/parks/' + id + '/gatehouse/' + gatehouse.idgatehouse, {
        gatehouse: gatehouse,
      }).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    deleteGatehouseMixin(id, gatehouse) {
      const self = this;
      return axios.delete(server.api_url + '/parks/' + id + '/gatehouse/' + gatehouse.idgatehouse).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
        });
    },

    //Gate
    syncGateMixin(id) {
      const self = this;

      return axios.post(server.api_url + '/parks/' + id + '/gate/sync', {}, server.config)
        .then((response) => {
          let responseData = response.data.data;

          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // window.open ('/parks/' + responseData.park.idpark , '_self');
            }
          })

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    createGateMixin(id, gate) {
      const self = this;
      return axios.post(server.api_url + '/parks/' + id + '/gate/', {
        gate: gate,
      }).then((response) => {
        let responseData = response.data.data;
        self.getParkGatezonesMixin(id);
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    updateGateMixin(id, gate) {
      const self = this;
      return axios.put(server.api_url + '/parks/' + id + '/gate/' + gate.idgate, {
        gate: gate,
      }).then((response) => {
        let responseData = response.data.data;
        self.getParkGatezonesMixin(id);
        self.$toasted.show(responseData.gate.name + ' atualizado');
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    changeAccessOut(gate) {
      if (gate.access_out == 0) {
        gate.access_out = 1
      } else {
        gate.access_out = 0
      }
    },
    changeAccessIn(gate) {
      if (gate.access_in == 0) {
        gate.access_in = 1
      } else {
        gate.access_in = 0
      }
    },
    getGatehouseGate(array, idgate) {
      for (let gatezone of array) {
        for (let gatehouse of gatezone.gatehouses) {
          if (gatehouse.gates.some(gate => gate.idgate === idgate)) {
            return gatehouse.idgatehouse;
            // console.log("Object found inside the array.");
          }
        }
      }
      return null
    },

    parksListFiltered(alreadyAddedList) {
      var listFiltered = [];
      var list = [];
      // console.log('parksListFiltered', alreadyAddedList)
      for (let item of this.parkList) {

        if (!alreadyAddedList.some(park => park.idpark === item.idpark)) {
          item.name.toLowerCase();
          list.push(item);
        }
      }
      if (this.filter.searchText == null) return list;

      for (let item of list) {
        console.log(!alreadyAddedList.some(park => park.idpark === item.idpark))
        let name = item.name.toLowerCase();
        let searchText = this.filter.searchText.toLowerCase();
        if (name.indexOf(searchText) > -1) {
          listFiltered.push(item);
        }
      }
      return listFiltered;
    },
    addUserParkMixin(id, idpark) {
      const self = this;
      if (this.$route.fullPath.indexOf('/companies') == -1) {
        return axios.post(server.url + '/users/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
            return responseData;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        return axios.post(server.url + '/companies/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)

            return responseData;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }

    },
    deleteUserParkMixin(id, idpark) {
      const self = this;
      // console.log('aqui deleteUserParkMixin', id, idpark)
      if (this.$route.fullPath.indexOf('/companies') == -1) {
        return this.axios.delete(server.url + '/users/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        return this.axios.delete(server.url + '/companies/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
    },
  }
};

export default parkMixin;