<template>
  <div class="data-item driver-registration-item">
    <div class="row">
      <div class="col-md-8 data-middle">
        <ImageItem :path="registrationItem.Company.logo" :isSmall="true"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">{{ registrationItem.Company.name }}</p>
          <p>Data da solicitação: {{ registrationItem.created_at | formatDate | verifyIfNull }}</p>
          <p>Parques: {{ getCompanyParks(registrationItem.Company) }}</p>
        </div>
      </div>
      <div class="col-md-4 data-right">
        <p>No GLP desde: {{ registrationItem.Driver.created_at | formatDate | verifyIfNull }}</p>
        <p>Status: <span class="highlight" v-bind:class="userStatusClass(registrationItem.status)">{{
          requestStatus(registrationItem.status) }}</span>
        </p>
      </div>
    </div>

    <overlay-item :route="'/registrations/' + registrationItem.idregistration"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin, companyMixin],
  props: ["registrationItem"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.driver-registration-item {
  .image-box {}
}

@media only screen and (max-width: 600px) {
  .driver-registration-item {}
}
</style>