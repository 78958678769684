var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',[_c('div',{attrs:{"id":"login"}},[_c('div',{staticClass:"row"},[(_vm.showLogin)?_c('div',{staticClass:"col-md-4 left-part"},[_c('img',{staticClass:"logo center",attrs:{"src":"/static/img/glp-logomark@2x.png","alt":"GLP logo"}}),_c('h2',[_vm._v("Login")]),_c('ok-input',{attrs:{"model":_vm.user.email,"label":'E-mail',"placeholder":'nome@email.com',"id":'email',"name":'email'},on:{"updated":(x) => {
          _vm.user.email = x;
        }}}),_c('div',{staticClass:"password"},[_c('ok-input',{attrs:{"model":_vm.user.password,"label":'Password',"placeholder":' ',"type":_vm.passwordInputType,"id":'password',"name":'password'},on:{"updated":(x) => {
            _vm.user.password = x;
          },"onEnter":function($event){return _vm.loginUser()}}}),_c('button',{class:{ 'hidden': !_vm.verifyPassword() },on:{"click":function($event){return _vm.showHidePassword()}}},[_c('i',{staticClass:"far fa-eye"})]),_c('button',{class:{ 'hidden': _vm.verifyPassword() },on:{"click":function($event){return _vm.showHidePassword()}}},[_c('i',{staticClass:"far fa-eye-slash"})])],1),_c('button',{staticClass:"app-btn app-btn-text forgot-password-btn",on:{"click":function($event){_vm.showLogin = false}}},[_vm._v("Esqueci a senha")]),_c('button',{staticClass:"app-btn app-btn-primary login-btn",attrs:{"id":"login-btn"},on:{"click":function($event){return _vm.loginUser()}}},[_vm._v("Entrar")]),_c('hr',{staticClass:"divider"}),_c('button',{staticClass:"app-btn app-btn-text",attrs:{"id":"login-btn-driver"},on:{"click":function($event){return _vm.goTo('/visitante/login')}}},[_vm._v("Acessar como visitante")])],1):_c('div',{staticClass:"col-md-4 left-part-forgot"},[_c('img',{staticClass:"logo center",attrs:{"src":"/static/img/glp-logomark@2x.png","alt":"GLP logo"}}),_c('h2',[_vm._v("Esqueci a senha")]),_c('p',{staticStyle:{"text-align":"center","font-size":"13px"}},[_vm._v("Informe seu email para enviarmos instruções")]),_c('ok-input',{attrs:{"model":_vm.userForgot.emailToSend,"label":'E-mail',"placeholder":'nome@email.com',"id":'emailToSend',"name":'emailToSend'},on:{"updated":(x) => {
          _vm.userForgot.emailToSend = x;
        }}}),_c('ok-input',{attrs:{"model":_vm.userForgot.emailToSendConfirm,"label":'Confirmar e-mail',"placeholder":'nome@email.com',"id":'emailToSendConfirm',"name":'emailToSendConfirm'},on:{"updated":(x) => {
          _vm.userForgot.emailToSendConfirm = x;
        },"onEnter":function($event){return _vm.emailToSend()}}}),_c('button',{staticClass:"app-btn app-btn-text forgot-password-btn",on:{"click":function($event){_vm.showLogin = true}}},[_vm._v("Voltar")]),_c('button',{staticClass:"app-btn app-btn-primary login-btn",on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v("Enviar")])],1),_c('div',{staticClass:"col-md-8 no-padding"},[_c('div',{staticClass:"image-box",staticStyle:{"background-image":"url(\"./static/img/glp-main.png\")"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }