import Vue from 'vue'

import uuid from "device-uuid";
Vue.use(uuid);

import VueRouter from 'vue-router'
Vue.use(VueRouter);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);

// import SassLoader from 'sass-loader';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  position: "bottom-center",
  duration: 3000,
  containerClass: 'toasted-custom',
});

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
Vue.use(Popper);

import moment from 'moment';
Vue.prototype.moment = moment;
moment.locale('pt-br');

import _ from 'lodash'
Vue.use(_);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
Vue.$cookies.config('30d');

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
