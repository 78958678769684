<template>
  <div class="overlay-item" :class="{ 'overlay-item-small': small }">
    <div class="overlay-left-item" :id="'open' + route" @click="goTo(route)">
      <p>Abrir</p>
    </div>
    <div
      class="overlay-right-item"
      :id="'open-new-tab' + route"
      @click="openNewTab(route)"
      :class="{ 'overlay-right-item-small': small }"
    >
      <p>Abrir em nova aba</p>
    </div>
    <div class="overlay-mobile-item" @click="goTo(route)"></div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";

export default {
  props: ['route', 'small'],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  methods: {},
}
</script>

<style lang="scss">
@import '../scss/colors.scss';

.overlay-item {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 14px;

  &-small {
    width: calc(100% - 30px);
  }

  // &:hover {
  //   display: flex;
  // }

  &:hover .overlay-left-item {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      display: block;
    }
  }

  &:hover .overlay-right-item {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      display: block;
    }
  }

  .overlay-left-item,
  .overlay-right-item {
    display: none;
    flex: 1;
    background-color: #44af3bb0;

    p {
      display: none;
      color: white;
      font-size: 20px;
    }
  }

  .overlay-left-item {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &:hover {
      background-color: #44af3be0;
    }
  }

  .overlay-right-item {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    &-small {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &:hover {
      background-color: #44af3be0;
    }
  }

  .overlay-mobile-item {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .overlay-item {
    
    &-small {
      width: 100%;
      height: calc(100% - 30px);
    }

    .overlay-left-item,
    .overlay-right-item {
      display: none !important;
    }

    .overlay-mobile-item {
      display: block !important;
      width: 100%;
    }
  }
}
</style>
