<template>
  <div class=" park-item">
    <div class="data-item">
      <div class="row">
        <div class="col-md-9 data-middle">
          <ImageItem :path="parkItem.image_photo" :isSmall="true"></ImageItem>
          <div class="middle-infos">
            <p class="highlight">Nome: {{ parkItem.name }}</p>
            <p v-if="parkItem.street">Endereço: {{ parkItem.street }}<span v-if="parkItem.number">,
                {{ parkItem.number }}</span></p>
            <p v-else>Endereço: -</p>
            <p v-if="parkItem.range">Cerca: {{ parkItem.range | verifyIfNull }} m</p>
            <p v-else>Cerca: -</p>
          </div>
        </div>
        <div class="col-md-3 data-right" v-bind:class="{ 'data-right-bigger': showRemove || showAdd }">
          <div class="" v-bind:class="{ 'left-part ': showRemove || showAdd }">
            <p>Registro: {{ parkItem.created_at | formatDate }}</p>
            <p>Genetec: <span
                v-bind:class="genetecStatusClass(parkItem.server_url != null && parkItem.server_key != null)">{{
                  genetecStatus(parkItem.server_url != null && parkItem.server_key != null)
                }}</span></p>
            <p>Status: <span class="highlight" v-bind:class="userStatusClass(parkItem.status)">{{
              userStatus(parkItem.status)
            }}</span></p>
          </div>

          <div class="data-right-btn data-right-btn-orange" v-if="showRemove" @click="removePark($event)">
            <i class="fas fa-times"></i>
          </div>
          <div class="data-right-btn data-right-btn-blue" v-if="showAdd" @click="addPark($event)">
            <i class="fas fa-check"></i>
          </div>
        </div>
      </div>
      <overlay-item :route="'/parks/' + parkItem.idpark" :small="showRemove || showAdd"></overlay-item>
    </div>
    <div class="row" style="padding: 0px 12px; margin-bottom: 18px"
      v-if="parkGatezoneList && parkGatezoneList.length > 0">
      <div class="col-md-12 park-gatezone" v-for="gatezone in parkGatezoneList" :key="gatezone.idgatezone">
        <p class="highlight">{{ gatezone.name }}</p>
        <!-- <p style="margin-left: 30px; float: right">{{verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)}}</p> -->
        <!-- <div class="right-blue" :class="{'hidden': verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)}"> -->
        <button class="clean icon-times"
          @click="addCompanyGatezone(parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)"
          :class="{ 'hidden': verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone) }">
          <i class="fas fa-times"></i>

          <!-- <i class="fas fa-times" v-if="verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)"></i>
          <i class="fas fa-check" v-if="!verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)"></i> -->

          <!-- <svg class="svg-inline--fa fa-times fa-w-11" v-if="teste == true" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" data-fa-i2svg=""><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
          <svg aria-hidden="true" focusable="false" v-if="teste == false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" data-fa-i2svg="" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg> -->
        </button>
        <!-- </div> -->
        <!-- <div class="right-orange" :class="{'hidden' : !verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)}"> -->
        <button class="clean icon-check"
          @click="deleteCompanyGatezone(parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone)"
          :class="{ 'hidden': !verifyCompanyGatezone(parkGatezoneList, parkItem.CompanyPark.CompanyIdcompany, gatezone.idgatezone) }">
          <i class="fas fa-check"></i>
        </button>
        <!-- </div> -->
      </div>
    </div>

  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import parkMixin from "../mixins/park.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [parkMixin, companyMixin],
  props: ["parkItem", "showRemove", 'showAdd', 'showGatezones'],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
      parkGatezoneList: [],
      isMouseOver: false,
    };
  },
  mounted() {
    // this.parkGatezoneList = [];
    // for(let gatezone of this.gatezoneList){
    //   if(gatezone.idpark == this.parkItem.idpark){
    //     this.parkGatezoneList.push(gatezone);
    //   }
    // }
    if (this.showGatezones) this.loadParkGatezones()


  },
  watch: {},
  methods: {
    removePark(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      this.deleteUserParkMixin(id, this.parkItem.idpark).then((response) => {
        console.log(response)
        self.$emit("done");
        self.$toasted.show('Parque removido com sucesso');

      });
    },
    addPark(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      this.addUserParkMixin(id, this.parkItem.idpark).then((response) => {
        console.log(response)
        self.$emit("done");
        self.$toasted.show('Parque associado com sucesso');

      });
    },
    loadParkGatezones() {
      let self = this;
      this.getParkGatezonesMixin(this.parkItem.idpark).then((response) => {
        self.parkGatezoneList = response.gatezoneList;
        // console.log('getParkGatezonesMixin', self.parkGatezoneList)
      });
    },
    addCompanyGatezone(idcompany, idgatezone) {
      let self = this;
      this.AddCompanyGatezoneMixin(idcompany, idgatezone).then((response) => {
        if (response) {
          self.loadParkGatezones();
          self.$toasted.show('Região ativada com sucesso');
        }
      });
    },
    deleteCompanyGatezone(idcompany, idgatezone) {
      let self = this;
      this.deleteCompanyGatezoneMixin(idcompany, idgatezone).then((response) => {
        if (response) {
          self.loadParkGatezones();
          self.$toasted.show('Região desativada com sucesso');
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.park-item {
  position: relative;

  .park-gatezone {
    background-color: $gray-bg;
    padding: 12px 20px;
    border-radius: 12px;
    margin-bottom: 5px;
    position: relative;

    p {
      display: inline-block;
      line-height: 33px;
    }

    .clean {
      float: right;
      font-size: 17px;
    }

    .icon-check {
      color: $blue;
    }

    .icon-times {
      color: $gray-dk;
    }

    // .clean{
    //   // float: right;
    //   font-size: 20px;
    //   color: white;
    //   margin-top: 16px;
    //   padding: 0px;

    //   &-times{
    //     margin-left: 7px;
    //   }

    //   &-check{
    //     margin-left: 4px;
    //   }
    // }

    // .right-blue{
    //   display: inline-block;
    //   background-color: $blue;
    //   position: absolute;
    //   right: 1px;
    //   top: 1px;
    //   width: 30px;
    //   height: 55px;
    //   border-top-right-radius: 14px;
    //   border-bottom-right-radius: 14px;
    // }

    // .right-orange{
    //   display: inline-block;
    //   background-color: $orange;
    //   position: absolute;
    //   right: 1px;
    //   top: 1px;
    //   width: 30px;
    //   height: 55px;
    //   border-top-right-radius: 14px;
    //   border-bottom-right-radius: 14px;
    // }

  }

}

@media only screen and (max-width: 600px) {
  .park-item {

    .data-item .data-middle,
    .data-box .data-middle {
      padding: 12px;
      padding-bottom: 5px;
      height: auto;
      padding-right: 45px;
    }

    .data-item {
      // max-height: 145px;
    }

  }
}
</style>