<template>
  <main-layout>
    <div class="content-container">
      <div id="third-company-register" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Terceiro</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteThirdCompanyMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1 && isAdministrator()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && thirdCompany.idcompany == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space"
              @click="saveThirdCompanyMixin(thirdCompany, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1 && isThirdCompanyAdm() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space"
              @click="saveThirdCompanyMixin(thirdCompany, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/third-companies/new') != -1 && isGlpMember()">Registrar</button>
          </div>
          <div class="col-md-10">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados da
                empresa</button>
              <button class="app-btn right-btn" @click="changeTab('employees')"
                v-bind:class="{ 'app-btn-primary ': tab == 'employees', 'app-btn-outline ': tab != 'employees' }"
                v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1 && (isCoordinator() || isMyThird())">Empregados</button>
              <button class="app-btn left-btn" @click="changeTab('drivers')"
                v-bind:class="{ 'app-btn-primary ': tab == 'drivers', 'app-btn-outline ': tab != 'drivers' }"
                v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1 && (isCoordinator() || isMyThird())">Motoristas</button>
              <!-- <button class="app-btn left-btn" @click="changeTab('vehicles')"
                v-bind:class="{ 'app-btn-primary ': tab == 'vehicles', 'app-btn-outline ': tab != 'vehicles' }"
                v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1">Veículos</button> -->
              <button class="app-btn right-btn" @click="changeTab('companies')"
                v-bind:class="{ 'app-btn-primary ': tab == 'companies', 'app-btn-outline ': tab != 'companies' }"
                v-if="this.$route.fullPath.indexOf('/third-companies/new') == -1 && (isCoordinator() || isMyThird())">Locatários</button>
            </div>
          </div>
        </div>
        <ValidationObserver ref="validator" class="row fix-right-margin" v-if="tab == 'base'">
          <div class="col-md-3 row">
            <div class="col-md-12">
              <image-upload v-model="thirdCompany.logo"
                @uploaded="saveThirdCompanyMixin(thirdCompany, $route.params.id, true)"
                @deleted="saveThirdCompanyMixin(thirdCompany, $route.params.id, true)"
                v-if="isThirdCompanyAdm()"></image-upload>
                <ImageItem :path="thirdCompany.logo" :isFull="true" v-else></ImageItem>
            </div>
          </div>
          <div class="col-md-9 row">
            <div class="col-md-6">
              <ok-input :model="thirdCompany.cnpj" @updated="(x) => {
                thirdCompany.cnpj = x;
              }
                " :label="'CNPJ'" :placeholder="' '" :id="'cnpj'" :name="'cnpj'" :mask="'##.###.###/####-##'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="thirdCompany.name" @updated="(x) => {
                thirdCompany.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="thirdCompany.email" @updated="(x) => {
                thirdCompany.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'email'" :name="'email'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="thirdCompany.phone" @updated="(x) => {
                thirdCompany.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'phone'" :name="'phone'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="thirdCompany.status" :sync="true" />
              </div>
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-12" style="margin-top: 15px;">
              <ok-input :model="thirdCompany.resume" @updated="(x) => {
                thirdCompany.resume = x;
              }
                " :label="'Descrição'" :placeholder="' '" :id="'resume'" :name="'resume'" :isTextArea="true">
              </ok-input>
            </div>
          </div>
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h1 class="title-md">Endereço</h1>
          </div>
          <div class="col-md-12 row">
            <address-form :item="thirdCompany"></address-form>
          </div>
          <!-- </div> -->
        </ValidationObserver>

        <div class="row fix-right-margin" v-if="tab == 'employees'">
          <div class="col-md-12 row" v-if="tab2 == 'list'">
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab2 = 'register'"
                v-if="isCompanyAdm() || isSecurityAdm() || isThirdCompanyAdm()">Adicionar
                funcionário</button>
            </div>
            <div class="col-md-12" v-for="user in thirdCompany.Users" :key="user.iduser" @click="selectEmployee(user)">
              <employee-item :userItem="user"></employee-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="thirdCompany.Users && thirdCompany.Users.length == 0">
                Não foram adicionados funcionários a esta empresa. <br>
                Clique em 'Adicionar funcionário' para fazer esse relacionamento.
              </div>
            </div>
          </div>

          <ValidationObserver ref="validator" class="col-md-12 row" v-if="tab2 == 'register'">
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="listEmployees()">Listar funcionários</button>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.name" @updated="(x) => {
                employee.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'employee_name'" :name="'employee_name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.lastname" @updated="(x) => {
                employee.lastname = x;
              }
                " :label="'Sobrenome'" :placeholder="' '" :id="'employee_lastname'" :name="'employee_lastname'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.email" @updated="(x) => {
                employee.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'employee_email'" :name="'employee_email'"
                rules="required|email">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.phone" @updated="(x) => {
                employee.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'employee_phone'"
                :name="'employee_phone'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.birthday" @updated="(x) => {
                employee.birthday = x;
              }
                " :label="'Nascimento'" :placeholder="' '" :id="'employee_birthday'" :name="'employee_birthday'"
                :mask="'##/##/####'" :isDate="true">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.gender" @updated="(x) => {
                employee.gender = x;
              }
                " :label="'Sexo'" :options="genderOptions" :placeholder="' '" :id="'employee_gender'"
                :name="'employee_gender'" style="margin-top: 4px;">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.job" @updated="(x) => {
                employee.job = x;
              }
                " :label="'Função'" :options="thirdEmployeeJobOptions" :placeholder="' '" :id="'employee_job'"
                :name="'employee_job'" style="margin-top: 4px;" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item" style="margin-top: 8px;">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="employee.status" :sync="true" />
              </div>
            </div>
            <div class="col-md-12 last">
              <button class="app-btn app-btn-secondary" v-if="employee.iduser" @click="forgotPassword()">Reenviar email
                de alteração de senha</button>
            </div>
            <div class="col-md-12 last" style="margin-top: 18px">
              <button class="app-btn app-btn-cancel"
                v-if="employee.iduser && isCompanyAdm() || isSecurityAdm() || isThirdCompanyAdm()"
                @click="deleteEmployee()">Remover</button>
              <button class="app-btn app-btn-secondary left-space" v-if="employee.iduser && isThirdCompanyAdm()"
                @click="updateEmployee()">Atualizar</button>
              <button class="app-btn app-btn-secondary left-space"
                v-if="!employee.iduser && (isCompanyAdm() || isSecurityAdm() || isThirdCompanyAdm())"
                @click="addEmployee()">Salvar</button>
            </div>
            <!-- </div> -->
          </ValidationObserver>
        </div>

        <div class="row" v-if="tab == 'drivers'">
          <div class="col-md-12" v-for="driver in thirdCompany.Drivers" :key="driver.iddriver">
            <user-item :userItem="driver" :isDriver="true"></user-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="thirdCompany.Drivers && thirdCompany.Drivers.length == 0">
              Este terceiro não tem nenhum motorista cadastrado.
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'companies'">
          <div class="col-md-12" v-for="company in thirdCompany.renter" :key="company.iduser">
            <company-item :companyItem="company" :showRemove="isThirdCompanyAdm()" @done="loadThirdCompany"
              :isThirdCompany="true"></company-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="thirdCompany.renter && thirdCompany.renter.length == 0">
              <p>Não há empresas relacionadas a este terceiro</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import userMixin from "../mixins/user.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import EmployeeItem from '@/components/EmployeeItem.vue'
import UserItem from '@/components/UserItem.vue'
import CompanyItem from '@/components/CompanyItem.vue'
import AddressForm from '@/components/AddressForm.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import ImageItem from '@/components/ImageItem.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin, thirdCompanyMixin, parkMixin, userMixin],
  components: {
    MainLayout,
    OkInput,
    EmployeeItem,
    UserItem,
    CompanyItem,
    AddressForm,
    ImageUpload,
    ImageItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
      employee: {
        job: '20'
      },
      parkList: [],
      filter: {
        searchText: null,
      },
      currentUser: null,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadThirdCompany();
      this.listParks();
      this.currentUser = getLocalStorage('user')
    }
  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadThirdCompany() {
      let self = this;
      const id = this.$route.params.id;
      this.getThirdCompanyMixin(id).then((response) => {
        // console.log('aqui', response)
        self.thirdCompany = response.third;
      });
    },
    listParks() {
      let self = this;
      this.listParksMixin(500).then((response) => {
        self.parkList = response.parkList;
      });
    },
    addEmployee() {
      let self = this;
      const id = this.$route.params.id;
      this.addEmployeeMixin(self.employee, id).then((response) => {
        // console.log(response);
        if (response != null) {
          self.listEmployees();
          self.loadThirdCompany();
          self.$toasted.show('Empregado adicionado com sucesso');
        }
      });
    },
    updateEmployee() {
      let self = this;
      this.updateEmployeeMixin(self.employee).then((response) => {
        console.log(response);
        if(!response) return
        self.listEmployees();
        self.loadThirdCompany();
        self.$toasted.show('Empregado atualizado com sucesso');
      });
    },
    deleteEmployee() {
      let self = this;

      this.$swal.fire({
        title: 'Realmente deseja excluir este funcinário?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteEmployeeMixin(self.employee).then((response) => {
            console.log(response);
            if(!response) return
            self.listEmployees();
            self.loadThirdCompany();
            self.$toasted.show('Empregado removido com sucesso');
          });
        }
      })
    },
    forgotPassword() {
      let self = this;
      this.forgotPasswordEmployeeMixin(self.employee.email);
    },
    changeTab(value) {
      this.tab2 = 'list'
      this.tab = value;
      this.employee = {};
    },
    listEmployees() {
      this.tab2 = 'list'
      this.employee = {};
    },
    selectEmployee(employee) {
      this.employee = JSON.parse(JSON.stringify(employee));
      this.employee.status = this.fixStatus(this.employee.status);
      this.tab2 = 'register'
    },
    isMyThird() {
      if (this.isAdministrator() || this.currentUser?.Company?.idcompany == this.$route.params.id) return true
      if (!this.thirdCompany.renter || this.thirdCompany.renter.length == 0) return false
      const thirdFound = this.thirdCompany.renter.some(objeto => objeto.idcompany === this.currentUser?.Company?.idcompany)
      return thirdFound
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#third-company-register {}
</style>