@@ -0,0 +1,44 @@
<template>
  <div>
    <div class="dev-mode" v-if="isDevelopment">
      <p>Ambiente de homologação</p>
    </div>
    <div class="header">
      <div class="row">
        <div class="col-md-4 col-6">
          <router-link to="/dashboard">
            <img src="/static/img/glp-logomark@2x.png" alt="glp-logo" class="logo">
          </router-link>
        </div>
        <div class="col-md-8 col-6 last d-none d-md-block d-lg-block">
          <p class="user-logged-name">Olá, {{ auth.name }} {{ auth.lastname }}</p>
        </div>
        <div class="col-md-8 col-6 last d-block d-md-none d-lg-none">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin],
  name: 'Header',
  components: {
    // LoadBar,
  },
  data() {
    return {
      isDevelopment: false,
    };
  },
  mounted() {
    this.auth = getLocalStorage('user');
    if (process.env.NODE_ENV === 'development') this.isDevelopment = true
    else this.isDevelopment = false

  },
  methods: {
    teste() {
      this.showMenu = true;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/colors.scss";

.header {
  background-color: $gray-bg;
  height: 60px;
  padding: 5px 15px;

  .logo {
    height: 35px;
    float: left;
    margin-top: 8px;
  }

  .user-logged-name {
    margin-top: 14px;
    margin-right: 10px;
    font-size: 13px;
  }

}

.dev-mode {
  background-color: #FB9801;
  padding: 5px 10px;

  p {
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 15px;
  }
}
</style>