<template>
  <div class="data-item">
    <div class="row">
      <div class="col-md-9 data-middle">
        <ImageItem :path="entryItem.Request.Park.image_photo" :isSmall="true"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">{{ entryItem.Request.Park.name }}</p>
          <p>Segurança: {{ entryItem.User.name }} {{ entryItem.User.lastname }}</p>
          <p>Portaria: -</p>
        </div>
      </div>
      <div class="col-md-3 data-right">
        <p>Entrada: {{ entryItem.access_in | formatDateTime }}</p>
        <p>Saída: {{ entryItem.access_out | formatDateTime }}</p>
      </div>
    </div>
    <overlay-item :route="'/entries/' + entryItem.identry"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  props: ["entryItem"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.entry-item {}

@media only screen and (max-width: 600px) {
  .entry-item {}
}
</style>