<template>
  <footer class="driver-footer">
    <nav>
      <ul>
        <li v-for="link in links" :key="link.link">
          <router-link
            :to="link.link"
            :class="{ active: $route.path === link.link }"
          >
            <i :class="link.icon"></i>
          </router-link>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      links: [
        {
          icon: 'fas fa-home',
          link: '/visitante/home',
        },
        {
          icon: 'fas fa-plus-circle',
          link: '/visitante/cadastro-solicitacao',
        },
        {
          icon: 'fas fa-clipboard-list',
          link: '/visitante/lista-solicitacoes',
        },
      ],
    }
  },
  methods: {},
}
</script>

<style lang="scss">
@import '../scss/colors.scss';

.driver-footer {
  height: auto;
  width: 100vw;
  background-color: $lightgray;
  position: fixed;
  bottom: 0;

  nav {
    box-shadow: 0 -1px 15px rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      justify-content: space-between;
      padding: 12px 30px;

      li {
        list-style: none;

        a {
          color: $gray-dk;
          text-decoration: none;
          font-size: 25px;

          &.active {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
