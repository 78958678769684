<template>
  <main-layout>
    <div class="content-container">
      <div id="driver-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Motoristas</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="goTo('/drivers/new')" v-if="isThirdMember()">Novo
              registro</button>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input :model="filter.searchText" @updated="(x) => {
              filter.searchText = x;
            }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="applyFilter">
            </ok-input>
            <button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.status" :options="statusOptions2" :reduce="(status) => status.value"
                placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.bindStatus" :options="statusBindOptions"
                :reduce="(status) => status.value" placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status vínculo</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idcompany" :options="thirdCompanyList"
                :reduce="(company) => company.idcompany" @input="applyFilter"
                placeholder="Selecione a empresa"></v-select>
              <label>Empresa</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.order" :options="orderOptions" :reduce="(order) => order.value"
                placeholder="Mais recentes" @input="applyFilter"></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{ filter.searchingText }}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="driver in driverList" :key="driver.iduser">
            <user-item :userItem="driver" :isDriver="true"></user-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="driverList && driverList.length == 0">
              <p>{{ noContentText }}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="driverList && driverList.length > 0 && filter.canLoadMore"
              @click="listDrivers()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import driverMixin from "../mixins/driver.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import UserItem from '@/components/UserItem.vue'

export default {
  mixins: [authMixin, driverMixin, thirdCompanyMixin],
  components: {
    MainLayout,
    OkInput,
    UserItem,
  },
  data() {
    return {
      driverList: [],
      thirdCompanyList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        idcompany: null,
        bindStatus: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando motoristas...',

    };
  },
  mounted() {
    this.listDrivers();
    this.listCompanies();
  },
  methods: {
    listDrivers() {
      let self = this;
      let status = self.filter.status;
      if (status == '3') status = null;
      let bindStatus = self.filter.bindStatus;
      if (bindStatus == '3') bindStatus = null;
      let thirdId = self.filter.idcompany ? self.filter.idcompany : null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando motoristas...';

      this.listDriversMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, bindStatus, thirdId).then((response) => {
        if (self.filter.page == 0) {
          self.driverList = response.driverList;
        } else {
          self.driverList = self.driverList.concat(response.driverList);
        }
        if (response.driverList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }
        self.noContentText = 'Nenhum motorista para exibir';
      });
    },
    listCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.thirdCompanyList = response.thirdList;

      });
    },
    applyFilter() {
      this.filter.page = 0;
      this.listDrivers()
    },
    removeSearchText() {
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#driver-list {}
</style>