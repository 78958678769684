<template>
  <div class="d-block d-md-none d-lg-none">
    <div class="d-block d-md-none d-lg-none menu-icon" @click="showMenu = !showMenu">
      <i class="fas fa-bars"></i>
    </div>
    <div class="navbar d-lg-none" v-if="showMenu">
      <div class="inside">
        <ul>
          <li v-for="item in menu" :key="item.name" v-bind:class="{ 'selected': item.active, 'hide-item': !item.show }"
            :disabled="!item.show">
            <router-link :to="item.link" :id="item.id">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
        <div class="logout-btn">
          <p style="margin-bottom: 10px;font-size: 13px;text-align: center;">{{ version }}</p>
          <button class="app-btn app-btn-cancel " id="logout" @click="logout()">Encerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import { getLocalStorage } from "../js/encryption";
export default {
  mixins: [authMixin],
  components: {},
  data() {
    return {
      menu: [
        { name: "Dashboard", link: "/dashboard", id: 'm_sidebar_dashboard', active: false, show: true },
        { name: "Parques", link: "/parks", id: 'm_sidebar_parks', active: false, show: true },
        { name: "Pessoas", link: "/users", id: 'm_sidebar_users', active: false, show: true },
        { name: "Locatários", link: "/companies", id: 'm_sidebar_companies', active: false, show: true },
        { name: "Terceiros", link: "/third-companies", id: 'sidebar_third_companies', active: false, show: true },
        { name: "Motoristas", link: "/drivers", id: 'm_sidebar_drivers', active: false, show: true },
        { name: "Veículos", link: "/vehicles", id: 'm_sidebar_vehicles', active: false, show: true },
        { name: "Acompanhantes", link: "/companions", id: 'm_sidebar_companions', active: false, show: true },
        { name: "Cadastros", link: "/registrations", id: 'm_sidebar_registrations', active: false, show: true },
        { name: "Solicitações", link: "/requests", id: 'm_sidebar_requests', active: false, show: true },
        { name: "Acessos", link: "/entries", id: 'm_sidebar_entries', active: false, show: true },
        { name: "Reportes", link: "/reports", id: 'm_sidebar_reports', active: false, show: true },
        { name: "Aplicações", link: "/applications", id: 'm_sidebar_applications', active: false, show: true },
        { name: "Log de integração", link: "/integrations", id: 'm_sidebar_integrations', active: false, show: true },

      ],
      showMenu: false,
      version: '',
    };
  },
  mounted() {
    this.version = process.env.VUE_APP_VERSION
    let path = 'm_sidebar_' + this.$route.path.split('/')[1];
    for (let item of this.menu) {
      if (item.id == path) {
        item.active = true;
      }
    }

    this.manageMenuItens()
  },
  methods: {
    manageMenuItens() {
      let user = getLocalStorage('user');

      switch (user.job) {
        case '11': //coordenador
          this.menu.splice(-2)

          break;

        case '20': //locatário adm
          this.menu[3].link = '/companies/' + user.idcompany;
          this.menu.splice(2, 1) //remove pessoas
          this.menu.splice(-2)

          break;

        case '21': //locatário 
          this.menu[3].link = '/companies/' + user.idcompany;
          this.menu.splice(2, 1) //remove pessoas
          this.menu.splice(-2)

          break;

        case '30': //Segurança adm
          this.menu.splice(9, 1) //remove cadastros
          this.menu.splice(8, 1) //remove solicitações
          this.menu.splice(-2)

          break;

        case '31': //Segurança
          this.menu.splice(2)

          break;

        case '40': //Terceiro adm
          this.menu[4].link = '/third-companies/' + user.idcompany;
          this.menu.splice(2, 1) //remove pessoas
          this.menu.splice(-2)

          break;

        case '41': //Terceiro funcionário
          this.menu[4].link = '/third-companies/' + user.idcompany;
          this.menu.splice(2, 1) //remove pessoas
          this.menu.splice(-2)

          break;

        default:

          break;

      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/navbar.scss";
</style>