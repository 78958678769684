import axios from 'axios';
import { server } from '../js/variables.js'

var utilsMixin = {
  data: function () {
    return {
    }
  },
  methods: {
    async uploadImage(image) {
      const formData = new FormData();

      var fileData = this.dataURLtoFile(image);
      formData.append('file', fileData);

      try {
        const response = await axios.post(server.api_url + '/media/image/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        return response.data.data;
      } catch (error) {
        this.showError(error);
        return null;
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // console.log("dataURLtoFile ok");
      return new File([u8arr], filename, { type: mime });
    },
  }
};

export default utilsMixin;