<template>
  <div class="company-item">
    <div class="data-item">
      <div class="row">
        <div class="col-md-8 data-middle">
          <ImageItem :path="companyItem.logo" :isSmall="true"></ImageItem>
          <div class="middle-infos">
            <p class="highlight">Nome: {{ companyItem.name }}</p>
            <p v-if="companyItem.street">Endereço: {{ companyItem.street }}<span v-if="companyItem.number">,
                {{ companyItem.number }}</span></p>
            <p v-else>Endereço: -</p>
            <p>Parques: {{ getCompanyParks(companyItem) }}</p>
          </div>
        </div>
        <div class="col-md-4 data-right" v-bind:class="{ 'data-right-bigger': showRemove || showAdd }">
          <div class="" v-bind:class="{ 'left-part ': showRemove || showAdd }">
            <p>Registro: {{ companyItem.created_at | formatDate }}</p>
            <p>Status: <span class="highlight" v-bind:class="userStatusClass(companyItem.status)">{{
              userStatus(companyItem.status)
            }}</span></p>
            <p v-if="isThirdCompany && companyItem.CompanyThird.status != -1">Status vínculo: <span class="highlight"
                v-bind:class="driverThirdStatusClass(companyItem.CompanyThird.status)">{{
                  driverThirdStatus(companyItem.CompanyThird.status)
                }}</span></p>
          </div>

          <div class="data-right-btn data-right-btn-orange" v-if="showRemove" @click="removeCompany($event)">
            <i class="fas fa-times"></i>
          </div>
          <div class="data-right-btn data-right-btn-blue" v-if="showAdd" @click="addCompany($event)">
            <i class="fas fa-check"></i>
          </div>
        </div>
      </div>

      <overlay-item :route="'/companies/' + companyItem.idcompany" :small="showRemove || showAdd"></overlay-item>
    </div>
    <div class="row" style="padding: 0px 12px; margin-bottom: 18px" v-if="gatezoneList && gatezoneList.length > 0">
      <div class="col-md-12 company-gatezone" v-for="gatezone in gatezoneList" :key="gatezone.idgatezone">
        <p class="highlight">{{ gatezone.name }}</p>
        <!-- <p style="margin-left: 30px; float: right">{{verifyCompanyGatezone(gatezoneList, companyItem.idcompany, gatezone.idgatezone)}}</p> -->
        <button class="clean icon-times" @click="addCompanyGatezone(companyItem.idcompany, gatezone.idgatezone)"
          :class="{ 'hidden': verifyCompanyGatezone(gatezoneList, companyItem.idcompany, gatezone.idgatezone) }">
          <i class="fas fa-times"></i>
        </button>
        <button class="clean icon-check" @click="deleteCompanyGatezone(companyItem.idcompany, gatezone.idgatezone)"
          :class="{ 'hidden': !verifyCompanyGatezone(gatezoneList, companyItem.idcompany, gatezone.idgatezone) }">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import companyMixin from "../mixins/company.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [companyMixin, thirdCompanyMixin],
  props: ["companyItem", "showRemove", 'showAdd', 'gatezoneList', 'isThirdCompany'],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
    addCompanyGatezone(idcompany, idgatezone) {
      let self = this;
      this.AddCompanyGatezoneMixin(idcompany, idgatezone).then((response) => {
        if (response) {
          self.$emit("loadGatezones");
          self.$toasted.show('Região ativada com sucesso');
        }
      });
    },
    deleteCompanyGatezone(idcompany, idgatezone) {
      let self = this;
      this.deleteCompanyGatezoneMixin(idcompany, idgatezone).then((response) => {
        if (response) {
          self.$emit("loadGatezones");
          self.$toasted.show('Região desativada com sucesso');
        }
      });
    },
    removeCompany(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      if (this.isThirdCompany) {
        this.deleteCompanyFromThirdCompanyMixin(this.companyItem.idcompany, id).then((response) => {
          console.log(response)
          self.$emit("done");
          self.$toasted.show('Locatário removido com sucesso.');
        });
      } else {
        this.deleteApplicationCompanyMixin(id, this.companyItem.idcompany).then((response) => {
          console.log(response)
          self.$emit("done");
          self.$toasted.show('Locatário removido com sucesso');
        });
      }
    },
    addCompany(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      this.addApplicationCompanyMixin(id, this.companyItem.idcompany).then((response) => {
        console.log(response)
        self.$emit("done");
        self.$toasted.show('Locatário associado com sucesso');

      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.company-item {
  .company-gatezone {
    background-color: $gray-bg;
    padding: 12px 20px;
    border-radius: 12px;
    margin-bottom: 5px;

    p {
      display: inline-block;
      line-height: 33px;
    }

    .clean {
      float: right;
      font-size: 17px;
    }

    .icon-check {
      color: $blue;
    }

    .icon-times {
      color: $gray-dk;
    }
  }
}

@media only screen and (max-width: 600px) {
  .company-item {}
}
</style>