<template>
  <div class="application-item">
    <div class="data-box ">
      <div class="row">
        <div class="col-md-6 data-left">
          <p class="highlight">Nome: {{ application.name }}</p>
          <p><span class="highlight">Fontes confiáveis:</span> {{ application.trusted_sources }}</p>
        </div>
        <div class="col-md-6 data-right">
          <p>Registro: {{ application.created_at | formatDate }}</p>
          <p>Status: <span class="highlight"
              v-bind:class="userStatusClass(application.status)">{{ userStatus(application.status) }}</span></p>
        </div>
      </div>
      <overlay-item :route="'/applications/' + application.idapp"></overlay-item>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'

export default {
  props: ["application"],
  components: {
    OverlayItem
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.application-item {

  .data-box {
    padding: 0px;
    background-color: $white !important;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
    }
  }
}

@media only screen and (max-width: 600px) {
  .application-item {}
}
</style>