<template>
  <div class="vehicle-item">
    <div class="data-box ">
      <div class="row">
        <div class="col-md-3 col-6 data-left">
          <div class="car-color" v-bind:style="{ backgroundColor: vehicle.color }"></div>
          <p><span class="highlight">Tipo:</span> {{ vehicle.type }}</p>
        </div>
        <div class="col-md-3 col-6 data-middle">
          <p><span class="highlight">Placa:</span> {{ vehicle.plate }}</p>
        </div>
        <div class="col-md-3 col-6 data-middle data-middle-no-padding-top">
          <p><span class="highlight">Cor:</span> {{ vehicleColor(vehicle.color) }}</p>
        </div>
        <div class="col-md-3 col-6 data-right">
          <p><span class="highlight">Ano:</span> {{ vehicle.year }}</p>
        </div>
      </div>

      <overlay-item :route="'/vehicles/' + vehicle.idvehicle"></overlay-item>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'

export default {
  mixins: [userMixin],
  props: ["vehicle"],
  components: {
    OverlayItem
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.vehicle-item {

  .car-color {
    display: inline-block;
    width: 20px;
    position: absolute;
    margin-top: -15px;
    margin-left: -12px;
    height: 51px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    background-color: $orange;
    border-right: 1px solid $gray-md;
  }

  .data-box {
    padding: 0px;
    background-color: $white !important;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
    }
  }

  .data-middle {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .vehicle-item {
    .car-color {
      display: none;
    }

    .data-middle-no-padding-top {
      padding-top: 0px;
    }
  }
}
</style>