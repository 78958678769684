var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',[_c('div',{attrs:{"id":"reset-password"}},[_c('div',{staticClass:"reset-content"},[_c('img',{staticClass:"logo center",attrs:{"src":"/static/img/glp-logomark@2x.png","alt":"GLP logo"}}),_c('h1',[_vm._v("Reset de senha")]),_c('p',[_vm._v("Preencha as informações abaixo para efetuar alteração da sua senha")]),_c('div',{staticClass:"reset-inputs"},[_c('ok-input',{attrs:{"model":_vm.reset.email,"label":'Email',"placeholder":'nome@email.com',"id":'email_reset',"name":'email_reset'},on:{"updated":(x) => {
                _vm.reset.email = x;
              }}}),_c('ok-input',{attrs:{"model":_vm.reset.password,"label":'Senha',"placeholder":' ',"type":'password',"id":'password_reset',"name":'password_reset'},on:{"updated":(x) => {
                _vm.reset.password = x;
              }}}),_c('ok-input',{attrs:{"model":_vm.reset.confirm,"label":'Repetir a senha',"placeholder":' ',"type":'password',"id":'confirm_reset',"name":'confirm_reset'},on:{"updated":(x) => {
                _vm.reset.confirm = x;
              },"onEnter":function($event){return _vm.resetPassword()}}})],1),_c('div',{staticClass:"reset-btns"},[_c('button',{staticClass:"app-btn app-btn-text forgot-password-btn",on:{"click":function($event){return _vm.goTo('/login')}}},[_vm._v("Início")]),_c('button',{staticClass:"app-btn app-btn-primary login-btn",staticStyle:{"margin-left":"50px"},attrs:{"id":"login-btn"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v("Alterar")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }