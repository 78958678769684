<template>
  <main-layout>
    <div class="content-container">
      <div id="registration-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Cadastro</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space separator-bottom" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && registration.idregistration == undefined">Voltar</button>
          </div>
        </div>
        <div class="row" v-if="registration.Driver">
          <div class="col-md-4">
            <div class="data-box" style="padding: 18px 15px;">
              <div class="row">
                <div class="col-md-12 toggle-photo">
                  <ImageItem :path="registration.Driver.image_photo" :isFull="true" v-if="photoType"></ImageItem>
                  <ImageItem :path="registration.Driver.image_document" :isFull="true" v-else></ImageItem>
                </div>
                <div class="col-md-12 last separator-bottom toggle-photo" style="margin-top: 6px;">
                  <button class="left" v-bind:class="{ 'active': photoType }" @click="photoType = true">Foto</button>
                  <button class="right" v-bind:class="{ 'active': !photoType }" @click="photoType = false">CNH</button>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 style="margin-bottom: 10px">{{ registration.Driver.name }} {{ registration.Driver.lastname }}</h2>
                  <p>Telefone: {{ registration.Driver.phone | formatPhone }}</p>
                  <p>E-mail: {{ registration.Driver.email }}</p>
                  <p>CPF: {{ registration.Driver.cpf | formatCpf }}</p>
                  <p>CNH: {{ registration.Driver.cnh }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 class="highlight" v-if="registration.Driver.company_name">{{ registration.Driver.company_name }}
                  </h2>
                  <hp v-else>Empresa: -</hp>
                  <p>CNPJ: {{ registration.Driver.company_cnpj | formatCnpj | verifyIfNull }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6" style="margin-top: 6px;">
                <p style="margin-left: 23.5px; line-height: 36px;">Status: <span class="highlight"
                    v-bind:class="userStatusClass(registration.status)">{{ requestStatus(registration.status) }}</span>
                </p>
              </div>
              <div class="col-md-6 last" style="margin-top: 6px;">
                <button class="app-btn app-btn-cancel" @click="denyRegistrationMixin(registration)">Negar</button>
                <button class="app-btn app-btn-primary left-space"
                  @click="approveRegistrationMixin(registration)">Aprovar</button>
              </div>

              <div class="col-md-12" style="pointer-events: none">
                <h2 class="title-sm separator-title">Cadastro</h2>
                <registration-details-item :registration="registration"></registration-details-item>
              </div>

              <div class="col-md-12" v-if="registration.User == null">
                <h2 class="title-sm separator-title">Aprovador</h2>
                <div class="no-content">
                  <p>Indefinido</p>
                </div>
              </div>
              <div class="col-md-12" v-else>
                <h2 class="title-sm separator-title">Aprovador</h2>
                <user-item :userItem="registration.User" :simple="true"></user-item>
              </div>

              <div class="col-md-12">
                <h2 class="title-sm separator-title">Cadastros anteriores</h2>
                <div v-for="registration in registrationList" :key="registration.idregistration">
                  <registration-details-item :registration="registration"></registration-details-item>
                </div>
              </div>
              <div class="col-md-12" style="text-align: center">
                <div class="no-content" v-if="registrationList && registrationList.length == 0">
                  <p>{{ noContentText }}</p>
                </div>
                <button class="app-btn app-btn-load-more"
                  v-if="registrationList && registrationList.length > 0 && filter.canLoadMore"
                  @click="listRegistrations()">Carregar mais</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import registrationMixin from "../mixins/registration.mixin.js";
import MainLayout from "../layouts/Template.vue";
import RegistrationDetailsItem from '@/components/RegistrationDetailsItem.vue'
import UserItem from '@/components/UserItem.vue'
import moment from 'moment'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [registrationMixin],
  components: {
    MainLayout,
    RegistrationDetailsItem,
    UserItem,
    ImageItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
      registrationList: [],
      filter: {
        pageItems: 3,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        from: null,
        to: null,
        idcompany: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando cadastros anteriores...',
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadRegistration();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadRegistration() {
      let self = this;
      const id = this.$route.params.id;
      this.getRegistrationMixin(id).then((response) => {
        self.registration = response.registration;
        this.listRegistrations();
      });
    },
    listRegistrations() {
      let self = this;
      let startDate = null;
      let endDate = null;
      let status = self.filter.status;
      if (status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      if (self.filter.dateRange) {
        startDate = self.filter.dateRange[0];
        endDate = self.filter.dateRange[1];
      } else {
        startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        self.filter.dateRange = [startDate, endDate];
      }
      this.noContentText = 'Buscando cadastros...';

      this.listRegistrationsMixin(self.filter.pageItems, self.filter.page, null, order, sort, status, null, null, this.registration.idcompany, this.registration.iddriver).then((response) => {
        if (self.filter.page == 0) {
          self.registrationList = response.registrationList;
        } else {
          self.registrationList = self.registrationList.concat(response.registrationList);
        }
        if (response.registrationList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }

        //remover mascara
        for (let registration of self.registrationList) {
          if (registration.Driver.phone.length > 11) {
            let phone = registration.Driver.phone.slice(4, 6);
            phone += registration.Driver.phone.slice(7, 17);
            registration.Driver.phone = phone;
          }
        }
        self.noContentText = 'Nenhum cadastro para exibir';

        // self.registrationListCSV = self.prepareCSVRegistrations(self.registrationList)
      });
    },
    changeTab(value) {
      if (value != 'parks') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#registration-view {}
</style>