<template>
  <div class="registration-details-item">
    <div class="data-box">
      <div class="row">
        <div class="col-md-8 data-middle">
          <ImageItem :path="registration.Company.logo" :isSmall="true"></ImageItem>
          <div class="middle-infos">
            <p class="highlight">{{ registration.Company.name }}</p>
            <!-- <p>{{registration.Park.name}}</p> -->
            <p>Data de cadastro: {{ registration.created_at | formatDate }}</p>
            <p>Hora de cadastro: {{ registration.created_at | getHour }}</p>
          </div>
        </div>
        <div class="col-md-4 data-right">
          <p style="margin-left: 23.5px; line-height: 36px;">Status: <span class="highlight"
              v-bind:class="userStatusClass(registration.status)">{{ requestStatus(registration.status) }}</span></p>
        </div>
      </div>

      <overlay-item :route="'/registrations/' + this.registration.idregistration"></overlay-item>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  props: ["registration"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
    requestDetails() {
      window.open('/registrations/' + this.registration.idregistration, "_blank");
    }
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.registration-details-item {
  .data-box {
    padding: 0px;
    background-color: $white !important;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
    }
  }
}

@media only screen and (max-width: 600px) {
  .registration-details-item {}
}
</style>