import Vue from 'vue'

import VueMask from 'v-mask'
Vue.use(VueMask);

import money from 'v-money'
Vue.use(money, {prefix: 'R$ ', decimal: ',', thousands: '.',precision: 2})

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, email, integer } from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// import * as rules from "vee-validate/dist/rules";

// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });

extend('required', {
  ...required,
  message: 'Campo obrigatório'
});
extend('email', {
  ...email,
  message: 'Verifique o formato do email'
});
extend('integer', {
  ...integer,
  message: 'Campo deve ter apenas números'
});
extend('minLength', {
  validate(value, { min }) {
    return value.length >= min;
  },
  params: ['min'],
  message: 'Mínimo de {min} caracteres'
});
extend('maxLength', {
  validate(value, { max }) {
    return value.length <= max;
  },
  params: ['max'],
  message: 'Máximo de {max} caracteres'
});
extend('zipcode', {
  validate: value => {
    return value.length == 9;
  },
  message: 'Verifique o formato do CEP'
});
extend('year', {
  validate: value => {
    return value.length == 4;
  },
  message: 'Verifique o formato do ano'
});
extend('hourFormat', {
  validate: value => {
    return value.length == 5;
  },
  message: 'Complete o horário'
});

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);

import FileUpload from 'v-file-upload'
Vue.use(FileUpload);

// import { VueEditor } from "vue2-editor";
// Vue.use(VueEditor);
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker);
