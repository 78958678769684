<template>
  <main-layout>
    <div id="driver-approve-third">
      <div class="driver-approve-content">
        <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
        <h1>Vínculo com terceiro</h1>
        <p>{{ text }}</p>
      </div>
    </div>

  </main-layout>
</template>

<script>
import driverMixin from "../mixins/driver.mixin.js";
import MainLayout from "../layouts/Main.vue";

export default {
  mixins: [driverMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      key: null,
      action: null,
      text: '',
    };
  },
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    this.key = this.$route.query.key
    this.action = this.$route.query.action
    if (this.action == 1) this.text = 'Aprovando...'
    else this.text = 'Rejeitando...'
    this.approveDeny()
  },
  methods: {
    approveDeny() {
      let self = this;
      this.approveDenyThird(this.key, this.action).then((response) => {
        if (response) {
          self.text = response.message
          this.key = ''
          this.action = ''
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.message,
          })
        } else {
          self.text = 'Erro ao rejeitar vínculo'
        }
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#driver-approve-third {
  height: 100vh;

  .driver-approve-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    h1 {
      text-align: center;
      margin-top: 35px;
      margin-bottom: 35px;
    }

    p {
      text-align: center;
      margin-bottom: 25px;
    }

    .reset-btns {
      text-align: center;
      margin-top: 30px;
    }
  }

}
</style>