import Vue from 'vue'
// import { publicPath } from '../../vue.config'
import { local, server } from '../js/variables.js'
import { setLocalStorage } from "../js/encryption";


var navigationMixin = {
  data() {
    return {

    }
  },
  methods: {
    loadData() {
      let self = this;
      console.log("navigationMixin load inicio ");
      this.axios.get(server.api_url + '/load')
        .then(function (response) {
          let responseData = response.data.data;
          self.setData(responseData);
        })
        .catch(function (error) {
          console.log("navigationMixin load: ", error);
          this.$router.push('/login');
        });
    },
    setData(responseData) {
      local.auth = responseData;
      local.user = responseData.user;
      setLocalStorage('token', responseData.token);
    },
    showError(error) {
      if (error.response) {
        // console.log("Response", error.response.data);
        // console.log("Response", error.response.status);
        // console.log("Response", error.response.headers);

        var answer = error.response.data;
        console.log(answer);
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    getCustomer() {
      console.log("Customer ", this.customer);
      return this.customer;
    }
  }
}

// export default navigationMixin;
Vue.mixin(navigationMixin);
