<template>
  <div class="employeee-item">
    <div class="data-item">
      <div class="row">
        <div class="col-md-9 data-middle" style="height: 105px;">
          <ImageItem :path="userItem.image_photo" :isSmall="false"></ImageItem>
          <div class="middle-infos">
            <p class="highlight">Nome: {{ userItem.name }} {{ userItem.lastname }}</p>
            <p>E-mail: {{ userItem.email }}</p>
            <p>Aniversário: {{ userItem.birthday | formatDate | verifyIfNull }}</p>
            <p>Gênero: {{ userGender(userItem.gender) | verifyIfNull }}</p>
          </div>
        </div>
        <div class="col-md-3 data-right">
          <p>Função: <span class="highlight">{{ employeeJob(userItem.job) }}</span></p>
          <p>Registro: {{ userItem.created_at | formatDate }}</p>
          <!-- <p style="display: inline-block;">Cargo: </p><p style="display: inline-block;" class="highlight">{{userJob(user.job)}}</p> -->
          <p>Status: <span class="highlight" v-bind:class="userStatusClass(userItem.status)">{{
            userStatus(userItem.status) }}</span></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["userItem"],
  components: {
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.employeee-item {
  .image-box {
    height: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .employeee-item {}
}
</style>