import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var errorMixin = {
  data: function () {
    return {
    }
  },
  methods: {
    listErrorsMixin(limit, page, search, order, sort, status, startDate, endDate, type, call, idpark) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit,
        page,
        search,
        order,
        sort,
        status,
        from: startDate,
        to: endDate,
        type,
        call,
        idpark
      };

      return axios.get(server.api_url + '/error-log', config)
        .then((response) => {
          let responseData = response.data.data;


          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
  }
};

export default errorMixin;