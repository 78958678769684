<template>
  <div class="request-details-item">
    <div class="data-box">
      <div class="row">
        <div class="col-md-8 data-middle">
          <ImageItem :path="request.Company.logo" :isSmall="true"></ImageItem>
          <div class="middle-infos">
            <p class="highlight">{{ request.Company.name }}</p>
            <!-- <p>{{request.Park.name}}</p> -->
            <p>Data de solicitação: {{ request.created_at | formatDate }}</p>
            <p>Hora de solicitação: {{ request.created_at | getHour }}</p>
          </div>
        </div>
        <div class="col-md-4 data-right" v-if="!simple">
          <p>Data: {{ request.day | formatDate }}</p>
          <p>Hora solicitada: {{ request.hour }}</p>
        </div>
        <div class="col-md-4 data-right" v-else>
          <p style="margin-left: 23.5px; line-height: 36px;">Status: <span class="highlight"
              v-bind:class="userStatusClass(request.status)">{{ requestStatus(request.status) }}</span></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import ImageItem from '@/components/ImageItem.vue'

export default {
  props: ["request", "simple"],
  components: {
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.request-details-item {
  .data-box {
    padding: 0px;
    background-color: $white !important;
    // cursor: pointer;

    // &:hover{
    //   border: 1px solid $primary;
    // }
  }
}

@media only screen and (max-width: 600px) {
  .request-details-item {}
}
</style>