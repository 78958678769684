<template>
  <div class="error-item">
    <div class="data-item accordion" :id="'accordionPanelsStayOpenExample' + errorItem.iderrorlog"
      v-bind:style="errorItem.message == '' ? 'cursor: initial' : 'cursor: pointer'">

      <div class="row collapsed" type="button" data-bs-toggle="collapse"
        :data-bs-target="'#panelsStayOpen-collapseOne' + errorItem.iderrorlog" aria-expanded="false"
        :aria-controls="'panelsStayOpen-collapseOne' + errorItem.iderrorlog">
        <div class="col-md-5">
          <!-- <div class="image-box" :style="'background-image: url(' + getImageUrl(errorItem.Park.image_photo) + ')'" v-bind:class="{ 'image-box-empty': !errorItem.Park.image_photo }"></div> -->
          <div class="middle-infos" style="padding: 15px 10px">
            <p class="highlight">Data: {{ errorItem.created_at | formatDateTime }}</p>
            <!-- <p style="margin-top: 7px">Parque: <a href="">{{errorItem.Park.name}}</a></p> -->
            <p style="margin-top: 7px; display: inline-block">Parque: <span v-if="!errorItem.Park.name">-</span></p>
            <button class="clean details-btn" @click="parkDetails($event)" v-if="errorItem.Park.name">{{
                errorItem.Park.name
            }}</button>
          </div>
        </div>
        <div class="col-md-3 data-middle">
          <div class="middle-infos">
            <p>Status: <span class="highlight" v-bind:class="integrationStatusClass(errorItem.status)">{{
                errorItem.status | verifyIfNull
            }}</span></p>
            <p style="margin-top: 7px; display: inline-block">Solicitação: <span v-if="!errorItem.idrequest">-</span>
            </p>
            <button class="clean details-btn" @click="requestDetails($event)" v-if="errorItem.idrequest">{{
                errorItem.idrequest
            }}</button>
            <!-- <p style="margin-top: 7px">Solicitação: <a :href="'./requests/' + errorItem.idrequest" v-if="errorItem.idrequest">{{errorItem.idrequest}}</a> <span v-else>-</span></p> -->
          </div>
        </div>
        <div class="col-md-4 data-right">
          <p>Chamada: {{ errorItem.call | verifyIfNull }}</p>
          <div style="text-align: right;">
            <p style="margin-top: 7px; display: inline-block">Acesso: <span v-if="!errorItem.identry">-</span></p>
            <button class="clean details-btn" @click="entryDetails($event)" v-if="errorItem.identry">{{
                errorItem.identry
            }}</button>
          </div>
          <!-- <p style="margin-top: 7px">Acesso: <a href="" v-if="errorItem.identry">{{errorItem.identry}}</a> <span v-else>-</span></p> -->
        </div>
      </div>
      <div :id="'panelsStayOpen-collapseOne' + errorItem.iderrorlog" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <p v-if="errorItem.message == null || errorItem.message == ''">Nenhum detalhe para exibir</p>
          <json-viewer :value="errorItem.message" v-else></json-viewer>
        </div>
      </div>



      <!-- <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
              Accordion Item #1
            </button>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">
              <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              Accordion Item #2
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">
              <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";

export default {
  mixins: [userMixin],
  props: ["errorItem"],
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    // console.log(this.$route)
  },
  watch: {},
  methods: {
    parkDetails(event) {
      if (event.stopPropagation) event.stopPropagation();
      this.openNewTab('/parks/' + this.errorItem.idpark)
      // this.$router.push('/parks/' + this.errorItem.idpark);
    },
    requestDetails(event) {
      if (event.stopPropagation) event.stopPropagation();
      this.openNewTab('/request-view/' + this.errorItem.idrequest)
      // this.$router.push('/requests/' + this.errorItem.idrequest);
    },
    entryDetails(event) {
      if (event.stopPropagation) event.stopPropagation();
      this.openNewTab('/entries/' + this.errorItem.identry)
      // this.$router.push('/entries/' + this.errorItem.identry);
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.error-item {

  .data-item {
    .data-middle {
      height: 75px;
    }

    .details-btn {
      font-size: 13px;
      font-weight: 300;
      text-decoration: underline;
      color: #0d6efd;
    }
  }


  // .accordion-button{
  //   border: none;
  //   box-shadow: none;
  //   outline: none !important;
  // }
  // .accordion-button:not(.collapsed) {
  //   color: red;
  //   background-color: #e7f1ff;
  //   box-shadow: none;
  // }
}

@media only screen and (max-width: 600px) {
  .error-item {}
}
</style>