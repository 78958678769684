var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',[_c('div',{attrs:{"id":"app-driver-register"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 left-part"},[_c('img',{staticClass:"logo center",attrs:{"src":"/static/img/glp-logomark@2x.png","alt":"GLP logo"}}),_c('h2',[_vm._v("Cadastro de motorista")]),_c('p',{staticClass:"description"}),_c('ValidationObserver',{ref:"validator",staticClass:"col-md-9"},[_c('ok-input',{attrs:{"model":_vm.driver.name,"label":'Nome',"placeholder":' ',"id":'name',"name":'name',"rules":"required"},on:{"updated":(x) => {
                _vm.driver.name = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.lastname,"label":'Sobrenome',"placeholder":' ',"id":'lastname',"name":'lastname',"rules":"required"},on:{"updated":(x) => {
                _vm.driver.lastname = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.cpf,"label":'CPF',"mask":'###.###.###-##',"placeholder":' ',"id":'cpf',"name":'cpf',"rules":"required"},on:{"updated":(x) => {
                _vm.driver.cpf = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.birthday,"label":'Nascimento',"placeholder":' ',"id":'birthday',"name":'birthday',"isDate":true,"rules":"required","mask":'##/##/####'},on:{"updated":(x) => {
                _vm.driver.birthday = x
              }}}),_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.driver.gender,"label":'Sexo',"options":_vm.genderOptions,"placeholder":' ',"id":'gender',"name":'gender'},on:{"updated":(x) => {
                _vm.driver.gender = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.email,"label":'E-mail',"placeholder":' ',"id":'email',"name":'email',"rules":"required|email"},on:{"updated":(x) => {
                _vm.driver.email = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.phone,"label":'Telefone',"mask":'(##) #####-####',"placeholder":' ',"id":'phone',"name":'phone',"disabled":_vm.disablePhone,"rules":"required"},on:{"updated":(x) => {
                _vm.driver.phone = x
              }}}),_c('hr'),_c('ok-input',{attrs:{"model":_vm.driver.company_cnpj,"label":'CNPJ',"mask":'##.###.###/####-##',"placeholder":' ',"id":'company_cnpj',"name":'company_cnpj'},on:{"updated":(x) => {
                _vm.driver.company_cnpj = x
              }}}),_c('ok-input',{attrs:{"model":_vm.driver.company_name,"label":'Nome',"placeholder":' ',"id":'company_name',"name":'company_name'},on:{"updated":(x) => {
                _vm.driver.company_name = x
              }}}),_c('hr'),_c('div',{staticClass:"images"},[_c('div',{staticClass:"image-item app-btn app-btn-outline",on:{"click":function($event){return _vm.openCamera('photo')}}},[(_vm.driver.image_photo)?_c('img',{attrs:{"src":_vm.driver.image_photo,"alt":"driver image"}}):_c('div',{staticClass:"image-empty"}),_c('span',[_vm._v("Cadastrar foto")]),_c('i',{staticClass:"fas fa-chevron-right chevron-right"})]),_c('div',{staticClass:"image-item app-btn app-btn-outline",on:{"click":function($event){return _vm.openCamera('document')}}},[(_vm.driver.image_document)?_c('img',{attrs:{"src":_vm.driver.image_document,"alt":"driver image"}}):_c('div',{staticClass:"image-empty"}),_c('span',[_vm._v("Cadastrar foto da CNH")]),_c('i',{staticClass:"fas fa-chevron-right chevron-right"})])]),_c('ok-input',{attrs:{"model":_vm.driver.cnh,"label":'CNH',"mask":'###########',"placeholder":' ',"id":'cnh',"name":'cnh'},on:{"updated":(x) => {
                _vm.driver.cnh = x
              }}})],1),_c('button',{staticClass:"app-btn app-btn-outline register-btn",attrs:{"id":"back-btn"},on:{"click":function($event){return _vm.goTo('/visitante/login')}}},[_vm._v(" Voltar ")]),_c('button',{staticClass:"app-btn app-btn-primary register-btn",attrs:{"id":"register-btn"},on:{"click":function($event){return _vm.registerDriver()}}},[_vm._v(" Cadastrar ")])],1),_c('div',{staticClass:"col-md-8 no-padding"},[_c('div',{staticClass:"image-box",staticStyle:{"background-image":"url('./static/img/glp-main.png')"}})])]),(_vm.showCamera)?_c('div',{staticClass:"camera-modal"},[_c('camera-capture',{on:{"image-captured":_vm.onImageCaptured,"camera-error":_vm.onCameraError}}),_c('button',{staticClass:"close-btn",on:{"click":_vm.closeCamera}},[_vm._v("Fechar")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }