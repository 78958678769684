<template>
  <div class="data-item">
    <div class="row">
      <div class="col-md-5 data-middle">
        <ImageItem :path="report.Request.Driver.image_photo" :isSmall="true"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">{{ report.Request.Driver.name }} {{ report.Request.Driver.lastname }}</p>
          <p>{{ report.Request.Driver.company_name }}</p>
          <p>Telefone: {{ report.Request.Driver.phone | formatPhone | verifyIfNull }}</p>
        </div>
      </div>
      <div class="col-md-4 data-middle">
        <div class="middle-infos">
          <p class="highlight">{{ report.Request.Park.name }}</p>
          <p>Segurança: {{ report.User.name }} {{ report.User.lastname }}</p>
          <p>Portaria: -</p>
        </div>
      </div>
      <div class="col-md-3 data-right">
        <p>Data: {{ report.created_at | formatDateTime }}</p>
        <p>Tipo: {{ report.ReportType.name }}</p>
      </div>
    </div>

    <overlay-item :route="'/reports/' + report.idreport"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["report"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.report-item {}

@media only screen and (max-width: 600px) {
  .report-item {}
}
</style>