import axios from 'axios';
import { server } from '../js/variables.js'
import moment from 'moment'

var registrationMixin = {
  data: function () {
    return {
      registration: {
        range: null,
      },
    }
  },
  methods: {
    listRegistrationsMixin(limit, page, search, order, sort, status, startDate, endDate, company, iddriver) {
      const self = this;
      // startDate = '2021-07-01'
      // endDate = '2021-07-02'
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        from: startDate,
        to: endDate,
        idcompany: company,
        iddriver: iddriver,
      };
      
      return axios.get(server.api_url + '/registrations', config)
      .then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    getRegistrationMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/registrations/' + id, server.config)
      .then((response) => {
        let responseData = response.data.data;

        //remover mascara
        if(responseData.registration.Driver.phone && responseData.registration.Driver.phone.length > 11){
          responseData.registration.Driver.phone = self.removeMaskPhoneDriver(responseData.registration.Driver.phone);
        }

        if(responseData.registration.Driver.company_cnpj && responseData.registration.Driver.company_cnpj.length > 14){
          let cnpj = responseData.registration.Driver.company_cnpj.slice(0, 8);
          cnpj += responseData.registration.Driver.company_cnpj.slice(-6);
          responseData.registration.Driver.company_cnpj = cnpj;
        }
        
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    approveRegistrationMixin(registration){
      const self = this;
      let status = registration.status;
      if(status != 2){
        status = 2
      }
      let registrationToSend= {
        // hour_min: registration.hour_min,
        // hour_max: registration.hour_max,
        status: status,
      }
      this.axios.put(server.api_url + '/registrations/' + registration.idregistration, {
        registration: registrationToSend,
      }).then((response) => {
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: response.data.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
          }).then((result) => {
          if (result.isConfirmed) {
            location.reload();
          }
        })
      })
      .catch(function (error) {
        self.showError(error);
      });
    },
    denyRegistrationMixin(registration){
      const self = this;
      let status = registration.status;
      if(status != -1){
        status = -1
      }
      let registrationToSend= {
        // hour_min: registration.hour_min,
        // hour_max: registration.hour_max,
        status: status,
      }
      this.axios.put(server.api_url + '/registrations/' + registration.idregistration, {
        registration: registrationToSend,
      }).then((response) => {
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: response.data.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
          }).then((result) => {
          if (result.isConfirmed) {
            location.reload();
          }
        })
      })
      .catch(function (error) {
        self.showError(error);
      });
    },
    prepareCSVRegistrations(data){
      let array = JSON.parse(JSON.stringify(data));
      let arrayReady = [];
      for(let item of array){
        item.created_at = moment(String(item.created_at)).format('DD/MM/YYYY')
        item.Driver.created_at = moment(String(item.Driver.created_at)).format('DD/MM/YYYY')
        let primeiraParte = item.Driver.phone.slice(0, 2);
        let segundaParte = item.Driver.phone.slice(2, 7);
        let terceiraParte = item.Driver.phone.slice(-4);
        item.Driver.phone = '(' + primeiraParte + ') ' + segundaParte + '-' + terceiraParte;

        let objFormated = {
          code: item.idregistration,
          status: this.userStatus(item.status) ,
          created_at: item.created_at,
          updated_at: item.updated_at,
          expiration: item.expiration ,

          // user
          approver_name: item.User ? item.User.name + item.User.lastname : "",
          approver_phone: item.User ? item.User.phone : "",
          approver_email: item.User ? item.User.email : "",

          // driver
          driver_name: item.Driver.name + item.Driver.lastname,
          driver_cpf: item.Driver.cpf,
          driver_cnh: item.Driver.cnh,
          driver_phone: item.Driver.phone,
          driver_email: item.Driver.email,

          // driver_company
          driver_company_name: item.Driver.company_name,
          driver_company_cnpj: item.Driver.company_cnpj,

          // company
          company_name: item.Company.name,
          company_cnpj: item.Company.cnpj,
          company_phone: item.Company.phone,
          company_email: item.Company.email,
        };
        arrayReady.push(objFormated)
      }
      
      // console.log('arrayReady', arrayReady)
      return arrayReady;
    },
  }
};

export default registrationMixin;