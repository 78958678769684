import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var applicationMixin = {
  data: function () {
    return {
      application: {
        app_key: '',
        secret_key: '',
        registration_url: '',
        request_url: '',
        entry_url: '',
      },
    }
  },
  methods: {
    listApplicationsMixin(limit, page, search, order, sort, status) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
      };

      return axios.get(server.api_url + '/applications', config)
        .then((response) => {
          let responseData = response.data.data;

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getApplicationMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/applications/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.application.status = self.fixStatus(responseData.application.status);

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveApplicationMixin(application, param) {
      const self = this;
      let applicationToSend = JSON.parse(JSON.stringify(application));
      applicationToSend.status = self.fixStatus(applicationToSend.status);

      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          this.axios.post(server.api_url + '/applications/', {
            application: applicationToSend,
          }).then((response) => {
            let responseData = response.data.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/applications/' + responseData.application.idapp, '_self');
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          this.axios.put(server.api_url + '/applications/' + applicationToSend.idapp, {
            application: applicationToSend,
          }).then((response) => {
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteApplicationMixin(id) {
      const self = this;
      // console.log('aqui')
      this.$swal.fire({
        title: 'Realmente deseja excluir esta aplicação?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/applications/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/applications/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },
  }
};

export default applicationMixin;