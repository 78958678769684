<template>
  <div class="camera-capture">
    <div v-if="!capturedImage" class="camera-container">
      <WebCam
        ref="webcam"
        :device-id="deviceId"
        width="100%"
        height="auto"
        @started="onCameraStarted"
        @stopped="onCameraStopped"
        @error="onCameraError"
        @cameras="onCameras"
        @camera-change="onCameraChange"
      />
      <div class="camera-controls">
        <button @click="capture" class="capture-btn" :disabled="isLoading">
          {{ isLoading ? 'Aguarde...' : 'Capturar' }}
        </button>
        <button
          @click="switchCamera"
          class="switch-btn"
          v-if="cameras.length > 1"
        >
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </div>
    <div v-else class="preview-container">
      <img :src="capturedImage" alt="Captured image" class="preview-image" />
      <div class="preview-controls">
        <button @click="retake" class="retake-btn">Tirar novamente</button>
        <button @click="confirm" class="confirm-btn">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam'

export default {
  name: 'CameraCapture',
  components: {
    WebCam,
  },
  data() {
    return {
      deviceId: null,
      cameras: [],
      capturedImage: null,
      isLoading: true,
    }
  },
  methods: {
    capture() {
      this.capturedImage = this.$refs.webcam.capture()
    },
    retake() {
      this.capturedImage = null
      this.cameraOn = true
      this.$nextTick(() => {
        if (this.$refs.webcam) {
          this.$refs.webcam.start()
        }
      })
    },
    confirm() {
      this.$emit('image-captured', this.capturedImage)
    },
    switchCamera() {
      if (this.cameras.length > 1) {
        const currentIndex = this.cameras.findIndex(
          (camera) => camera.deviceId === this.deviceId
        )
        const nextIndex = (currentIndex + 1) % this.cameras.length
        this.deviceId = this.cameras[nextIndex].deviceId
      }
    },
    onCameraStarted(stream) {
      console.log('Camera started!', stream)
      this.isLoading = false
    },
    onCameraStopped(stream) {
      console.log('Camera stopped!', stream)
    },
    onCameraError(error) {
      console.error('Camera error:', error)
      this.$emit('camera-error', error)
    },
    onCameras(cameras) {
      this.cameras = cameras
      if (cameras.length > 0) {
        this.deviceId = cameras[0].deviceId
      }
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
    },
  },
}
</script>

<style scoped lang="scss">
.camera-capture {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.camera-container,
.preview-container {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  background-color: #000;
  overflow: hidden;
}

.camera-controls,
.preview-controls {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.capture-btn {
  &:disabled {
    background-color: #ccc !important;
    color: #666;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
  }
}

.capture-btn,
.confirm-btn {
  background-color: #4caf50 !important;
  color: white;
}

.switch-btn {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
