import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var dashboardMixin = {
    data: function () {
        return {
            stats: null,
        }
    },
    methods: {
        getStatsMixin(idpark, idcompany, startDate, endDate) {
            const self = this;
            let config = JSON.parse(JSON.stringify(server.config));
            config["params"] = {
                idpark: idpark,
                idcompany: idcompany,
                from: startDate,
                to: endDate,
            };

            return axios.get(server.api_url + '/dashboard', config)
            .then((response) => {
                let responseData = response.data.data;
                self.stats = responseData;

                return responseData;
            })
            .catch(function (error) {
              self.showError(error);
              return null;
            });
        }

    }
};

export default dashboardMixin;