import axios from 'axios';
import { local, server } from '../js/variables.js';
import { getLocalStorage, setLocalStorage } from "../js/encryption";

var authMixin = {
  data: function () {
    return {
      auth: {},
      excludedRoutes: [
        '/login',
        '/reset-password',
        '/approve',
        '/visitante/remocao-de-conta',
        '/visitante/login',
        '/visitante/cadastro',
        '/visitante/cadastro-solicitacao',
        '/visitante/lista-solicitacoes',
        '/visitante/solicitacao/',
        '/visitante/home'
      ]
    }
  },
  mounted() {
    this.listenUser();
  },
  methods: {
    loadData() {
      const self = this;
      if (!this.excludedRoutes.some(route => this.$route.fullPath.includes(route))) {
        this.axios.get(server.api_url + '/load')
          .then(function (response) {
            let responseData = response.data;
            self.auth = responseData.user;
            local.auth = responseData;
            local.user = responseData.user;
            setLocalStorage('token', responseData.token);
            setLocalStorage('user', responseData.user);
          })
          .catch(function (error) {
            console.log("navigationMixin load: ", error);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.open("/login", "_self");
          });
      }
    },
    watchUser() {
      setTimeout(this.listenUser(), 1000);
    },
    listenUser() {
      if (localStorage.user) {
        this.auth = getLocalStorage('user');
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$toasted.show('Sessão encerrada com sucesso');
      this.$router.push('/login');
    },
    login(loginData) {
      const self = this;
      return axios.post(server.api_url + "/login", loginData
      ).then(function (response) {
        let token = response.data.data.token;
        let user = response.data.data.user;
        setLocalStorage('token', token);
        setLocalStorage('user', user);
        self.auth = user;
        local.user = user;
        return self.auth;
      }).catch(function (error) {
        self.showError(error);
      });
    },
    forgotPasswordMixin(email) {
      const self = this;
      return axios.post(server.url + '/forgot-password/', {
        email: email
      })
        .then(function (response) {
          let responseData = response.data;
          self.$swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
          })
          return responseData
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    resetPasswordMixin(resetData) {
      const self = this;
      this.axios.post(server.api_url + "/reset-password", {
        email: resetData.email,
        password: resetData.password,
        confirm: resetData.confirm,
        token: resetData.token,
      }).then(function (response) {
        let responseData = response.data;
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: responseData.message,
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            self.goTo('/login');
          }
        })

      }).catch(function (error) {
        self.showError(error);
      });
    },
    isAdministrator() {
      const user = this.auth;

      return user && user.job === '10';

    },
    isCoordinator() {
      const user = this.auth;
      return user && user.job === '11';
    },
    isGlpMember() {
      return this.isAdministrator() || this.isCoordinator();
    },
    isCompanyAdm() {
      const user = this.auth;
      return user && user.job === '20' || this.isGlpMember();
    },
    isCompany() {
      const user = this.auth;
      return user && user.job === '21' || this.isGlpMember();
    },
    isCompanyMember() {
      const user = this.auth;
      return user && user.job === '20' || user && user.job === '21'
    },
    isSecurityAdm() {
      const user = this.auth;
      return user && user.job === '30' || this.isGlpMember();
    },
    isSecurity() {
      const user = this.auth;
      return user && user.job === '31' || this.isGlpMember();
    },
    isSecurityMember() {
      return this.isSecurityAdm() || this.isSecurity();
    },
    isThirdCompanyAdm() {
      const user = this.auth;
      return user && user.job === '40' || this.isAdministrator();
    },
    isThirdCompany() {
      const user = this.auth;
      return user && user.job === '41' || this.isThirdCompanyAdm();
    },
    isThirdMember() {
      return this.isThirdCompanyAdm() || this.isThirdCompany();
    },
    getJobOpbtionsCreate() {
      const user = this.auth;
      let jobs = [
        { name: "Administrador", value: '10' },
        { name: "Coordenador", value: '11' },
        { name: "Locatário Adm", value: '20' },
        { name: "Locatário Aprovador", value: '21' },
        { name: "Segurança Adm", value: '30' },
        { name: "Segurança", value: '31' },
        { name: "Terceiro Adm", value: '40' },
        { name: "Terceiro Funcionário", value: '41' },
      ];
      switch (user.job) {
        case '10':
          return jobs;

        case '11':
          jobs.splice(0, 1);
          return jobs;

        case '20':
          jobs.splice(-2);
          jobs.splice(1, 1);
          jobs.splice(0, 1);
          return jobs;

        case '21':
          jobs.splice(-2);
          jobs.splice(2, 1);
          jobs.splice(1, 1);
          jobs.splice(0, 1);
          return jobs;

        case '30':
          jobs.splice(3, 1);
          jobs.splice(2, 1);
          jobs.splice(1, 1);
          jobs.splice(0, 1);
          return jobs;

        case '31':
          jobs.splice(4, 1);
          jobs.splice(3, 1);
          jobs.splice(2, 1);
          jobs.splice(1, 1);
          jobs.splice(0, 1);
          return jobs;

        default:
          break;
      }
    },
  }
};

export default authMixin;