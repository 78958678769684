<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ok-input
          :model="item.postal_code"
          @updated="
            (x) => {
              item.postal_code = x;
            }
          "
          @onBlur="getAddressMixin(item.postal_code, item)"
          @onEnter="getAddressMixin(item.postal_code, item)"
          :label="'CEP'"
          :placeholder="' '"
          :id="'postal_code'"
          :name="'postal_code'"
          :mask="'#####-###'"
          :rules="'zipcode'"
          v-if="isPostalCode"
        >
        </ok-input>
        <ok-input
          :model="item.zipcode"
          @updated="
            (x) => {
              item.zipcode = x;
            }
          "
          @onBlur="getAddressMixin(item.zipcode, item)"
          @onEnter="getAddressMixin(item.zipcode, item)"
          :label="'CEP'"
          :placeholder="' '"
          :id="'zipcode'"
          :name="'zipcode'"
          :mask="'#####-###'"
          :rules="'zipcode'"
          v-else
        >
        </ok-input>
        <ok-input
          :model="item.street"
          @updated="
            (x) => {
              item.street = x;
            }
          "
          :label="'Endereço'"
          :placeholder="' '"
          :id="'street'"
          :name="'street'"
        >
        </ok-input>
        <ok-input
          :model="item.number"
          @updated="
            (x) => {
              item.number = x;
            }
          "
          :label="'Número'"
          :placeholder="' '"
          :id="'number'"
          :name="'number'"
        >
        </ok-input>
        <ok-input
          :model="item.state"
          @updated="
            (x) => {
              item.state = x;
            }
          "
          :label="'Estado'"
          :placeholder="' '"
          :id="'state'"
          :name="'state'"
        >
        </ok-input>
        <ok-input
          :model="item.city"
          @updated="
            (x) => {
              item.city = x;
            }
          "
          :label="'Cidade'"
          :placeholder="' '"
          :id="'city'"
          :name="'city'"
        >
        </ok-input>
        <ok-input
          :model="item.district"
          @updated="
            (x) => {
              item.district = x;
            }
          "
          :label="'Bairro'"
          :placeholder="' '"
          :id="'district'"
          :name="'district'"
        >
        </ok-input>
        <ok-input
          :model="item.complement"
          @updated="
            (x) => {
              item.complement = x;
            }
          "
          :label="'Complemento'"
          :placeholder="' '"
          :id="'complement'"
          :name="'complement'"
          :rules="'minLength:3'"
        >
        </ok-input>
        <ok-input
          :model="item.lat"
          @updated="
            (x) => {
              item.lat = x;
            }
          "
          @onBlur="prepareMap(item)"
          @onEnter="prepareMap(item)"
          :label="'Latitude'"
          :placeholder="' '"
          :id="'lat'"
          :name="'lat'"
        >
        </ok-input>
        <ok-input
          :model="item.lng"
          @updated="
            (x) => {
              item.lng = x;
            }
          "
          @onBlur="prepareMap(item)"
          @onEnter="prepareMap(item)"
          :label="'Longitude'"
          :placeholder="' '"
          :id="'lng'"
          :name="'lng'"
        >
        </ok-input>
        <ok-input
          :model="item.range"
          @updated="
            (x) => {
              item.range = x;
            }
          "
          @onBlur="prepareMap(item)"
          @onEnter="prepareMap(item)"
          :label="'Raio da cerca virtual (m)'"
          :placeholder="' '"
          :id="'range'"
          :name="'range'"
          :type="'number'"
           v-if="this.$route.fullPath.indexOf('/parks/' + this.$route.params.id ) != -1 "
        >
        </ok-input>
      </div>
      <div class="col-md-8">
        <GmapMap
          :center="center"
          :zoom="zoom"
          map-type-id="roadmap"
          style="width: 100%; height: 100%"
        >
          <GmapMarker
            ref="mapRef"
            :position="position"
            :clickable="false"
            :draggable="false"
            :icon="{url: '/static/img/glp-pin.png', scaledSize: {width: 40, height: 40}}"
          />
          <GmapCircle
            :center="center"
            :radius="stringToFloat(item.range)"
            :visible="true"
            :options="{strokeColor: '#000000',fillOpacity:0.15,strokeColor: '#44AF3B',strokeOpacity: 1,strokeWeight: 1.5}"
            :clickable="false"
          ></GmapCircle>
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import OkInput from '@/components/OkInput.vue'

export default {
  props: ["item", "isPostalCode"],
  components: {
    OkInput,
  },
  data() {
    return {
      zoom: 12,
      center: {
        lat: -23.5902,
        lng: -46.6819,
      },
      position: null,
    };
  },
  mounted() {
    // this.prepareMap(this.item);
    // if(this.item.lat == undefined && this.item.lng == undefined){
    //   this.item.lat = -23.5902;
    //   this.item.lng = -46.6819;
    // }
    // console.log('mounted', this.item.lat)
    // console.log('mounted', this.$route)
        
  },
  watch: {
    item() {
      if (this.item.lat) {
        // console.log('prepareMap', this.item)
        this.prepareMap(this.item)
      }
    },
  },
  methods: {
    prepareMap(address){
      let lat = parseFloat(address.lat);
      let lng = parseFloat(address.lng);
      // console.log('prepareMap', address)
      this.center.lat = lat;
      this.center.lng = lng;
      // this.position = new google.maps.LatLng(lat, lng);
      this.position = {lat: lat, lng: lng};
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";
.entry-item {
}

@media only screen and (max-width: 600px) {
  .entry-item {
  }
}
</style>