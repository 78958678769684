<template>
  <main-layout>
    <div id="app-driver-request-register">
      <div class="row">
        <div class="col-md-4 left-part">
          <img
            src="/static/img/glp-logomark@2x.png"
            alt="GLP logo"
            class="logo center"
          />
          <h2>Cadastro de solicitação</h2>

          <div class="request-types" v-if="step === 1">
            <p class="description">
              Informe o tipo de solicitação que deseja realizar.
            </p>

            <button
              class="request-type app-btn app-btn-outline"
              @click="handleTypeSelection('docks')"
            >
              <span> Acesso às docas</span>
              <i class="fas fa-chevron-right"></i>
            </button>

            <button
              class="request-type app-btn app-btn-outline"
              @click="handleTypeSelection('vehicle')"
            >
              <span> Acesso com veículo</span>
              <i class="fas fa-chevron-right"></i>
            </button>

            <button
              class="request-type app-btn app-btn-outline"
              @click="handleTypeSelection('pedestrian')"
            >
              <span> Acesso pedestre</span>
              <i class="fas fa-chevron-right"></i>
            </button>

            <button
              class="app-btn app-btn-outline"
              style="margin-top: 40px"
              id="go-to-home-btn"
              @click="goTo('/visitante/home')"
            >
              Voltar para home
            </button>
          </div>

          <ValidationObserver ref="validator" v-else-if="step === 2">
            <p class="description">Qual o seu destino?</p>
            <ok-input
              :model="request.idpark"
              @updated="
                (x) => {
                  request.idpark = x
                }
              "
              :label="'Parque'"
              :options="parkList"
              :placeholder="' '"
              :id="'park'"
              :name="'park'"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="request.idcompany"
              @updated="
                (x) => {
                  request.idcompany = x
                }
              "
              :label="'Empresa'"
              :options="companyList"
              :placeholder="' '"
              :id="'company'"
              :name="'company'"
              style="margin-top: 4px"
              :disabled="!request.idpark || companyList.length === 0"
            >
            </ok-input>

            <ok-input
              :model="request.idthird"
              @updated="
                (x) => {
                  request.idthird = x
                }
              "
              :label="'Responsável pela entrada'"
              :options="thirdList"
              :placeholder="' '"
              :id="'third'"
              :name="'third'"
              style="margin-top: 4px"
              :disabled="!request.idcompany || thirdList.length === 0"
            >
            </ok-input>
          </ValidationObserver>

          <div class="vehicle-list" v-else-if="step === 3 && !addingVehicle">
            <p class="description">Qual veículo você pretende utilizar?</p>
            <div
              class="vehicle-item"
              v-for="vehicle in vehicleList"
              :key="vehicle.idvehicle"
              @click="handleVehicleSelection(vehicle)"
            >
              <div class="data-box">
                <div class="row">
                  <div class="col-md-3 col-6 data-left">
                    <div
                      class="car-color"
                      v-bind:style="{ backgroundColor: vehicle.color }"
                    ></div>
                    <p>
                      <span class="highlight">Tipo:</span> {{ vehicle.type }}
                    </p>
                  </div>
                  <div class="col-md-3 col-6 data-middle">
                    <p>
                      <span class="highlight">Placa:</span> {{ vehicle.plate }}
                    </p>
                  </div>
                  <div
                    class="col-md-3 col-6 data-middle data-middle-no-padding-top"
                  >
                    <p>
                      <span class="highlight">Cor:</span>
                      {{ vehicleColor(vehicle.color) }}
                    </p>
                  </div>
                  <div class="col-md-3 col-6 data-right">
                    <p>
                      <span class="highlight">Ano:</span> {{ vehicle.year }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="app-btn app-btn-outline register-vehicle-btn"
              id="register-vehicle-btn"
              @click="addVehicle()"
            >
              Adicionar novo veículo
            </button>
          </div>

          <div v-if="step === 3 && addingVehicle">
            <p class="description">Cadastro de veículo</p>

            <ok-input
              :model="newVehicle.type"
              @updated="
                (x) => {
                  newVehicle.type = x
                }
              "
              label="Tipo*"
              :placeholder="' '"
              id="vehicleType"
              name="type"
              :options="vehicleTypeList"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newVehicle.color"
              @updated="
                (x) => {
                  newVehicle.color = x
                }
              "
              label="Cor*"
              :placeholder="' '"
              id="vehicleColor"
              name="color"
              :options="vehicleColorList"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newVehicle.year"
              @updated="
                (x) => {
                  newVehicle.year = x
                }
              "
              label="Ano*"
              :placeholder="' '"
              id="vehicleYear"
              name="year"
              mask="####"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newVehicle.plate"
              @updated="
                (x) => {
                  newVehicle.plate = x.toUpperCase()
                }
              "
              label="Placa*"
              :placeholder="' '"
              id="vehiclePlate"
              name="plate"
              mask="AAA-#X##"
              style="margin-top: 4px"
            >
            </ok-input>

            <div class="action-buttons">
              <button
                class="app-btn app-btn-outline register-btn"
                id="back-btn"
                @click="addingVehicle = false"
              >
                Cancelar
              </button>
              <button
                class="app-btn app-btn-primary register-btn"
                id="next-step-btn"
                @click="registerVehicle()"
              >
                Cadastrar
              </button>
            </div>
          </div>

          <div class="vehicle-confirmation" v-else-if="step === 4">
            <p class="description">Confirmação de veículo</p>
            <div class="vehicle-item">
              <div class="data-box">
                <div class="row">
                  <div class="col-md-3 col-6 data-left">
                    <div
                      class="car-color"
                      v-bind:style="{ backgroundColor: selectedVehicle.color }"
                    ></div>
                    <p>
                      <span class="highlight">Tipo:</span>
                      {{ selectedVehicle.type }}
                    </p>
                  </div>
                  <div class="col-md-3 col-6 data-middle">
                    <p>
                      <span class="highlight">Placa:</span>
                      {{ selectedVehicle.plate }}
                    </p>
                  </div>
                  <div
                    class="col-md-3 col-6 data-middle data-middle-no-padding-top"
                  >
                    <p>
                      <span class="highlight">Cor:</span>
                      {{ vehicleColor(selectedVehicle.color) }}
                    </p>
                  </div>
                  <div class="col-md-3 col-6 data-right">
                    <p>
                      <span class="highlight">Ano:</span>
                      {{ selectedVehicle.year }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <ok-input
              :model="request.plate2"
              @updated="
                (x) => {
                  request.plate2 = x.toUpperCase()
                }
              "
              :label="'Placa 2'"
              :placeholder="' '"
              :id="'plate2'"
              :name="'plate2'"
              style="margin-top: 4px"
              :mask="'AAA-#X##'"
              v-if="hasPlate2"
            >
            </ok-input>

            <ok-input
              :model="request.plate3"
              @updated="
                (x) => {
                  request.plate3 = x.toUpperCase()
                }
              "
              :label="'Placa 3'"
              :placeholder="' '"
              :id="'plate3'"
              :name="'plate3'"
              style="margin-top: 4px"
              :mask="'AAA-#X##'"
              v-if="hasPlate3"
            >
            </ok-input>
          </div>

          <div
            class="companions-list"
            v-else-if="step === 5 && !addingCompanion"
          >
            <p class="description">Irá com algum acompanhante?</p>
            <div class="selected-companions">
              <p class="title">Acompanhantes selecionados</p>
              <div class="companion-list">
                <div
                  class="companion-item"
                  v-for="companion in request.companions"
                  :key="companion.idcompanion"
                  @click="handleCompanionSelection(companion)"
                >
                  <img
                    :src="companion.image_photo"
                    class="companion-image"
                    alt="Companion image"
                    loading="lazy"
                    v-show="companion.image_photo"
                  />
                  <div
                    class="companion-image-empty"
                    v-show="!companion.image_photo"
                  ></div>
                  <p class="companion-name">
                    <b>{{ companion.name }} {{ companion.lastname }}</b>
                  </p>
                  <p>{{ companion.cpf | formatCpf }}</p>
                  <p>{{ companion.birthday | formatDate }}</p>
                </div>
              </div>

              <p v-if="request.companions.length === 0">
                Nenhum acompanhante selecionado
              </p>
            </div>
            <div class="available-companions">
              <p class="title">Acompanhantes cadastrados</p>
              <div class="companion-list">
                <div
                  class="companion-item"
                  v-for="companion in companionList"
                  :key="companion.idcompanion"
                  @click="handleCompanionSelection(companion)"
                >
                  <img
                    :src="companion.image_photo"
                    class="companion-image"
                    alt="Companion image"
                    loading="lazy"
                    v-show="companion.image_photo"
                  />
                  <div
                    class="companion-image-empty"
                    v-show="!companion.image_photo"
                  ></div>
                  <p class="companion-name">
                    <b>{{ companion.name }} {{ companion.lastname }}</b>
                  </p>
                  <p>{{ companion.cpf | formatCpf }}</p>
                  <p>{{ companion.birthday | formatDate }}</p>
                </div>
              </div>

              <p v-if="companionList.length === 0">
                {{ companionListText }}
              </p>
            </div>

            <button
              class="app-btn app-btn-outline register-companion-btn"
              id="register-companion-btn"
              @click="addCompanion()"
            >
              Adicionar novo acompanhante
            </button>
          </div>

          <div v-if="step === 5 && addingCompanion">
            <p class="description">Cadastro de acompanhante</p>

            <ok-input
              :model="newCompanion.name"
              @updated="
                (x) => {
                  newCompanion.name = x
                }
              "
              label="Nome*"
              :placeholder="' '"
              id="companionName"
              name="name"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newCompanion.lastname"
              @updated="
                (x) => {
                  newCompanion.lastname = x
                }
              "
              label="Sobrenome*"
              :placeholder="' '"
              id="companionLastname"
              name="lastname"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newCompanion.cpf"
              @updated="
                (x) => {
                  newCompanion.cpf = x
                }
              "
              label="CPF*"
              :placeholder="' '"
              id="companionCpf"
              name="cpf"
              mask="###.###.###-##"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newCompanion.birthday"
              @updated="
                (x) => {
                  newCompanion.birthday = x
                }
              "
              label="Data de nascimento*"
              :placeholder="' '"
              id="companionBirthday"
              name="birthday"
              mask="##/##/####"
              :isDate="true"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newCompanion.phone"
              @updated="
                (x) => {
                  newCompanion.phone = x
                }
              "
              label="Telefone*"
              :placeholder="' '"
              id="companionPhone"
              name="phone"
              mask="(##) #####-####"
              style="margin-top: 4px"
            >
            </ok-input>

            <ok-input
              :model="newCompanion.email"
              @updated="
                (x) => {
                  newCompanion.email = x
                }
              "
              label="Email*"
              :placeholder="' '"
              id="companionEmail"
              name="email"
              style="margin-top: 4px"
            >
            </ok-input>

            <div class="action-buttons">
              <button
                class="app-btn app-btn-outline register-btn"
                id="back-btn"
                @click="addingCompanion = false"
              >
                Cancelar
              </button>
              <button
                class="app-btn app-btn-primary register-btn"
                id="next-step-btn"
                @click="registerCompanion()"
              >
                Cadastrar
              </button>
            </div>
          </div>

          <div v-if="step === 6">
            <p class="description">Quando pretende realizar seu acesso?</p>

            <date-picker
              class="date-picker"
              v-model="day"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              type="date"
              :inline="true"
              placeholder="Selecione a data"
              :lang="daysAndMonths"
              :disabled-date="disabledDates"
            >
            </date-picker>

            <ok-input
              :model="hour"
              @updated="
                (x) => {
                  hour = x
                }
              "
              :label="'Horário'"
              :placeholder="' '"
              :id="'hour'"
              :name="'hour'"
              :mask="'##:##'"
              style="margin-top: 4px; margin-bottom: 0px"
            >
            </ok-input>

            <ok-input
              :model="code"
              @updated="
                (x) => {
                  request.code = x
                }
              "
              :label="'Código identificador (opcional)'"
              :placeholder="' '"
              :id="'code'"
              :name="'code'"
              style="margin-top: 4px; margin-bottom: 0px"
            >
            </ok-input>
          </div>

          <div v-if="step === 7">
            <p class="description">Data solicitadas até agora</p>

            <div class="dates">
              <div
                class="date"
                v-for="(date, index) in datesAndHours"
                :key="date.day"
              >
                <p>{{ date.day | formatDate }}</p>
                <p>{{ date.hour }}</p>
                <button class="delete-btn" @click="removeDate(index)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>

            <p v-if="datesAndHours.length === 0" style="margin-bottom: 30px">
              Nenhuma data selecionada
            </p>
          </div>

          <div class="action-buttons">
            <button
              class="app-btn app-btn-outline register-btn"
              id="back-btn"
              @click="previousStep()"
              v-if="step > 1 && !addingCompanion && !addingVehicle"
            >
              {{ step === 7 ? 'Adicionar' : 'Voltar' }}
            </button>
            <button
              class="app-btn app-btn-primary register-btn"
              id="next-step-btn"
              @click="nextStep()"
              :disabled="!isStepOk()"
              v-if="step > 1 && !addingCompanion && !addingVehicle"
            >
              Continuar
            </button>
          </div>
        </div>
        <div class="col-md-8 no-padding">
          <div
            class="image-box"
            style="background-image: url('./static/img/glp-main.png')"
          ></div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import driverMixin from '../../mixins/driver.mixin.js'
import parkMixin from '../../mixins/park.mixin.js'
import companyMixin from '../../mixins/company.mixin.js'
import vehicleMixin from '../../mixins/vehicle.mixin.js'
import companionMixin from '../../mixins/companion.mixin.js'
import requestMixin from '../../mixins/request.mixin.js'
import MainLayout from '../../layouts/Main.vue'
import OkInput from '@/components/OkInput.vue'
import { getLocalStorage } from '../../js/encryption'

export default {
  mixins: [
    driverMixin,
    parkMixin,
    companyMixin,
    vehicleMixin,
    companionMixin,
    requestMixin,
  ],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      parkList: [],
      companyList: [],
      vehicleList: [],
      thirdList: [],
      companionList: [],
      driver: null,
      request: {
        idpark: null,
        idcompany: null,
        idthird: null,
        idvehicle: null,
        plate2: null,
        plate3: null,
        companions: [],
        day: null,
        hour: null,
        entry_type: null,
      },
      day: null,
      hour: null,
      code: null,
      datesAndHours: [],
      newVehicle: {
        type: null,
        plate: null,
        year: null,
        color: null,
      },
      newCompanion: {},
      step: 1,
      plate2Vehicles: ['CARRETA', 'TRUCK', 'BITREM', 'TOCO', 'VUC'],
      hasPlate2: false,
      hasPlate3: false,
      addingVehicle: false,
      addingCompanion: false,
      companionListText: 'Carregando acompanhantes...',
    }
  },
  watch: {
    'request.idpark': function (newPark) {
      if (newPark) {
        this.listCompanies(newPark)
        this.request.idcompany = null
        this.request.idthird = null
      }
    },
  },
  mounted() {
    this.driver = getLocalStorage('user')
    this.listParks()
    this.listVehicles()
  },
  methods: {
    listParks() {
      let self = this
      this.listParksMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.parkList = response.parkList.map((item) => {
          const obj = {
            name: item.name,
            value: item.idpark,
          }
          return obj
        })
      })
    },
    listCompanies(idpark) {
      let self = this
      this.listCompaniesMixin(
        500,
        null,
        null,
        'ASC',
        'name',
        null,
        idpark
      ).then((response) => {
        self.companyList = response.companyList.map((item) => {
          const obj = {
            name: item.name,
            value: item.idcompany,
          }
          return obj
        })
      })
    },
    listVehicles() {
      let self = this
      self.vehicleList = []
      this.listVehiclesMixin(500, null, null, null, null, null).then(
        (response) => {
          self.vehicleList = response.vehicleList.map((item) => {
            const obj = {
              name: `${item.plate} - ${item.type ? item.type : ''} ${
                item.year ? item.year : ''
              }`,
              value: item.idvehicle,
              type: item.type,
              plate: item.plate,
              year: item.year,
              idvehicle: item.idvehicle,
            }
            return obj
          })
        }
      )
    },
    listCompanions() {
      let self = this
      self.companionListText = 'Carregando acompanhantes...'
      this.listCompanionsMixin(
        null,
        null,
        null,
        null,
        null,
        null,
        self.driver.iddriver
      ).then((response) => {
        self.companionList = response.companionList
        self.companionListText = 'Nenhum acompanhante encontrado'
        self.companionListMapped = response.companionList.map((item) => {
          const obj = {
            name: `${item.name} ${item.lastname ? item.lastname : ''}`,
            value: item.idcompanion,
          }
          return obj
        })
      })
    },
    handleTypeSelection(type) {
      if (type === 'pedestrian') this.request.entry_type = 1
      else this.request.entry_type = 2
      this.step = 2
    },
    handleVehicleSelection(vehicle) {
      this.request.idvehicle = vehicle.idvehicle
      this.selectedVehicle = vehicle
      this.hasAditionalPlates()
      this.nextStep()
    },
    handleCompanionSelection(companion) {
      const index = this.request.companions.findIndex(
        (c) => c.idcompanion === companion.idcompanion
      )
      if (index === -1) {
        this.request.companions.push(companion)
        const companionIndex = this.companionList.findIndex(
          (c) => c.idcompanion === companion.idcompanion
        )
        if (companionIndex !== -1) {
          this.companionList.splice(companionIndex, 1)
        }
      } else {
        this.request.companions.splice(index, 1)
        this.companionList.push(companion)
      }
    },
    addVehicle() {
      this.addingVehicle = true
    },
    addCompanion() {
      this.addingCompanion = true
    },
    registerVehicle() {
      if (
        Object.keys(this.newVehicle).length > 0 &&
        Object.values(this.newVehicle).every(
          (value) => value !== '' && value !== null && value !== undefined
        )
      ) {
        this.appCreateVehicleMixin(this.newVehicle).then((response) => {
          this.$toasted.success('Veículo registrado com sucesso')
          this.request.idvehicle = response.vehicle.idvehicle
          this.addingVehicle = false
          this.listVehicles()
        })
      } else {
        this.$toasted.error('Por favor, preencha os campos do veículo.')
        return
      }
    },
    registerCompanion() {
      if (
        Object.keys(this.newCompanion).length > 0 &&
        Object.values(this.newCompanion).every(
          (value) => value !== '' && value !== null && value !== undefined
        )
      ) {
        this.registerCompanionMixin(this.newCompanion).then((response) => {
          this.$toasted.success('Acompanhante registrado com sucesso')
          this.request.companions.push(response.companion)
          this.newCompanion = {}
          this.addingCompanion = false
        })
      } else {
        this.$toasted.error(
          'Por favor, preencha todos os campos do acompanhante.'
        )
        return
      }
    },
    addDateAndHour() {
      this.datesAndHours.push({
        day: this.day,
        hour: this.hour,
        code: this.code,
      })
      this.day = null
      this.hour = null
      this.code = null
    },
    disabledDates(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      if (date < today) return true

      const dateString = date.toISOString().split('T')[0]
      return this.datesAndHours.some((item) => item.day === dateString)
    },
    removeDate(index) {
      this.datesAndHours.splice(index, 1)
    },
    previousStep() {
      if (this.step === 6 && this.request.entry_type === 1) this.step = 2
      else this.step = this.step - 1
    },
    nextStep() {
      if (this.step === 7) {
        this.createRequest()
        return
      }

      if (this.isStepOk()) this.step = this.step + 1
      if (this.step === 3 && this.request.entry_type === 1) this.step = 6
      if (this.step === 5) this.listCompanions()
      if (this.step === 7) this.addDateAndHour()
    },
    isStepOk() {
      if (this.step === 2 && (!this.request.idpark || !this.request.idcompany))
        return false
      if (this.step === 3 && !this.request.idvehicle) return false
      if (this.step === 4) return this.checkPlates()
      if (this.step === 6) return this.day && this.hour && this.hour.length >= 5

      return true
    },
    hasAditionalPlates() {
      this.setHasPlates(false, false)
      if (!this.selectedVehicle) return
      if (this.plate2Vehicles.includes(this.selectedVehicle.type))
        this.setHasPlates(true, false)
      if (this.selectedVehicle.type === 'BITREM') this.setHasPlates(true, true)
    },
    setHasPlates(plate2, plate3) {
      this.hasPlate2 = plate2
      this.hasPlate3 = plate3
    },
    checkPlates() {
      if (
        this.hasPlate2 &&
        this.hasPlate3 &&
        (!this.request.plate2 || !this.request.plate3)
      )
        return false

      if (this.hasPlate2 && !this.request.plate2) return false

      return true
    },
    cleanAditionalPlates() {
      if (!this.hasPlate2) this.request.plate2 = null
      if (!this.hasPlate3) this.request.plate3 = null
    },
    createRequest() {
      let self = this
      if (self.datesAndHours.length === 0) {
        self.$toasted.error('Adicione uma data e horário')
        return
      }

      self.cleanAditionalPlates()
      if (self.request.entry_type === 1) {
        delete self.request.idvehicle
        self.request.companions = []
      }
      let requestCopy = JSON.parse(JSON.stringify(self.request))
      let requests = []
      this.datesAndHours.forEach((element) => {
        requests.push({
          ...requestCopy,
          day: self.formatDateToServer(element.day),
          hour: element.hour,
          entry_type: self.request.entry_type,
        })
      })
      const obj = {
        window_range: parseInt(this.window_range),
        request: requests,
      }
      this.appRequestMultipleMixin(obj).then((response) => {
        if (response) {
          this.$toasted.success('Solicitação criada com sucesso')
          this.$router.push('/visitante/home')
        }
      })
    },
  },
}
</script>

<style scooped lang="scss">
@import '../../scss/colors.scss';

#app-driver-request-register {
  .left-part {
    background-color: $lightgray;
    padding: 90px 70px;
    height: 100vh;
    overflow-y: auto;

    .logo {
      height: 45px;
      margin-bottom: 90px;
      object-fit: contain;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .description {
      margin-bottom: 35px;
      font-weight: 500;
      font-size: 16px;
      color: $gray-xdk;
      text-align: center;
    }

    .date-picker {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .request-types {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .request-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 14px;
        height: 45px;
      }
    }

    .vehicle-list,
    .vehicle-confirmation {
      .vehicle-item {
        .car-color {
          display: inline-block;
          width: 20px;
          position: absolute;
          margin-top: -15px;
          margin-left: -12px;
          height: 51px;
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px;
          background-color: $orange;
          border-right: 1px solid $gray-md;
        }

        .data-box {
          padding: 0px;
          background-color: $white !important;
          cursor: pointer;
          border-radius: 10px;
          box-shadow: 0 1px 4px -3px #000000;

          &:hover {
            border: 1px solid $primary;
          }
        }

        .data-middle {
          height: auto;
        }
      }

      .register-vehicle-btn {
        margin-top: 15px;
        width: 100%;
      }
    }

    .companions-list {
      .selected-companions,
      .available-companions {
        padding-top: 15px;
        padding-bottom: 25px;

        .title {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .companion-list {
          display: grid;
          // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;

          .companion-item {
            padding: 10px;
            background-color: $white !important;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0 1px 4px -3px #000000;

            &:hover {
              border: 1px solid $primary;
            }

            .companion-image {
              aspect-ratio: 1;
              width: 80%;
              border-radius: 50%;
              margin-bottom: 20px;
            }

            .companion-image-empty {
              background-color: #ebebeb;
              aspect-ratio: 1;
              width: 80%;
              border-radius: 50%;
              margin-bottom: 20px;
            }

            .companion-name {
              margin-bottom: 5px;
              text-align: center;
            }
          }
        }
      }

      .register-companion-btn {
        margin-top: 15px;
        width: 100%;
      }
    }

    .dates {
      display: grid;
      gap: 12px;

      .date {
        flex: 1;
        padding: 10px 15px;
        background-color: $white !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;

        .delete-btn {
          color: $gray-xdk;
          background-color: transparent;
          border: none;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 15px;

      .app-btn {
        flex: 1;
      }
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #app-driver-request-register {
    .left-part {
      padding: 30px 40px;

      .logo {
        margin-bottom: 40px;
      }

      .vehicle-list,
      .vehicle-confirmation {
        .vehicle-item {
          .car-color {
            display: none;
          }

          .data-middle-no-padding-top {
            padding-top: 0px;
          }
        }
      }
    }
  }
}
</style>
