import axios from 'axios';
import { server } from '../js/variables.js'
import moment from 'moment'

var entryMixin = {
  data: function () {
    return {
      request: {
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
        range: null,
        idcompany: null,
      },
    }
  },
  methods: {
    listEntriesMixin(limit, page, search, order, sort, startDate, endDate, company, iddriver, idvehicle, idcompanion) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        from: startDate,
        to: endDate,
        idcompany: company,
        iddriver: iddriver,
        idvehicle: idvehicle,
        idcompanion: idcompanion,
      };
      
      return axios.get(server.api_url + '/entries', config)
      .then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    getEntryMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/entries/' + id, server.config)
      .then((response) => {
        let responseData = response.data.data;

        //remover mascara
        if(responseData.entry.Request.Driver.phone && responseData.entry.Request.Driver.phone.length > 11){
          responseData.entry.Request.Driver.phone = self.removeMaskPhoneDriver(responseData.entry.Request.Driver.phone);
        }

        if(responseData.entry.Request.Driver.company_cnpj && responseData.entry.Request.Driver.company_cnpj.length > 14){
          let cnpj = responseData.entry.Request.Driver.company_cnpj.slice(0, 8);
          cnpj += responseData.entry.Request.Driver.company_cnpj.slice(-6);
          responseData.entry.Request.Driver.company_cnpj = cnpj;
        }
        
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    prepareCSVEntries(data){
      let array = JSON.parse(JSON.stringify(data));
      let arrayReady = [];
      for(let item of array){
        item.created_at = moment(String(item.created_at)).format('DD/MM/YYYY')
        item.Request.created_at = moment(String(item.Request.created_at)).format('DD/MM/YYYY')
        item.Request.Vehicle.created_at = moment(String(item.Request.Vehicle.created_at)).format('DD/MM/YYYY')

        let objFormated = {
          code: item.identry,
          access_in: item.access_in,
          access_out: item.access_out,
          created_at: item.created_at,

          // request 
          request_code: item.Request.idrequest,
          request_status: this.userStatus(item.Request.status) ,
          request_created_at: item.Request.created_at,
          request_hour: item.Request.hour ,
          request_hour_min: item.Request.hour_min ,
          request_hour_max: item.Request.hour_max ,
          request_company_code: item.Request.company_code ,
          
          // request_company
          request_company_name: item.Request.Company.name,

          // request_driver
          request_driver_name: item.Request.Driver.name,
          request_driver_lastname: item.Request.Driver.lastname,
          request_driver_company_name: item.Request.Driver.company_name,
          // request_driver_image_photo: item.Request.Driver.image_photo,

          // request_park
          request_park_name: item.Request.Park.name,

          // request_vehicle
          request_vehicle_brand: item.Request.Vehicle.brand,
          request_vehicle_color: item.Request.Vehicle.color,
          request_vehicle_created_at: item.Request.Vehicle.created_at,
          request_vehicle_type: item.Request.Vehicle.type,
          request_vehicle_plate: item.Request.Vehicle.plate,
          request_vehicle_year: item.Request.Vehicle.year,

          // user
          approver_name: item.User ? item.User.name + item.User.lastname : "",

        };
        arrayReady.push(objFormated)
      }
      
      // console.log('arrayReady', arrayReady)
      return arrayReady;
    },
  }
};

export default entryMixin;