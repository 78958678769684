<template>
  <div class=" third-company-item">
    <div class="data-item">
      <div class="row">
        <div class="col-md-9 data-middle">
          <div class="image-box" :style="'background-image: url(' + getImageUrl(thirdCompanyItem.logo) + ')'"
            v-bind:class="{ 'image-box-empty': !thirdCompanyItem.logo }"></div>
          <div class="middle-infos">
            <p class="highlight">Nome: {{ thirdCompanyItem.name }}</p>
            <p v-if="thirdCompanyItem.street">Endereço: {{ thirdCompanyItem.street }}<span
                v-if="thirdCompanyItem.number">,
                {{ thirdCompanyItem.number }}</span></p>
            <p v-else>Endereço: -</p>
            <p v-if="thirdCompanyItem.renter && thirdCompanyItem.renter.length > 0">Locatários: {{
              getRenters(thirdCompanyItem.renter) | verifyIfNull
            }}</p>
            <p v-else>Locatários: -</p>
          </div>
        </div>
        <div class="col-md-3 data-right" v-bind:class="{ 'data-right-bigger': showRemove || showAdd }">
          <div class="" v-bind:class="{ 'left-part ': showRemove || showAdd }">
            <p>Registro: {{ thirdCompanyItem.created_at | formatDate }}</p>
            <p>Status: <span class="highlight" v-bind:class="userStatusClass(thirdCompanyItem.status)">{{
              userStatus(thirdCompanyItem.status)
            }}</span></p>
            <p v-if="thirdCompanyItem.CompanyThird">Status vínculo: <span class="highlight"
                v-bind:class="userStatusClass(thirdCompanyItem.CompanyThird.status)">{{
                  userStatus(thirdCompanyItem.CompanyThird.status)
                }}</span></p>
          </div>

          <div class="data-right-btn data-right-btn-orange" v-if="showRemove" @click="removeThird($event)">
            <i class="fas fa-times"></i>
          </div>
          <div class="data-right-btn data-right-btn-blue" v-if="showAdd" @click="addThird($event)">
            <i class="fas fa-check"></i>
          </div>
        </div>
      </div>
      <overlay-item :route="'/third-companies/' + thirdCompanyItem.idcompany"
        :small="showRemove || showAdd"></overlay-item>
    </div>
    <div class="third-company-actions" v-if="showActions">
      <div class="action">
        <p class="highlight">Aprovação de cadastro</p>
        <toggle-button class="toggle-btn" :value="false" v-model="thirdCompanyItem.CompanyThird.auto_registration"
          :sync="true" @change="updateActions()" />
      </div>
      <div class="action">
        <p class="highlight">Aprovação de solicitação</p>
        <toggle-button class="toggle-btn" :value="false" v-model="thirdCompanyItem.CompanyThird.auto_request" :sync="true"
          @change="updateActions()" />
      </div>
    </div>

  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import companyMixin from "../mixins/company.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'

export default {
  mixins: [companyMixin, thirdCompanyMixin],
  props: ["thirdCompanyItem", "showActions", "showRemove", 'showAdd'],
  components: {
    OverlayItem,
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  mounted() {

  },
  watch: {},
  methods: {
    removeThird(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      this.deleteThirdFromCompanyMixin(id, this.thirdCompanyItem.idcompany).then((response) => {
        self.$emit("done");
        self.$toasted.show(response.message);
      });
    },
    addThird(event) {
      let self = this
      const id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      this.addThirdToCompanyMixin(id, this.thirdCompanyItem.idcompany).then((response) => {
        self.$emit("done");
        self.$toasted.show(response.message);
      });
    },
    updateActions() {
      let self = this
      const id = this.$route.params.id;
      this.actionsThirdCompanyMixin(id, this.thirdCompanyItem).then((response) => {
        self.$emit("done");
        self.$toasted.show(response.message);
      });
    },
    getRenters(renters) {
      const names = renters.map(renter => renter.name)
      return names.join(', ')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.third-company-item {
  position: relative;
  margin-bottom: 8px;

  .data-item {
    margin-bottom: 0px;
  }

  .third-company-actions {
    background-color: #EBEBEB;
    margin-top: -8px;
    margin-bottom: 15px;
    border-radius: 0px 0px 14px 14px;
    padding: 18px 30px;
    padding-top: 26px;
    display: flex;

    .action {
      flex: 1;
      text-align: end;

      p {
        display: inline-block;
        margin-right: 40%;
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  .third-company-item {

    .data-item .data-middle,
    .data-box .data-middle {
      padding: 12px;
      padding-bottom: 5px;
      height: auto;
      padding-right: 45px;
    }

    .data-item {
      // max-height: 145px;
    }

  }
}
</style>