<template>
  <main-layout>
    <div class="content-container">
      <div id="driver-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Solicitações</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-border" @click="exportRequestCSV">Download csv</button>
            <button class="app-btn app-btn-primary left-space" @click="goTo('/requests/new')" v-if="isThirdMember()">Novo
              registro</button>
            <download-csv class="app-btn app-btn-primary" :data="requestListCSV" :separator-excel="true"
              name="solicitações.csv" style="display: none" ref="downloadCSV">
              Download csv
            </download-csv>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input :model="filter.searchText" @updated="(x) => {
              filter.searchText = x;
            }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="applyFilter">
            </ok-input><button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.status" :options="statusOptions3" :reduce="(status) => status.value"
                placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-4">
            <date-picker v-model="filter.dateRange" value-type="YYYY-MM-DD" format="DD/MM/YYYY" type="date" range
              placeholder="Selecione o período" :lang="daysAndMonths" @input="applyFilter">
            </date-picker>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.order" :options="orderOptions2" placeholder="Mais recentes"
                :reduce="(order) => order.value" @input="applyFilter"></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idcompany" :options="companyList"
                :reduce="(company) => company.idcompany" @input="applyFilter"
                placeholder="Selecione a empresa"></v-select>
              <label>Empresa</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.vehicleType" :options="vehicleTypeList"
                :reduce="(vehicle) => vehicle.name" placeholder="Selecione o veículo" @input="applyFilter"></v-select>
              <label>Veículo</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{ filter.searchingText }}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="request in requestList" :key="request.idreport">
            <request-item :requestItem="request"></request-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="requestList && requestList.length == 0">
              <p>{{ noContentText }}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="requestList && requestList.length > 0 && filter.canLoadMore"
              @click="listRequests()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import requestMixin from "../mixins/request.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import vehicleMixin from "../mixins/vehicle.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import RequestItem from '@/components/RequestItem.vue'
import moment from 'moment'

export default {
  mixins: [authMixin, requestMixin, companyMixin, vehicleMixin],
  components: {
    MainLayout,
    OkInput,
    RequestItem,
  },
  data() {
    return {
      requestList: [],
      requestListCSV: [],
      companyList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        from: null,
        to: null,
        idcompany: null,
        vehicleType: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando solicitações...',
    };
  },
  mounted() {
    this.filter.status = '1';
    this.listRequests();
    this.listCompanies();
    this.vehicleTypeList.push({ name: 'PEDESTRE', value: 'PEDESTRE' },)
  },
  methods: {
    listRequests() {
      let self = this;
      let startDate = null;
      let endDate = null;
      let status = self.filter.status;
      if (status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      let vehicleType = self.filter.vehicleType
      sort = 'day'
      if (self.filter.dateRange) {
        startDate = self.filter.dateRange[0];
        endDate = self.filter.dateRange[1];
      } else {
        startDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
        endDate = moment().add(14, 'd').format('YYYY-MM-DD');
        self.filter.dateRange = [startDate, endDate];
      }
      this.noContentText = 'Buscando solicitações...';

      this.listRequestsMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, startDate, endDate, self.filter.idcompany, null, null, vehicleType).then((response) => {
        if (self.filter.page == 0) {
          self.requestList = response.requestList;
        } else {
          self.requestList = self.requestList.concat(response.requestList);
        }
        if (response.requestList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }

        //remover mascara
        for (let request of self.requestList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentText = 'Nenhuma solicitação para exibir';
      });
    },
    exportRequestCSV() {
      let self = this;
      this.$swal({
        title: 'Exportação csv',
        text: 'Qual o número limite de solicitações que você deseja?',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        confirmButtonText: 'Exportar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {

          let startDate = null;
          let endDate = null;
          let status = self.filter.status;
          if (status == '3') status = null;
          let sort = this.fixSort(self.filter.order);
          let order = this.fixOrder(self.filter.order);
          let vehicleType = self.filter.vehicleType
          if (self.filter.dateRange) {
            startDate = self.filter.dateRange[0];
            endDate = self.filter.dateRange[1];
          } else {
            startDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
            endDate = moment().add(14, 'd').format('YYYY-MM-DD');
            self.filter.dateRange = [startDate, endDate];
          }

          if (result.value < 1 || result.value == '' || !result.value || isNaN(result.value)) {
            self.$swal.fire({
              icon: 'warning',
              title: 'Atenção',
              text: 'Digite um número limite',
            })
            return
          }

          this.listRequestsMixin(result.value, 0, self.filter.searchText, order, sort, status, startDate, endDate, self.filter.idcompany, null, null, vehicleType).then((response) => {
            //remover mascara
            for (let request of response.requestList) {
              if (request.Driver.phone.length > 11) {
                let phone = request.Driver.phone.slice(4, 6);
                phone += request.Driver.phone.slice(7, 17);
                request.Driver.phone = phone;
              }
            }
            self.requestListCSV = self.prepareCSVRequests(response.requestList)
            // console.log('aqui requestListCSV', self.requestListCSV)
            setTimeout(() => { this.$refs.downloadCSV.$el.click(); }, 1000);
          });
        }
      })
    },
    listCompanies() {
      let self = this;
      this.listCompaniesMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.companyList = response.companyList;

      });
    },
    applyFilter() {
      this.filter.page = 0;
      this.listRequests()
    },
    removeSearchText() {
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#request-list {}
</style>