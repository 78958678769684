<template>
  <main-layout>
    <div id="app-driver-register">
      <div class="row">
        <div class="col-md-4 left-part">
          <img
            src="/static/img/glp-logomark@2x.png"
            alt="GLP logo"
            class="logo center"
          />
          <h2>Cadastro de motorista</h2>
          <p class="description"></p>

          <ValidationObserver ref="validator" class="col-md-9">
            <ok-input
              :model="driver.name"
              @updated="
                (x) => {
                  driver.name = x
                }
              "
              :label="'Nome'"
              :placeholder="' '"
              :id="'name'"
              :name="'name'"
              rules="required"
            >
            </ok-input>
            <ok-input
              :model="driver.lastname"
              @updated="
                (x) => {
                  driver.lastname = x
                }
              "
              :label="'Sobrenome'"
              :placeholder="' '"
              :id="'lastname'"
              :name="'lastname'"
              rules="required"
            >
            </ok-input>
            <ok-input
              :model="driver.cpf"
              @updated="
                (x) => {
                  driver.cpf = x
                }
              "
              :label="'CPF'"
              :mask="'###.###.###-##'"
              :placeholder="' '"
              :id="'cpf'"
              :name="'cpf'"
              rules="required"
            >
            </ok-input>
            <ok-input
              :model="driver.birthday"
              @updated="
                (x) => {
                  driver.birthday = x
                }
              "
              :label="'Nascimento'"
              :placeholder="' '"
              :id="'birthday'"
              :name="'birthday'"
              :isDate="true"
              rules="required"
              :mask="'##/##/####'"
            >
            </ok-input>
            <ok-input
              :model="driver.gender"
              @updated="
                (x) => {
                  driver.gender = x
                }
              "
              :label="'Sexo'"
              :options="genderOptions"
              :placeholder="' '"
              :id="'gender'"
              :name="'gender'"
              style="margin-top: 4px"
            >
            </ok-input>
            <ok-input
              :model="driver.email"
              @updated="
                (x) => {
                  driver.email = x
                }
              "
              :label="'E-mail'"
              :placeholder="' '"
              :id="'email'"
              :name="'email'"
              rules="required|email"
            >
            </ok-input>
            <ok-input
              :model="driver.phone"
              @updated="
                (x) => {
                  driver.phone = x
                }
              "
              :label="'Telefone'"
              :mask="'(##) #####-####'"
              :placeholder="' '"
              :id="'phone'"
              :name="'phone'"
              :disabled="disablePhone"
              rules="required"
            >
            </ok-input>

            <hr />

            <ok-input
              :model="driver.company_cnpj"
              @updated="
                (x) => {
                  driver.company_cnpj = x
                }
              "
              :label="'CNPJ'"
              :mask="'##.###.###/####-##'"
              :placeholder="' '"
              :id="'company_cnpj'"
              :name="'company_cnpj'"
            >
            </ok-input>
            <ok-input
              :model="driver.company_name"
              @updated="
                (x) => {
                  driver.company_name = x
                }
              "
              :label="'Nome'"
              :placeholder="' '"
              :id="'company_name'"
              :name="'company_name'"
            >
            </ok-input>

            <hr />

            <div class="images">
              <div
                class="image-item app-btn app-btn-outline"
                @click="openCamera('photo')"
              >
                <img
                  :src="driver.image_photo"
                  alt="driver image"
                  v-if="driver.image_photo"
                />
                <div class="image-empty" v-else></div>
                <span>Cadastrar foto</span>
                <i class="fas fa-chevron-right chevron-right"></i>
              </div>

              <div
                class="image-item app-btn app-btn-outline"
                @click="openCamera('document')"
              >
                <img
                  :src="driver.image_document"
                  alt="driver image"
                  v-if="driver.image_document"
                />
                <div class="image-empty" v-else></div>
                <span>Cadastrar foto da CNH</span>
                <i class="fas fa-chevron-right chevron-right"></i>
              </div>
            </div>

            <ok-input
              :model="driver.cnh"
              @updated="
                (x) => {
                  driver.cnh = x
                }
              "
              :label="'CNH'"
              :mask="'###########'"
              :placeholder="' '"
              :id="'cnh'"
              :name="'cnh'"
            >
            </ok-input>
          </ValidationObserver>

          <button
            class="app-btn app-btn-outline register-btn"
            id="back-btn"
            @click="goTo('/visitante/login')"
          >
            Voltar
          </button>

          <button
            class="app-btn app-btn-primary register-btn"
            id="register-btn"
            @click="registerDriver()"
          >
            Cadastrar
          </button>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/glp-main.png" alt="GLP img"> -->
          <div
            class="image-box"
            style="background-image: url('./static/img/glp-main.png')"
          ></div>
        </div>
      </div>

      <div v-if="showCamera" class="camera-modal">
        <camera-capture
          @image-captured="onImageCaptured"
          @camera-error="onCameraError"
        />
        <button @click="closeCamera" class="close-btn">Fechar</button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import driverMixin from '../../mixins/driver.mixin.js'
import utilsMixin from '../../mixins/utils.mixin.js'
import MainLayout from '../../layouts/Main.vue'
import OkInput from '@/components/OkInput.vue'
import CameraCapture from '@/components/CameraCapture.vue'
import { getLocalStorage } from '../../js/encryption.js'

export default {
  mixins: [driverMixin, utilsMixin],
  components: {
    MainLayout,
    OkInput,
    CameraCapture,
  },
  data() {
    return {
      driver: {
        status: 2,
        company_cnpj: '',
        company_name: '',
        cnh: '',
        email: '',
        gender: '',
        lastname: '',
        name: '',
        phone: '',
        cpf: '',
        birthday: '',
      },
      disablePhone: false,
      currentCaptureType: null,
      showCamera: false,
    }
  },
  mounted() {
    this.driver.phone = getLocalStorage('driverPhone')
    if (this.driver.phone) this.disablePhone = true
    localStorage.removeItem('driverPhone')
  },
  methods: {
    registerDriver() {
      this.appCreateDriverMixin(this.driver).then((response) => {
        if (response) {
          this.$router.push('/visitante/home')
        }
      })
    },
    openCamera(type) {
      this.currentCaptureType = type
      this.showCamera = true
    },
    closeCamera() {
      this.showCamera = false
      this.currentCaptureType = null
    },
    async onImageCaptured(imageData) {
      try {
        if (this.currentCaptureType === 'photo') {
          const response = await this.uploadImage(imageData)
          this.driver.image_photo = response.file.filename
        } else if (this.currentCaptureType === 'document') {
          const response = await this.uploadImage(imageData)
          this.driver.image_document = response.file.filename
        }
        this.closeCamera()
      } catch (error) {
        console.error('Error uploading image:', error)
        this.$toasted.error('Não foi possível salvar a imagem, tente novamente')
      }
    },
    onCameraError(error) {
      console.error('Camera error:', error)
      alert(
        'Não foi possível acessar a câmera. Por favor, verifique as permissões e tente novamente.'
      )
      this.closeCamera()
    },
  },
}
</script>

<style scooped lang="scss">
@import '../../scss/colors.scss';

#app-driver-register {
  .left-part {
    background-color: $lightgray;
    padding: 90px 70px;
    height: 100vh;
    overflow-y: auto;

    .logo {
      height: 45px;
      margin-bottom: 90px;
      object-fit: contain;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .description {
      margin-bottom: 35px;
      font-weight: 500;
      color: $gray-xdk;
      text-align: center;
    }

    .images {
      display: grid;
      gap: 12px;
      margin-bottom: 12px;

      .image-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 14px;
        height: 70px;
        background-color: $white;
        padding: 8px 12px;

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 1;
          border-radius: 5px;
        }

        .image-empty {
          height: 100%;
          aspect-ratio: 1;
          border-radius: 5px;
          background-color: $gray-dk;
        }
      }
    }

    .register-btn {
      width: 100%;
      margin-top: 15px;
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  .camera-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .close-btn {
      margin-top: 10px;
      padding: 10px 20px;
      font-size: 16px;
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  #app-driver-register {
    .left-part {
      padding: 90px 40px;
    }
  }
}
</style>
