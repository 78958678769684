<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: ["data"],
  data() {
    return {
        chartdata: {
                labels: ['January', 'February'],
                datasets: [
                    {
                    label: 'Data One',
                    backgroundColor: ["#41B883", "#E46651"],
                    data: [40, 20]
                    }
                ]
            },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    data() {
      this.renderChart(this.data, this.options);
    },
  },
};
</script>

<style>
</style>