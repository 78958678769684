<template>
  <main-layout>
    <div class="content-container">
      <div id="company-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Locatários</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="goTo('/companies/new')" v-if="isGlpMember()">Novo
              registro</button>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input :model="filter.searchText" @updated="(x) => {
              filter.searchText = x;
            }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="applyFilter">
            </ok-input>
            <button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-4">
            <div class="select-with-label"
              v-bind:style="isThirdListing ? 'pointer-events: none; opacity: 0.5;' : 'pointer-events: auto'">
              <v-select label="name" v-model="filter.status" :options="statusOptions2" :reduce="(status) => status.value"
                placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idpark" :options="parkList" :reduce="(park) => park.idpark"
                @input="applyFilter" placeholder="Selecione o parque"></v-select>
              <label>Parque</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.order" :options="orderOptions" :reduce="(order) => order.value"
                placeholder="Mais recentes" @input="applyFilter"></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{ filter.searchingText }}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-if="newRequestsList.length > 0">
            <div class="new-requests-title">Novas solicitações</div>
            <company-item v-for="company in newRequestsList" :key="company.idcompany" :companyItem="company"
              :isThirdCompany="isThirdListing"></company-item>
            <hr class="new-requests-separator">
          </div>
          <div class="col-md-12" v-for="company in companyList" :key="company.idcompany">
            <company-item :companyItem="company" :isThirdCompany="isThirdListing"></company-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="companyList && companyList.length == 0">
              <p>{{ noContentText }}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="companyList && companyList.length > 0 && filter.canLoadMore"
              @click="checkWhichList()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import CompanyItem from '@/components/CompanyItem.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin, companyMixin, parkMixin, thirdCompanyMixin],
  components: {
    MainLayout,
    OkInput,
    CompanyItem,
  },
  data() {
    return {
      companyList: [],
      newRequestsList: [],
      parkList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        idpark: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando empresas...',
      currentUser: null,
      isThirdListing: false,
    };
  },
  mounted() {
    this.currentUser = getLocalStorage('user')
    if (this.currentUser.job === '40' || this.currentUser.job === '41') {
      this.isThirdListing = true
    }
    this.checkWhichList()
    this.listParks()
  },
  methods: {
    checkWhichList() {
      if (this.isThirdListing) {
        this.listCompaniesThird()
        this.listCompaniesThirdPending()
      }
      else this.listCompanies()
    },
    listCompanies() {
      let self = this;
      let status = self.filter.status;
      if (status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando empresas...';

      this.listCompaniesMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, self.filter.idpark).then((response) => {
        if (self.filter.page == 0) {
          self.companyList = response.companyList;
        } else {
          self.companyList = self.companyList.concat(response.companyList);
        }
        if (response.companyList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }
        self.noContentText = 'Nenhuma empresa para exibir';
      });
    },
    listCompaniesThird() {
      let self = this;
      // let status = self.filter.status;
      // if (status == '3') status = null;
      // const statusToSearch = listStatus ? listStatus : status
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando empresas...';

      this.listCompaniesFromThirdMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, 2, self.currentUser.Company.idcompany).then((response) => {
        console.log(response)
        if (self.filter.page == 0) {
          self.companyList = response.third.companyList;
        } else {
          self.companyList = self.companyList.concat(response.third.companyList);
        }
        if (response.third.companyList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }
        self.noContentText = 'Nenhuma empresa para exibir';
      });
    },
    listCompaniesThirdPending() {
      let self = this;
      this.listCompaniesFromThirdMixin(500, null, null, null, null, 1, self.currentUser.Company.idcompany).then((response) => {
        self.newRequestsList = response.third.companyList;
      });
    },
    applyFilter() {
      this.filter.page = 0;
      this.checkWhichList()
    },
    removeSearchText() {
      this.removeSearchTextMixin();
      this.applyFilter();
    },
    listParks() {
      let self = this;
      this.listParksMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.parkList = response.parkList;
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#company-list {

  .new-requests-title {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
  }

  .new-requests-separator {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>