<template>
  <div class="content container-fluid" style="padding: 0px">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {},
}
</script>

<style lang="scss">
@import '../scss/reset.scss';
@import '../scss/colors.scss';
@import '../scss/base.scss';
@import '../scss/form.scss';
@import '../scss/fonts.scss';

main {
  display: flex;
  height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100vw;
}
</style>
