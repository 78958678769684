import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var thirdCompanyMixin = {
  data: function () {
    return {
      thirdCompany: {
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
      },
    }
  },
  methods: {
    listThirdCompaniesMixin(limit, page, search, order, sort, status, idpark) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        idpark: idpark,
      };

      return axios.get(server.api_url + '/third', config)
        .then((response) => {
          return response.data.data;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getThirdCompanyMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/third/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.third.status = self.fixStatus(responseData.third.status);
          if (responseData.third.range) {
            responseData.third.range = responseData.third.range.toString();
          }
          if (responseData.third.window_range) responseData.third.window_range = responseData.third.window_range.toString();
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveThirdCompanyMixin(company, param, photo) {
      const self = this;
      if (photo == true && param === 'new') return
      let companyToSend = JSON.parse(JSON.stringify(company));
      if (companyToSend.zipcode) companyToSend.zipcode = self.removeMaskZipcode(companyToSend.zipcode);
      if (companyToSend.cnpj) companyToSend.cnpj = self.removeMaskCNPJ(companyToSend.cnpj);
      if (companyToSend.phone) companyToSend.phone = self.removeMaskPhone(companyToSend.phone);
      // if (!companyToSend.window_range) companyToSend.window_range = 0;
      companyToSend.status = self.fixStatus(companyToSend.status);
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          companyToSend.type = 1
          this.axios.post(server.api_url + '/third/', {
            company: companyToSend,
          }).then((response) => {
            let responseData = response.data.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/third-companies/' + responseData.company.idcompany, '_self');
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          this.axios.put(server.api_url + '/third/' + companyToSend.idcompany, {
            company: companyToSend,
          }).then((response) => {
            if (photo == '' || photo == null) {
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: response.data.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              })
            }
          })
            .catch(function (error) {
              self.showError(error);
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteThirdCompanyMixin(id) {
      const self = this;
      this.$swal.fire({
        title: 'Realmente deseja excluir este locatário?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/third/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/third-companies/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },
    listCompaniesFromThirdMixin(limit, page, search, order, sort, status, idThird) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
      };

      return axios.get(server.api_url + '/third/' + idThird + '/company', config)
        .then((response) => {
          return response.data.data;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    thirdCompaniesListFiltered(alreadyAddedList, all) {
      var listFiltered = [];
      var list = [];
      // console.log('thirdCompaniesListFiltered', alreadyAddedList, all)
      for (let item of all) {

        if (!alreadyAddedList.some(park => park.idcompany === item.idcompany)) {
          item.name.toLowerCase();
          list.push(item);
        }
      }
      if (this.filter.searchText == null) return list;

      for (let item of list) {
        console.log(!alreadyAddedList.some(park => park.idcompany === item.idcompany))
        let name = item.name.toLowerCase();
        let searchText = this.filter.searchText.toLowerCase();
        if (name.indexOf(searchText) > -1) {
          listFiltered.push(item);
        }
      }
      return listFiltered;
    },
    addThirdToCompanyMixin(idcompany, idThird) {
      const self = this;
      return axios.post(server.url + '/companies/' + idcompany + '/third/' + idThird)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    deleteThirdFromCompanyMixin(idcompany, idThird) {
      const self = this;
      return this.axios.delete(server.url + '/companies/' + idcompany + '/third/' + idThird)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    deleteCompanyFromThirdCompanyMixin(idcompany, idThird) {
      const self = this;
      return this.axios.delete(server.url + '/third/' + idThird + '/company/' + idcompany)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    approveDenyThirdCompanyMixin(idcompany, idThird, obj) {
      const self = this;
      return axios.put(server.url + '/companies/' + idcompany + '/third/' + idThird, obj)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    getCompanyThirdCompanyMixin(idcompany, idThird) {
      const self = this;
      return axios.get(server.url + '/third/' + idThird + '/company/' + idcompany)
        .then(function (response) {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    listDriverThirdCompanyMixin(idThird) {
      const self = this;
      return axios.get(server.url + '/third/' + idThird + '/driver')
        .then(function (response) {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    addDriverToThirdCompanyMixin(idThird, idDriver) {
      const self = this;
      return axios.post(server.url + '/third/' + idThird + '/driver/' + idDriver)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    approveDriverToThirdCompanyMixin(idThird, idDriver) {
      const self = this;
      return axios.put(server.url + '/third/' + idThird + '/driver/' + idDriver, { status: 2 })
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    deleteDriverFromThirdCompanyMixin(idDriver) {
      const self = this;
      return this.axios.delete(server.url + '/third/driver/' + idDriver)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    saveRequestThirdCompanyMixin(request) {
      const self = this
      let requestToSend = JSON.parse(JSON.stringify(request));
      this.axios.post(server.api_url + '/third/request', {
        request: requestToSend,
      }).then((response) => {
        let responseData = response.data.data;
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: response.data.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open('/requests/' + responseData.request.idrequest, '_self');
          }
        })
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    actionsThirdCompanyMixin(idcompany, thirdCompany) {
      const self = this;
      const obj = {
        status: thirdCompany.CompanyThird.status,
        auto_registration: self.fixStatus(thirdCompany.CompanyThird.auto_registration),
        auto_request: self.fixStatus(thirdCompany.CompanyThird.auto_request),
      }
      return axios.put(server.url + '/companies/' + idcompany + '/third/' + thirdCompany.idcompany, obj)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    getThirdCompaniesForOkInput(company) {
      let array = [];
      for (let c of company) {
        let obj = {
          name: c.name,
          value: c.idcompany
        }
        array.push(obj);
      }
      return array
    },
  }
};

export default thirdCompanyMixin;