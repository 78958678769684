import { render, staticRenderFns } from "./VehicleItem.vue?vue&type=template&id=34e3189b"
import script from "./VehicleItem.vue?vue&type=script&lang=js"
export * from "./VehicleItem.vue?vue&type=script&lang=js"
import style0 from "./VehicleItem.vue?vue&type=style&index=0&id=34e3189b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports