import Vue from 'vue'

Vue.filter("formatPhone", function (value) {
  let text = new String(value);
  if(text == '' || text == 'null' || text.length < 10){
    return '-'
  }
  let primeiraParte = text.slice(0, 2);
  let segundaParte = text.slice(2, 7);
  // let terceiraParte = text.slice(7, 11);
  let terceiraParte = text.slice(-4);

  return '('
    .concat(primeiraParte)
    .concat(") ")
    .concat(segundaParte)
    .concat("-")
    .concat(terceiraParte)
});