<template>
  <div class="image-upload">
    <file-upload v-if="!image" :url="url" :thumb-url="thumbUrl" :headers="headers" :unique-id="getRandomArbitrary()"
      name="image" class="thumb" v-model="files" accept="image/png, image/gif, image/jpeg, image/webp"
      @change="onFileChange" ref="upload"></file-upload>

    <div v-else class="thumb" :style="{
      backgroundImage: `url('${imageUrl}')`,
    }">
      <button class="clean" @click="removeMediaImage(image)">
        <i class="fas fa-times-circle close-button"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { server } from "../js/variables.js";
import { getLocalStorage } from "../js/encryption";

export default {
  props: {
    image: {
      required: true,
    },
  },
  model: {
    prop: "image",
    event: "imagechange",
  },
  data() {
    return {
      url: server.url + "/media/image/",
      headers: {
        Authorization: 'Bearer ' + getLocalStorage('token')
      },
      files: [],
      filesUploaded: [],
      fileUploaded: null,
      imageUrl: ''
    };
  },
  watch: {
    image() {
      this.imageUrl = this.image
    },
  },
  computed: {
    imageLocal: {
      get: function () {
        return this.liimagest;
      },
      set: function (value) {
        this.$emit("imagechange", value);
      },
    },
  },
  mounted() {
    this.headers = server.config.headers;
    this.url = server.url + "/api/media/image/";
    if (!this.headers.Authorization && localStorage.token) {
      this.headers = {
        Authorization: 'Bearer ' + getLocalStorage('token')
      }
    }
    this.imageUrl = this.image
  },
  updated() {
    this.url = server.url + "/media/image/";
  },
  methods: {
    // checkImage() {
    //   const self = this
    //   if (!this.image) {
    //     this.imageUrl = null
    //     return
    //   }
    //   this.axios.get(this.getImageUrl(this.image))
    //     .then(function () {

    //       self.imageUrl = self.getImageUrl(self.image)
    //     })
    //     .catch(function () {
    //       self.imageUrl = self.getMediaUrl(self.image)
    //     });
    // },
    getRandomArbitrary() {
      return "-" + Math.random() * (100 - 0) + 0;
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      // console.log('upload', response.data);
      // console.log('file', response.data.file);
      this.imageLocal = response.data.file.filename;
      // this.image = response.data.file.filename;
      // this.fileUploaded = this.image;
      this.$forceUpdate();
      this.$emit("uploaded");
    },
    getImageUrl(image) {
      return image;
    },
    getImageThumbUrl(image) {
      return image;
    },
    removeMediaImage(image) {
      var self = this;
      if (!image) return;
      // let file = {
      //   name: image,
      // }
      // console.log('file remove', file)
      const imageSplit = image.split('/')
      this.axios.delete(server.url + '/media/image/' + imageSplit[imageSplit.length - 1]).then((response) => {
        console.log(response);
        // self.driver.image = null;
        // console.log('self.image', self.image)
        this.imageLocal = null;
        // self.imageLocal = null;
        // self.image = null;
        // self.fileUploaded = null;
        // console.log('self.depois', self.image)
        // self.updateItem();
        self.$emit("deleted");
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: 'Imagem removida',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // self.$emit("deleted");
          }
        })
      })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss" >
.file-upload {
  .input-wrapper {
    height: 100% !important;
    background-color: #efeaea !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #a3aab4 !important;
    }

    .file-upload-label {
      .file-upload-icon {
        align-self: center;
        color: #555;
        display: initial !important;
      }

      div {
        font-size: 10pt;
        margin-top: 20px;
        color: black;
      }
    }
  }
}

.image-upload {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;

  .thumb {
    height: 100%;
    padding-top: 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .close-button {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
  }

  .file-upload-label {
    height: 100%;
    padding: initial !important;
    padding-top: 37% !important;
  }
}

@media only screen and (max-width: 600px) {
  .image-upload {
    margin-bottom: 15px;

    .thumb {
      height: 305px;
      padding-top: 0px;
    }
  }
}
</style>