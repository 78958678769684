<template>
  <main-layout>
    <div class="content-container" >
      <div id="user-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Pessoas</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="goTo('/users/new')" v-if="isSecurityAdm()">Novo registro</button>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input
              :model="filter.searchText"
              @updated="
                (x) => {
                  filter.searchText = x;
                }
              "
              :label="'Pesquisar'"
              :placeholder="' '"
              :id="'searchText'"
              :name="'searchText'"
              @onEnter="applyFilter"
            >
            </ok-input>
            <button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.status"
                :options="statusOptions2"
                :reduce="(status) => status.value "
                placeholder="Todos"
                @input="applyFilter"
              ></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.jobs"
                :options="jobOptions"
                :reduce="(job) => job.value "
                @input="applyFilter"
                placeholder="Selecione o cargo"
              ></v-select>
              <label>Cargo</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.order"
                :options="orderOptions"
                :reduce="(order) => order.value "
                placeholder="Mais recentes"
                @input="applyFilter"
              ></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{filter.searchingText}}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="user in userList" :key="user.iduser">
            <user-item :userItem="user"></user-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="userList && userList.length == 0">
              <p>{{noContentText}}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="userList && userList.length > 0 && filter.canLoadMore" @click="listUsers()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import userMixin from "../mixins/user.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import UserItem from '@/components/UserItem.vue'

export default {
  mixins: [authMixin, userMixin],
  components: {
    MainLayout,
    OkInput,
    UserItem,
  },
  data() {
    return {
      userList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        jobs: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando pessoas...',
    };
  },
  mounted() {
    this.listUsers();
  },
  methods: {
    listUsers(){
      let self = this;
      let status = self.filter.status;
      if(status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando pessoas...';
      this.listUsersMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, self.filter.jobs).then((response) => {
        if(self.filter.page == 0){
          self.userList = response.userList;
        }else{
          self.userList = self.userList.concat(response.userList);
        }
        if(response.userList.length != self.filter.pageItems){
          self.filter.canLoadMore = false;
        }else{
          self.filter.canLoadMore = true;
        }
        self.filter.page ++
        
        if(self.filter.searchText){
          self.filter.searchingText = self.filter.searchText;
        }else{
          self.filter.searchingText = null;
        }
    
        self.noContentText = 'Nenhum usuário para exibir';
      });
    },
    applyFilter(){
      this.filter.page = 0;
      this.listUsers()
    },
    removeSearchText(){
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";
#user-list{
  

}
</style>