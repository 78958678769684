var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"content-box",attrs:{"id":"request-register"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 separator-bottom"},[_c('h1',{staticClass:"title-md"},[_vm._v("Solicitação")])]),_c('div',{staticClass:"col-md-8 last"},[_c('button',{staticClass:"app-btn app-btn-outline separator-bottom",on:{"click":function($event){return _vm.goBackWindow()}}},[_vm._v("Voltar")]),(this.$route.fullPath.indexOf('/requests/new') === -1 && _vm.isThirdMember())?_c('button',{staticClass:"app-btn app-btn-outline left-space",on:{"click":function($event){return _vm.saveRequest()}}},[_vm._v("Salvar")]):_vm._e(),(this.$route.fullPath.indexOf('/requests/new') !== -1 && _vm.isThirdMember())?_c('button',{staticClass:"app-btn app-btn-outline left-space",on:{"click":function($event){return _vm.saveRequest()}}},[_vm._v("Registrar")]):_vm._e(),(this.$route.fullPath.indexOf('/requests/new') === -1 && (_vm.isThirdMember() || _vm.isCompanyMember()))?_c('button',{staticClass:"app-btn app-btn-cancel left-space action",on:{"click":function($event){return _vm.denyAll()}}},[_vm._v("Reprovar todos")]):_vm._e(),(this.$route.fullPath.indexOf('/requests/new') === -1 && (_vm.isThirdMember() || _vm.isCompanyMember()))?_c('button',{staticClass:"app-btn app-btn-primary left-space action",on:{"click":function($event){return _vm.approveAll()}}},[_vm._v("Aprovar todos")]):_vm._e()])]),_c('div',{staticClass:"row"},[(_vm.requestId !== 'new')?_c('div',{staticClass:"col-md-4"},[_c('ok-input',{attrs:{"model":_vm.window_range,"label":'Janela de tolerância (h)',"placeholder":' ',"id":'window_range',"name":'window_range',"rules":'integer'},on:{"updated":(x) => {
            _vm.window_range = x;
          }}})],1):_vm._e(),(_vm.requestId !== 'new')?_c('div',{staticClass:"col-md-8"}):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.idthird,"label":'Empresa Terceira',"options":_vm.thirdCompanyList,"placeholder":'Selecione o terceiro',"id":'request-third',"name":'idthird',"disabled":_vm.thirdCompanyList.length == 0 || _vm.isThird || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.idthird = x;
          },"selected":function($event){return _vm.selectedThird()}}})],1),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"select-with-label"},[_c('v-select',{attrs:{"label":"name","options":_vm.driverList,"reduce":(driver) => driver.value,"placeholder":"Digite o nome do motorista"},on:{"search":_vm.searchDriver},model:{value:(_vm.request.iddriver),callback:function ($$v) {_vm.$set(_vm.request, "iddriver", $$v)},expression:"request.iddriver"}}),_c('label',[_vm._v("Motorista")])],1)]),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.idpark,"label":'Parque',"options":_vm.parkList,"placeholder":'Selecione o parque',"id":'request-park',"name":'idpark',"disabled":_vm.parkList.length == 0 || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.idpark = x;
          },"selected":function($event){return _vm.loadCompanyList()}}})],1),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.idcompany,"label":'Locatário',"options":_vm.companyList,"placeholder":'Selecione a empresa',"id":'request-company',"name":'idcompany',"disabled":_vm.companyList.length == 0 || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.idcompany = x;
          }}})],1),_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.idvehicle,"label":'Veículo',"options":_vm.vehicleList,"placeholder":'Selecione o veículo',"id":'request-vehicle',"name":'idvehicle',"disabled":_vm.vehicleList.length == 0 || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.idvehicle = x;
          },"selected":function($event){return _vm.checkPlates()}}})],1),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.plate2,"label":'Placa 2',"placeholder":' ',"id":'plate2',"name":'plate2',"disabled":_vm.isPlate2Disabled || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.plate2 = x;
          }}})],1),_c('div',{staticClass:"col-md-4"},[_c('ok-input',{staticStyle:{"margin-top":"4px"},attrs:{"model":_vm.request.plate3,"label":'Placa 3',"placeholder":' ',"id":'plate3',"name":'plate3',"disabled":_vm.isPlate3Disabled || _vm.isFieldsDisabled},on:{"updated":(x) => {
            _vm.request.plate3 = x;
          }}})],1)]),_c('hr',{staticClass:"separator"}),_c('div',{staticClass:"row",staticStyle:{"padding":"10px 0px"}},[_c('div',{staticClass:"col-md-3"},[_c('date-picker',{attrs:{"value-type":"YYYY-MM-DD","format":"DD/MM/YYYY","type":"date","inline":true,"placeholder":"Selecione a data","lang":_vm.daysAndMonths,"multiple":true,"disabled-date":_vm.disabledBeforeToday},on:{"input":_vm.handleDateSelection},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1),_c('div',{staticClass:"col-md-9"},[_c('h2',{staticClass:"title-sm",staticStyle:{"margin-bottom":"15px"}},[_vm._v("Horário")]),_vm._l((_vm.selectedDatesAndHours),function(date,index){return _c('div',{key:date.day,staticStyle:{"display":"flex","gap":"12px","margin-bottom":"10px"}},[_c('ok-input',{staticStyle:{"margin-top":"4px","margin-bottom":"0px"},attrs:{"model":date.day,"label":'Data',"placeholder":' ',"id":'day',"name":'day',"disabled":true},on:{"updated":(x) => {
              date.day = x;
            }}}),_c('ok-input',{staticStyle:{"margin-top":"4px","margin-bottom":"0px"},attrs:{"model":date.hour,"label":'Horário',"placeholder":' ',"id":'hour',"name":'hour',"disabled":date.idrequest !== undefined,"mask":'##:##',"rules":"required|hourFormat"},on:{"updated":(x) => {
              date.hour = x;
            }}}),(!date.idrequest)?_c('button',{staticClass:"clean remove-date",staticStyle:{"font-size":"20px"},on:{"click":function($event){return _vm.removeDate(index)}}},[_c('i',{staticClass:"fas fa-times"})]):_c('button',{staticClass:"clean thumbs",class:{ 'thumbs-approved': date.status === 2, 'thumbs-denied': date.status === -1 },staticStyle:{"font-size":"20px"},attrs:{"disabled":date.status === 0},on:{"click":function($event){return _vm.goToRequest(date.idrequest)}}},[_c('i',{staticClass:"fa-regular fa-thumbs-up"})])],1)}),(_vm.request && _vm.selectedDatesAndHours.length === 0)?_c('div',{staticStyle:{"margin-top":"15px"}},[_vm._v(" Selecione uma data no calendário ")]):_vm._e()],2)]),_c('hr',{staticClass:"separator"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",staticStyle:{"margin-top":"12px","margin-bottom":"18px"}},[_c('h2',{staticClass:"title-sm"},[_vm._v("Acompanhantes")])]),(_vm.requestId === 'new')?_c('div',{staticClass:"col-md-8",staticStyle:{"margin-bottom":"12px"}},[_c('div',{staticClass:"select-with-label"},[_c('v-select',{class:{ 'disable-select ': _vm.companionListMapped.length == 0 },attrs:{"label":"name","options":_vm.companionListMapped,"reduce":(order) => order.value,"placeholder":"Nome do acompanhante"},on:{"input":_vm.addSelectedCompanion},model:{value:(_vm.selectedCompanion),callback:function ($$v) {_vm.selectedCompanion=$$v},expression:"selectedCompanion"}}),_c('label',[_vm._v("Acompanhante")])],1)]):_vm._e(),_vm._l((_vm.request.Companions),function(companion){return _c('div',{key:companion.idcompanion,staticClass:"col-md-12"},[_c('companion-item',{attrs:{"companion":companion,"showRemove":true},on:{"companionRemove":_vm.removeCompanion}})],1)}),_c('div',{staticClass:"col-md-12"},[(_vm.request.Companions && _vm.request.Companions == 0)?_c('div',{staticClass:"no-content"},[_c('p',[_vm._v("Nenhum acompanhante adicionado")])]):_vm._e()])],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }