import Vue from 'vue'


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCnM-_iYCzoNk4gRrdOB00pJVdcem5ntwA',
      libraries: 'places',
    },
    installComponents: true
  });