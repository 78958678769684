import Vue from 'vue'

Vue.filter('calculateAge', function(value) {
    if (value) {
      // return moment(String(value)).format('MM/DD/YYYY hh:mm')
  
      let currentDate = new Date();
      let birthDate = new Date(value);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference/31557600000);
      return age
    }
  });