<template>
  <div>
    <footer>
      <div id="rights" class="">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="center"> -->
              <p class="center" style="margin-top: 13px; text-align: center;">© Copyright 2020 - All Rights Reserved.</p>
              <a href="https://oceantek.com.br/" alt="Desenvolvimento de apps, e-commerce e market places" target="_blank">
                <img class="logo" src="/static/logo_oceantek_grey.svg" alt />
              </a>
              <!-- <p>v 2.0.7</p> -->
            </div>
          <!-- </div> -->
          <!-- <div class="offset-md-2 col-md-5">
            <a href="https://oceantek.com.br/" alt="Desenvolvimento de apps, e-commerce e market places" target="_blank">
            <img class="logo-right" src="/static/logo_oceantek_grey.svg" alt />
            </a>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";
/* footer */

footer {
  height: auto;
  width: 100vw;
  background-color: $lightgray;

  #rights {
    color: #0a0f2d;
    font-size: 13px;
    padding: 35px 10px;

    .logo-right{
      // float: right;
      // float: left;
    }

    p{
      font-size: 13px;
    }

    a{
      position: absolute;
      bottom: 20px;
      left: 42px;
    }
  }

}



@media only screen and (max-width: 600px) {
  footer{

    #rights {
      padding-top: 0px;

      a{
        position: initial;

        .logo{
          margin-top: 25px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 40%;
        }
      }

      p{
        text-align: center;
      }
    }
  }
  
}
</style>