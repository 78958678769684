<template>
  <main-layout>
    <div class="content-container">
      <div id="dashboard" class="content-box">
        <div class="row">
          <div class="col-md-12">
            <h1 class="title-md">Dashboard</h1>
          </div>

          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idpark" :options="parkList" :reduce="(park) => park.idpark"
                @input="getStats(true)" placeholder="Selecione o parque"></v-select>
              <label>Parque</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label"
              v-bind:style="filter.idpark == null || filter.idpark == 999999999 ? 'pointer-events: none; opacity: 0.5;' : 'pointer-events: auto'">
              <v-select label="name" v-model="filter.idcompany" :options="companyList"
                :reduce="(company) => company.idcompany" @input="getStats()" placeholder="Selecione a empresa"></v-select>
              <label>Empresa</label>
            </div>
          </div>
          <div class="col-md-4">
            <date-picker v-model="filter.dateRange" value-type="YYYY-MM-DD" format="DD/MM/YYYY" type="date" range
              placeholder="Selecione o período" :lang="daysAndMonths" @input="getStats()"></date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class>
              <h2>Visão geral</h2>
              <chart-bar :data="analitics.chartBar"></chart-bar>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="info-card">
              <h3>Motoristas</h3>
              <br />
              <p>{{ analitics.drivers }}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="info-card">
              <h3>Solicitações</h3>
              <br />
              <p>{{ analitics.requests }}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="info-card">
              <h3>Acessos</h3>
              <br />
              <p>{{ analitics.entries }}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="info-card">
              <h3>Tempo médio no parque (HH:MM)</h3>
              <br />
              <p>{{ analitics.avgTime }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h2>Notificações por tipo</h2>
            <chart-pie :data="analitics.chartReport"></chart-pie>
          </div>

          <div class="col-md-6">
            <h2>Registros por Horário</h2>
            <chart-line :data="analitics.chartHourLine"></chart-line>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class>
              <h2>Aprovação por período de tempo</h2>
              <chart-bar :data="analitics.chartApprove"></chart-bar>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class>
              <h2>Antecedência para solicitação de entrada</h2>
              <chart-bar :data="analitics.chartAccess"></chart-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import userMixin from "../mixins/user.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import dashboardMixin from "../mixins/dashboard.mixin.js";
import MainLayout from "../layouts/Template.vue";
import moment from "moment";
import ChartBar from "../components/ChartBar.vue";
import ChartLine from "../components/ChartLine.vue";
import ChartPie from "../components/ChartPie.vue";
// import { filter } from "vue/types/umd";
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [userMixin, companyMixin, parkMixin, dashboardMixin],
  components: {
    MainLayout,
    ChartBar,
    ChartLine,
    ChartPie
  },
  data() {
    return {
      dateColors: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(23, 20, 187, 0.2)',
        'rgba(201, 24, 173, 0.2)',
        'rgba(207, 255, 15, 0.2)'
      ],
      parkList: [],
      companyList: [],
      colors: [],
      filter: {
        idpark: null,
        idcompany: null,
        dateRange: null,
      },
      analitics: {
        drivers: 0,
        requests: 0,
        entries: 0,
        avgTime: 0,
        chartBar: [],
        chartHourLine: [],
        chartReport: [],
        chartApprove: [],
        chartAccess: [],
      },
    };
  },
  mounted() {
    let startDate = null;
    let endDate = null;
    startDate = moment().subtract(7, "d").format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
    this.filter.dateRange = [startDate, endDate]

    this.listParks();
  },
  methods: {
    getStats(parkChanged) {
      let self = this;
      let startDate = null;
      let endDate = null;
      let idcompany = 'undefined';
      let idpark = null
      if (parkChanged) {
        if (this.filter.idpark == 999999999) this.filter.idcompany = null
        else {
          this.filter.idcompany = null
          this.listCompanies(this.filter.idpark);
        }
      }
      if (this.filter.idcompany != null) idcompany = this.filter.idcompany;
      if (this.filter.idpark) {
        if (this.filter.idpark == 999999999) idpark = 'undefined'
        else idpark = this.filter.idpark
        if (this.filter.dateRange) {
          startDate = this.filter.dateRange[0];
          endDate = this.filter.dateRange[1];
        } else {
          startDate = moment().subtract(2, "months").subtract(7, "d").format("YYYY-MM-DD");
          endDate = moment().subtract(2, "months").format("YYYY-MM-DD");
          this.filter.dateRange = [startDate, endDate]
        }

        this.getStatsMixin(
          idpark,
          idcompany,
          startDate,
          endDate
        ).then((response) => {
          self.fillDateChart(response.chart);
          self.fillApproveChart(response.approvalTime[0])
          self.fillAccessChart(response.avgTimeToAccess[0])
          self.fillHourChart(response.chartHour);
          self.fillReportChart(response.chartReport);
          self.analitics.drivers = response.totalDrivers;
          self.analitics.requests = response.totalRequests;
          self.analitics.entries = response.totalEntries;
          self.analitics.avgTime = response.avgTime;

        });
      } else {
        this.filter.idcompany = null;
        this.analitics.driver = 0;
        this.analitics.requests = 0;
        this.analitics.entries = 0;
        this.analitics.avgTime = 0;
        this.analitics.chartBar = [];
        this.analitics.chartHourLine = [];
        this.analitics.chartReport = [];
      }
    },
    fillDateChart(chartData) {
      var chart = {
        labels: [],
        datasets: [
          {
            label: "Solicitações",
            backgroundColor: this.dateColors[0],
            data: [],
          },
          {
            label: "Acessos",
            backgroundColor: this.dateColors[1],
            data: [],
          },
          {
            label: "Notificações",
            backgroundColor: this.dateColors[2],
            data: [],
          },
        ],
      };

      var from = new Date(this.filter.dateRange[0]);
      var to = new Date(this.filter.dateRange[1]);
      var fDate = null;
      var index = null;
      // loop for every day
      for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
        fDate = this.formatDate(day);
        chart.labels.push(fDate);
      }

      for (let item of chartData) {
        fDate = this.formatDate(item.day);
        index = chart.labels.indexOf(fDate);
        if (index == -1) {
          chart.labels.push(fDate);
          index = chart.labels.indexOf(fDate);
        }
        chart.datasets[0].data[index] = item.requests ? item.requests : 0;
        chart.datasets[1].data[index] = item.entries ? item.entries : 0;
        chart.datasets[2].data[index] = item.reports ? item.reports : 0;
      }

      this.analitics.chartBar = chart;
    },
    fillApproveChart(chartData) {
      var chart = {
        labels: [],
        datasets: [
          {
            label: "Aprovações",
            backgroundColor: this.dateColors[0],
            data: [],
          },
        ],
      };

      chart.labels = [
        'em até 10 min',
        'entre 10 e 30 min',
        'entre 30 e 60 min',
        'entre 1 e 4 hrs',
        'entre 4 e 8 hrs',
        'entre 8 e 24 hrs',
        'mais de 24 hrs',
      ]
      chart.datasets[0].data[0] = chartData.in_10_min ? chartData.in_10_min : 0;
      chart.datasets[0].data[1] = chartData.between_10_and_30_min ? chartData.between_10_and_30_min : 0;
      chart.datasets[0].data[2] = chartData.between_30_and_60_min ? chartData.between_30_and_60_min : 0;
      chart.datasets[0].data[3] = chartData.between_1_and_4_hours ? chartData.between_1_and_4_hours : 0;
      chart.datasets[0].data[4] = chartData.between_4_and_8_hours ? chartData.between_4_and_8_hours : 0;
      chart.datasets[0].data[5] = chartData.between_8_and_24_hours ? chartData.between_8_and_24_hours : 0;
      chart.datasets[0].data[6] = chartData.more_than_24_hours ? chartData.more_than_24_hours : 0;

      this.analitics.chartApprove = chart;
    },
    fillAccessChart(chartData) {
      var chart = {
        labels: [],
        datasets: [
          {
            label: "Acessos",
            backgroundColor: this.dateColors[0],
            data: [],
          },
        ],
      };

      chart.labels = [
        'em até 1 hora',
        'entre 1 e 4 hrs',
        'entre 4 e 8 hrs',
        'entre 8 e 16 hrs',
        'entre 16 e 24 hrs',
        'entre 1 e 3 dias',
        'entre 3 e 5 dias',
        'entre 5 e 15 dias',
        'entre 15 e 30 dias',
        'mais de 1 mês',
      ]
      // console.log('chartData', chartData)
      chart.datasets[0].data[0] = chartData.in_1_hour ? chartData.in_1_hour : 0;
      chart.datasets[0].data[1] = chartData.between_1_and_4_hours ? chartData.between_1_and_4_hours : 0;
      chart.datasets[0].data[2] = chartData.between_4_and_8_hours ? chartData.between_4_and_8_hours : 0;
      chart.datasets[0].data[3] = chartData.between_8_and_16_hours ? chartData.between_8_and_16_hours : 0;
      chart.datasets[0].data[4] = chartData.between_16_and_24_hours ? chartData.between_16_and_24_hours : 0;
      chart.datasets[0].data[5] = chartData.between_1_and_3_days ? chartData.between_1_and_3_days : 0;
      chart.datasets[0].data[6] = chartData.between_3_and_5_days ? chartData.between_3_and_5_days : 0;
      chart.datasets[0].data[7] = chartData.between_5_and_15_days ? chartData.between_5_and_15_days : 0;
      chart.datasets[0].data[8] = chartData.between_15_and_30_days ? chartData.between_15_and_30_days : 0;
      chart.datasets[0].data[9] = chartData.more_than_a_month ? chartData.more_than_a_month : 0;

      this.analitics.chartAccess = chart;
    },
    fillHourChart(chartData) {
      var chart = {
        labels: [],
        datasets: [
          {
            label: "Solicitações",
            backgroundColor: this.dateColors[0],
            data: [],
          },
          {
            label: "Acessos",
            backgroundColor: this.dateColors[1],
            data: [],
          },
        ],
      };

      var index = null;
      // loop for every day
      for (var hour = 0; hour <= 23; hour = hour + 1) {
        // console.log(hour);
        chart.labels.push(hour);
      }

      for (let item of chartData) {
        index = chart.labels.indexOf(item.hour);
        if (index == -1) {
          chart.labels.push(item.hour);
          index = chart.labels.indexOf(item.hour);
        }
        chart.datasets[0].data[index] = item.requests ? item.requests : 0;
        chart.datasets[1].data[index] = item.entries ? item.entries : 0;
      }

      // console.log(chart);
      this.analitics.chartHourLine = chart;
    },
    fillReportChart(chartData) {
      var chart = {
        labels: [],
        datasets: [
          {
            label: "Dados",
            backgroundColor: [],
            data: [],
          },
        ],
      };

      var index = 0;
      for (let item of chartData) {
        // console.log(item);
        chart.labels.push(item.name);
        chart.datasets[0].backgroundColor.push(this.dateColors[index]);
        chart.datasets[0].data.push(item.count);
        index = index + 1;
      }

      // console.log(chart);
      this.analitics.chartReport = chart;
    },
    formatDate: function (date) {
      if (date) {
        return moment(date).format('DD/MM');
      } else {
        return "Indefinido"
      }
    },
    listCompanies(idpark) {
      let self = this;
      this.listCompaniesMixin(500, null, null, 'ASC', 'name', null, idpark).then((response) => {
        self.companyList = response.companyList;

      });
    },
    listParks() {
      let self = this;
      this.listParksMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.parkList = response.parkList;

        let user = getLocalStorage('user')
        if (user.job == '10') self.parkList.push({ idpark: 999999999, name: 'Todos' })
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#dashboard {
  h2 {
    color: #44af3b;
    font-weight: 500;
    font-size: 14pt;
    margin-top: 20px;
  }

  .info-card {
    padding: 20px;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 15px;

    h3 {
      color: #44af3b;
      font-weight: 500;
      font-size: 9pt;
    }

    p {}

    .disabled {
      pointer-events: none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;
    }
  }
}
</style>