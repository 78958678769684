import Vue from 'vue'

import uuid from "device-uuid";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import './plugins/basic'
import './plugins/charts'
import './plugins/forms'
import './plugins/google'
import './plugins/others'

import './middlewares/interceptor'

import './filters/calculateAge'
import './filters/formatDate'
import './filters/getHour'
import './filters/formatPhone'
import './filters/replace'
import './filters/strLimit'
import './filters/formatCpf'
import './filters/formatCnpj'
import './filters/toCurrency'
import './filters/verifyIfNull'

import './mixins/navigation.mixin'

import routes from './routes.js'
import App from './App.vue'
import axios from 'axios'
import { server } from './js/variables.js';
import { setLocalStorage } from "./js/encryption";
import moment from 'moment'

Vue.config.productionTip = false;

Vue.mixin({
  data: function () {
    return {
      statusOptions: [
        { name: "Todos", value: '3' },
        { name: "Ativos", value: '2' },
        { name: "Cancelado", value: '0' },
        { name: "Aguardando", value: '1' },
        { name: "Inativos", value: '-1' },
      ],
      statusOptions2: [
        { name: "Todos", value: '3' },
        { name: "Ativos", value: '2' },
        { name: "Inativos", value: '-1' },
      ],
      statusOptions3: [
        { name: "Todos", value: '3' },
        { name: "Aprovados", value: '2' },
        { name: "Aguardando", value: '1' },
        { name: "Cancelados", value: '0' },
        { name: "Rejeitados", value: '-1' },
      ],
      statusBindOptions: [
        { name: "Todos", value: '3' },
        { name: "Vínculado", value: '2' },
        { name: "Aguardando terceira", value: '1' },
        { name: "Aguardando motorista", value: '0' },
        { name: "Sem vínculo", value: '-1' },
      ],
      statusOptionsIntegration: [
        { name: "Todos", value: '3' },
        { name: "Sucesso", value: '2' },
        { name: "Bad request", value: '0' },
        { name: "Erro", value: '-1' },
      ],
      genderOptions: [
        { name: "Masculino", value: "M" },
        { name: "Feminino", value: "F" },
        // { label: "Outros", name: "O" },
        // { label: "Prefiro não informar", name: "N" },
      ],
      orderOptions: [
        { name: "Mais recentes", value: '10' },
        { name: "Mais antigos", value: '11' },
        { name: "Crescente", value: '21' },
        { name: "Decrescente", value: '20' },
      ],
      orderOptions2: [
        { name: "Mais recentes", value: '10' },
        { name: "Mais antigos", value: '11' },
      ],
      jobOptions: [
        { name: "Administrador", value: '10' },
        { name: "Coordenador", value: '11' },
        { name: "Locatário Adm", value: '20' },
        { name: "Locatário Aprovador", value: '21' },
        { name: "Segurança Adm", value: '30' },
        { name: "Segurança", value: '31' },
        { name: "Terceiro Adm", value: '40' },
        { name: "Terceiro Funcionário", value: '41' },
      ],
      employeeJobOptions: [
        { name: "Locatário administrador", value: '20' },
        { name: "Funcionário", value: '21' },
      ],
      thirdEmployeeJobOptions: [
        { name: "Terceiro administrador", value: '40' },
        { name: "Terceiro Funcionário", value: '41' },
      ],
      integrationsCalls: [
        { name: "Evento/Registrar", value: '10' },
        { name: "Evento/Locais", value: '11' },
        { name: "Agendamento/Registrar", value: '21' },
        { name: "Agendamento/Alterar", value: '22' },
        { name: "Agendamento/Cancelar", value: '23' },
      ],
      daysAndMonths: {
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      },
      // filter: {
      //   searchText: null,
      //   searchingText: null,
      //   order: null,
      //   sort: null,
      //   status: null,
      //   dateRange: null,
      //   company: null,
      //   park: null,
      // },
      // zoom: 12,
      // center: {
      //   lat: -23.5902,
      //   lng: -46.6819,
      // },
      // position: null,
      photoType: true,
      photoNull: null,
    }
  },
  methods: {
    goBack(currentWindow) {
      console.log(currentWindow.history.length)
      if (currentWindow.history.length > 1) this.$router.go(-1)
      else currentWindow.close()
    },
    goTo(path) {
      this.$router.push(path);
    },
    openNewTab(path) {
      window.open(path, "_blank");
    },
    getBtnTitle(condition, text) {
      if (condition) return text
      else return ''
    },
    getAddressMixin(zipcode, object) {
      const self = this;
      if (zipcode.length > 7) {
        return axios.get(server.url + "/zipcode?zipcode=" + self.removeMaskZipcode(zipcode))
          .then((response) => {
            let responseData = response.data.data;
            object.street = responseData.logradouro;
            object.state = responseData.uf;
            object.city = responseData.localidade;
            object.district = responseData.bairro;
            self.getLatLngMixin(object);
            return responseData;
          })
          .catch(function (error) {
            self.showError(error);
            return null;
          });
      }
    },
    getLatLngMixin(object) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        zipcode: object.zipcode,
        street: object.street,
        number: object.number,
        district: object.district,
        city: object.city,
        state: object.state,
      };
      return axios.get(server.url + "/geo-location", config)
        .then((response) => {
          let responseData = response.data.data;
          object.lat = responseData.geometry.location.lat.toString();
          object.lng = responseData.geometry.location.lng.toString();
          self.prepareMap(object);
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    removeSearchTextMixin() {
      this.filter.searchText = null;
      return this.filter.searchText
    },
    stringToFloat(text) {
      return parseFloat(text);
    },
    getImageUrl(route) {
      // let image = server.image_url + route
      return route
    },
    getMediaUrl(route) {
      // let image = server.media_url + route
      return route
    },
    removeMaskZipcode(zipcode) {
      // console.log("aqui removeMaskZipcode", zipcode, zipcode.length)
      if (zipcode.length == 9) {
        let zipcodeNoMask = zipcode.slice(0, 5);
        zipcodeNoMask += zipcode.slice(-3);
        return zipcodeNoMask;
      }
      return zipcode;
    },
    removeMaskPhone(phone) {
      // console.log("aqui removeMaskPhone", phone, phone.length)
      if (phone.length == 15) {
        let phoneNoMask = phone.slice(1, 3);
        phoneNoMask += phone.slice(5, 10);
        phoneNoMask += phone.slice(-4);
        return phoneNoMask;
      }
      return phone;
    },
    removeMaskPhoneDriver(phone) {
      // console.log("aqui removeMaskPhoneDriver", phone, phone.length)
      if (phone.length == 13) {
        return phone.slice(2, phone.length);
      }
      return phone;
    },
    removeMaskCPF(cpf) {
      // console.log("aqui removeMaskCPF", cpf, cpf.length)
      if (cpf.length == 14) {
        let cpfNoMask = cpf.slice(0, 3);
        cpfNoMask += cpf.slice(4, 7);
        cpfNoMask += cpf.slice(8, 11);
        cpfNoMask += cpf.slice(-2);
        return cpfNoMask;
      }
      return cpf;
    },
    removeMaskCNPJ(cnpj) {
      // console.log("aqui removeMaskCNPJ", cnpj, cnpj.length)
      if (cnpj.length == 18) {
        let cnpjNoMask = cnpj.slice(0, 2);
        cnpjNoMask += cnpj.slice(3, 6);
        cnpjNoMask += cnpj.slice(7, 10);
        cnpjNoMask += cnpj.slice(11, 15);
        cnpjNoMask += cnpj.slice(-2);
        return cnpjNoMask;
      }
      return cnpj;
    },
    fixPhoneDriverToSend(phone) {
      // console.log("aqui fixPhoneDriverToSend", phone, phone.length)
      if (phone.length == 15) {
        let phoneNoMask = '+55'
        phoneNoMask += phone.slice(0, 4);
        phoneNoMask += phone.slice(5, 10);
        phoneNoMask += phone.slice(-4);
        return phoneNoMask;
      }
      return phone;
    },
    CPFMask(cpf) {
      if (!cpf || typeof cpf !== "string") return ''
      cpf = cpf.replace(/\D/g, "");

      if (cpf.length !== 11) return cpf

      return (
        cpf.slice(0, 3) + "." +
        cpf.slice(3, 6) + "." +
        cpf.slice(6, 9) + "-" +
        cpf.slice(9)
      );
    },
    CNPJMask(cnpj) {
      if (!cnpj || typeof cnpj !== "string") return ''
      cnpj = cnpj.replace(/\D/g, "");

      if (cnpj.length !== 14) return cnpj

      return (
        cnpj.slice(0, 2) + "." +
        cnpj.slice(2, 5) + "." +
        cnpj.slice(5, 8) + "/" +
        cnpj.slice(8, 12) + "-" +
        cnpj.slice(12)
      );
    },
    addWindowRangeToHour(time, windowRange) {
      let t = moment(time, 'HH:mm').format();
      if (windowRange) this.request.hour_max = moment(String(t)).add(windowRange, 'hours').format('HH:mm')
    },
    cleanFilter() {
      this.filter.searchText = null;
      this.filter.order = '10';
      this.filter.sort = null;
      this.filter.status = '3';
      this.filter.dateRange = null;
    },
    verifyIfNull(item) {
      if (item == null || item == '') {
        return '-'
      } else {
        return item
      }
    },
    formatDateToFront(dateString) {
      const dateParts = dateString.split("-")
      const formattedDate = dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0]
      return formattedDate
    },
    formatDateToServer(dateString) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (regex.test(dateString)) return dateString
      const dateParts = dateString.split("/")
      const formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
      return formattedDate
    },
    fixStatus(status) {
      switch (status) {
        case 2:
          return true;

        case true:
          return 2;

        case false:
          return -1;

        case -1:
          return false;

        case undefined:
          return -1;

        default:
          return -1;
      }
    },
    fixStatus2(status) {
      switch (status) {
        case true:
          return 2;

        case false:
          return -1;

        case undefined:
          return -1;

        default:
          return status;
      }
    },
    fixSort(sort) {
      switch (sort) {
        case '20':
          return 'name'

        case '21':
          return 'name'

        default:
          return 'created_at'
      }
    },
    fixOrder(order) {
      switch (order) {
        case '10':
          return 'DESC'

        case '11':
          return 'ASC'

        case '20':
          return 'DESC'

        case '21':
          return 'ASC'

        default:
          return 'DESC'
      }
    },
    userJob(jobId) {
      switch (jobId) {
        case '10':
          return 'Administrador';

        case '11':
          return 'Coordenador';

        case '20':
          return 'Locatário Adm';

        case '21':
          return 'Locatário Aprovador';

        case '30':
          return 'Segurança Adm';

        case '31':
          return 'Segurança';

        case '40':
          return 'Terceiro Adm';

        case '41':
          return 'Terceiro funcionário';

        default:
          return '-';
      }
    },
    userGender(gender) {
      switch (gender) {
        case 'M':
          return 'Masculino';

        case 'F':
          return 'Feminino';
      }
    },
    parkStatus(statusId) {
      switch (statusId) {
        case -1:
          return 'Inativo';

        case 0:
          return 'Removido';

        case 1:
          return 'Aguardando';

        case 2:
          return 'Ativo';

        default:
          return '-';
      }
    },
    userStatus(statusId) {
      switch (statusId) {
        case -1:
          return 'Inativo';

        case 0:
          return 'Conta removida';

        case 1:
          return 'Aguardando';

        case 2:
          return 'Ativo';

        default:
          return '-';
      }
    },
    userStatusClass(statusId) {
      switch (statusId) {
        case -1:
          return 'status-text-red';

        case 0:
          return 'status-text-gray';

        case 1:
          return 'status-text-orange';

        case 2:
          return 'status-text-green';

        default:
          return 'status-text-gray';
      }
    },
    genetecStatusClass(statusId) {
      switch (statusId) {
        case true:
          return 'status-text-green';

        default:
          return 'status-text-red';
      }
    },
    integrationStatusClass(statusId) {
      switch (statusId) {
        case 'Internal Server Error':
          return 'status-text-red';

        case 'ECONNABORTED':
          return 'status-text-red';

        case 'ETIMEDOUT':
          return 'status-text-red';

        case 'Bad Request':
          return 'status-text-orange';

        case 'success':
          return 'status-text-green';

        default:
          return 'status-text-gray';
      }
    },
    requestStatus(statusId) {
      switch (statusId) {
        case -1:
          return 'Rejeitado';

        case 0:
          return 'Cancelado';

        case 1:
          return 'Aguardando';

        case 2:
          return 'Aprovado';

        default:
          return '-';
      }
    },
    genetecStatus(statusId) {
      switch (statusId) {
        case true:
          return 'Integrado'

        default:
          return 'Inativo'
      }
    },
    integrationStatus(statusId) {
      switch (statusId) {
        case -1:
          return 'Erro';

        case 0:
          return 'Bad request';

        case 2:
          return 'Sucesso';

        default:
          return '-';
      }
    },
    driverThirdStatus(statusId) {
      switch (statusId) {
        case 0:
          return 'Aguardando motorista';

        case 1:
          return 'Aguardando terceira';

        case 2:
          return 'Ativo';

        default:
          return '-';
      }
    },
    driverThirdStatusClass(statusId) {
      switch (statusId) {
        case 0:
          return 'status-text-orange';

        case 1:
          return 'status-text-orange';

        case 2:
          return 'status-text-green';

        default:
          return 'status-text-gray';
      }
    },
    employeeJob(jobId) {
      switch (jobId) {
        case '20':
          return 'Locatário administrador';

        case '21':
          return 'Funcionário';

        case '40':
          return 'Terceiro administrador';

        case '41':
          return 'Terceiro funcionário';
      }
    },
    vehicleColor(color) {
      switch (color) {
        case '#ffffff':
          return 'Branco'

        case '#000000':
          return 'Preto'

        case '#333333':
          return 'Prata'

        case '#ff0000':
          return 'Vermelho'

        case '#555555':
          return 'Cinza'

        case '#0000ff':
          return 'Azul'

        case '#ffff00':
          return 'Amarelo'

        case '#00ff00':
          return 'Verde'

        // case '#333333':
        //   return 'Branco'

        default:
          return 'Outros'
      }
    },

    showError(error) {
      console.log(error);
      if (error.response) {
        console.log("Response", error.response.data);
        console.log("Response", error.response.status);
        console.log("Response", error.response.headers);

        var answer = error.response.data;
        console.log(answer);
        this.$swal({
          icon: "error",
          title: "Oops...",
          confirmButtonText: 'OK',
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
  }
})

new Vue({
  render: h => h(App),
  router: routes,
  // template: template,
  beforeCreate: function () {
    if (!localStorage.device) {
      let device = uuid.DeviceUUID().get();
      setLocalStorage('device', device)
    }
  },
}).$mount('#app')