<template>
  <main-layout>
    <div class="content-container">
      <div id="vehicle-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Veículo</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteVehicleMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/vehicles/new') == -1 && isThirdCompanyAdm()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && vehicle.idvehicle == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveVehicle()"
              v-if="this.$route.fullPath.indexOf('/vehicles/new') == -1 && isThirdCompanyAdm() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveVehicle()"
              v-if="this.$route.fullPath.indexOf('/vehicles/new') != -1 && isThirdCompanyAdm()">Registrar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                veículo</button>
              <button class="app-btn right-btn" @click="changeTab('drivers')"
                v-bind:class="{ 'app-btn-primary ': tab == 'drivers', 'app-btn-outline ': tab != 'drivers' }"
                v-if="this.$route.fullPath.indexOf('/vehicles/new') == -1">Motoristas</button>
              <button class="app-btn left-btn" @click="changeTab('requests')"
                v-bind:class="{ 'app-btn-primary ': tab == 'requests', 'app-btn-outline ': tab != 'requests' }"
                v-if="this.$route.fullPath.indexOf('/vehicles/new') == -1">Solicitações</button>
              <button class="app-btn right-btn" @click="changeTab('entries')"
                v-bind:class="{ 'app-btn-primary ': tab == 'entries', 'app-btn-outline ': tab != 'entries' }"
                v-if="this.$route.fullPath.indexOf('/vehicles/new') == -1">Acessos</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="tab == 'base'">
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12">
                <!-- <image-upload v-model="photoNull"></image-upload> -->
                <div class="no-image"></div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <ok-input :id="'type'" :model="vehicle.type" @updated="(x) => {
                  vehicle.type = x;
                }
                  " :label="'Modelo'" :options="vehicleTypeList" :placeholder="' '" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="vehicle.plate" @updated="(x) => {
                  vehicle.plate = x;
                }
                  " :label="'Placa'" :placeholder="' '" :id="'plate'" :name="'plate'" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :id="'type'" :model="vehicle.color" @updated="(x) => {
                  vehicle.color = x;
                }
                  " :label="'Cor'" :options="vehicleColorList" :placeholder="' '" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="vehicle.year" @updated="(x) => {
                  vehicle.year = x;
                }
                  " :label="'Ano (YYYY)'" :mask="'####'" :placeholder="' '" :id="'year'" :name="'year'"
                  rules="required|year">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="vehicle.idcompany" @updated="(x) => {
                  vehicle.idcompany = x;
                }
                  " :label="'Terceiro'" :options="thirdCompanyList" :placeholder="' '" :id="'idcompany'"
                  :name="'idcompany'" style="margin-top: 4px;">
                </ok-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'drivers'">

          <div class="col-md-12">
            <!-- vehicle.Vehicles && vehicle.Vehicles.length  -->
            <div class="no-content" v-if="vehicle.Drivers.length == 0">
              Este veículo nao pertence a nenhum motorista.
            </div>

            <div v-else class="col-md-12" v-for="driver in vehicle.Drivers" :key="driver.iddriver">
           
              <UserItem :userItem="driver" :isDriver="true"/>
            </div>

          </div>
        </div>

        <div class="row" v-if="tab == 'requests'">
          <div class="col-md-12" v-for="request in requestList" :key="request.idrequest">
            <driver-request-item :requestItem="request"></driver-request-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="requestList && requestList.length == 0">
              {{ noContentRequestText }}
            </div>
            <button class="app-btn app-btn-load-more"
              v-if="requestList && requestList.length > 0 && filterRequest.canLoadMore"
              @click="listDriverRequests()">Carregar mais</button>
          </div>
        </div>

        <div class="row" v-if="tab == 'entries'">
          <div class="col-md-12" v-for="entry in entryList" :key="entry.identry">
            <driver-entry-item :entryItem="entry"></driver-entry-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="entryList && entryList.length == 0">
              {{ noContentEntryText }}
            </div>
            <button class="app-btn app-btn-load-more" v-if="entryList && entryList.length > 0 && filterEntry.canLoadMore"
              @click="listDriverEntries()">Carregar
              mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import vehicleMixin from "../mixins/vehicle.mixin.js";
import requestMixin from "../mixins/request.mixin.js";
import entryMixin from "../mixins/entry.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import UserItem from '../components/UserItem.vue'
import DriverRequestItem from '@/components/DriverRequestItem.vue'
import DriverEntryItem from '@/components/DriverEntryItem.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin, thirdCompanyMixin, vehicleMixin, requestMixin, entryMixin],
  components: {
    MainLayout,
    OkInput,
    UserItem,
    DriverRequestItem,
    DriverEntryItem,
  },
  data() {
    return {
      tab: "base",
      thirdCompanyList: [],
      parkList: [],
      requestList: [],
      entryList: [],
      currentUser: null,
      isThird: false,
      noContentRequestText: 'Buscando solicitações...',
      noContentEntryText: 'Buscando acessos...',
      filterDriver: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterRequest: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterEntry: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.currentUser = getLocalStorage('user')
    if (this.currentUser.job === '40' || this.currentUser.job === '41') this.isThird = true
    if (id !== 'new') {
      this.loadVehicle();
      // this.listParks();
    }
    this.listThirdCompanies();

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadVehicle() {
      let self = this;
      const id = this.$route.params.id;
      this.getVehicleMixin(id).then((response) => {
        self.vehicle = response.vehicle;
        console.log(self.vehicle);
      });
    },
    changeTab(value) {
      this.tab = value;
      // console.log('changetab', value)
      if (value == 'requests') this.listVehicleRequests();
      if (value == 'entries') this.listVehicleEntries();
    },
    listVehicleRequests() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterRequest.canLoadMore) return
      this.noContentRequestText = 'Buscando solicitações...';

      this.listRequestsMixin(self.filterRequest.pageItems, self.filterRequest.page, null, null, null, null, null, null, null, null, id).then((response) => {
        if (self.filterRequest.page == 0) {
          self.requestList = response.requestList;
        } else {
          self.requestList = self.requestList.concat(response.requestList);
        }
        if (response.requestList.length != self.filterRequest.pageItems) {
          self.filterRequest.canLoadMore = false;
        } else {
          self.filterRequest.canLoadMore = true;
        }
        self.filterRequest.page++

        //remover mascara
        for (let request of self.requestList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentRequestText = 'Nenhuma solicitação para exibir';
      });
    },
    listVehicleEntries() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterEntry.canLoadMore) return
      this.noContentEntryText = 'Buscando acessos...';

      this.listEntriesMixin(self.filterEntry.pageItems, self.filterEntry.page, null, null, null, null, null, null, null, id).then((response) => {
        if (self.filterEntry.page == 0) {
          self.entryList = response.entryList;
        } else {
          self.entryList = self.entryList.concat(response.entryList);
        }
        if (response.entryList.length != self.filterEntry.pageItems) {
          self.filterEntry.canLoadMore = false;
        } else {
          self.filterEntry.canLoadMore = true;
        }
        self.filterEntry.page++

        //remover mascara
        for (let request of self.entryList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentEntryText = 'Nenhum acesso para exibir';
      });
    },
    listThirdCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500).then((response) => {
        self.thirdCompanyList = response.thirdList.map(item => {
          const cnpj = item.cnpj ? ' - ' + self.CNPJMask(item.cnpj) : ''
          const obj = {
            name: `${item.name}${cnpj}`,
            value: item.idcompany
          }
          return obj
        })
      });
    },
    saveVehicle() {
      let self = this
      this.saveVehicleMixin(self.vehicle, self.$route.params.id)
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#vehicle-view {
  .no-image {
    width: 100%;
    padding-top: 100% !important;
    background-color: $gray-md;
  }

}
</style>