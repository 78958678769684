<template>
    <div class="floating-whatsapp">
      <a class="icon" :href="whatsapp" target="_blank">
        <i class="fa-brands fa-whatsapp"></i>
      </a>
    </div>
</template>

<script>

export default {
  props: [],
  components: {
  },
  data() {
    return {
      whatsapp: 'https://api.whatsapp.com/send?phone=5511911093524',
    };
  },
  mounted() {
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.floating-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: white;
  border-radius: 5em;
  box-shadow: 0px 1px 3px gray;
  z-index: 10;
  
  .icon{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    color: #56d062;
    font-size: 35px;
    height: 58px;
    width: 58px;
    border-radius: 5em;

    &:hover{
      background-color: #ebebeb;
    }
  }

}

@media only screen and (max-width: 600px) {
  .floating-whatsapp {

    .icon{
      font-size: 25px;
      height: 41px;
      width: 41px;
    }
  }
}
</style>