<template>
  <main-layout>
    <div class="content-container" >
      <div id="driver-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Cadastros</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="exportRegistrationCSV">Download csv</button>
            <download-csv
              class="app-btn app-btn-primary"
              :data="registrationListCSV"
              :separator-excel="true"
              name="cadastros.csv"
              style="display: none"
              ref="downloadCSV">
              Download csv
            </download-csv>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input
              :model="filter.searchText"
              @updated="
                (x) => {
                  filter.searchText = x;
                }
              "
              :label="'Pesquisar'"
              :placeholder="' '"
              :id="'searchText'"
              :name="'searchText'"
              @onEnter="applyFilter"
            >
            </ok-input><button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.idcompany"
                :options="companyList"
                :reduce="(company) => company.idcompany "
                @input="applyFilter"
                placeholder="Selecione a empresa"
              ></v-select>
              <label>Empresa</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.status"
                :options="statusOptions3"
                :reduce="(status) => status.value "
                placeholder="Todos"
                @input="applyFilter"
              ></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-3">
            <date-picker
              v-model="filter.dateRange"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              type="date"
              range
              placeholder="Selecione o período"
              :lang="daysAndMonths"
              @input="applyFilter"
            >
            </date-picker>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.order"
                :options="orderOptions2"
                placeholder="Mais recentes"
                :reduce="(order) => order.value "
                @input="applyFilter"
              ></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{filter.searchingText}}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="registration in registrationList" :key="registration.idregistration">
            <registration-item :registrationItem="registration"></registration-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="registrationList && registrationList.length == 0">
              <p>{{noContentText}}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="registrationList && registrationList.length > 0 && filter.canLoadMore" @click="listRegistrations()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import registrationMixin from "../mixins/registration.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import RegistrationItem from '@/components/RegistrationItem.vue'
import moment from 'moment'

export default {
  mixins: [registrationMixin, companyMixin],
  components: {
    MainLayout,
    OkInput,
    RegistrationItem,
  },
  data() {
    return {
      registrationList: [],
      registrationListCSV: [],
      companyList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        from: null,
        to: null,
        idcompany: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando cadastros...',
    };
  },
  mounted() {
    this.filter.status = '1';
    this.listRegistrations();
    this.listCompanies();
  },
  methods: {
    listRegistrations(){
      let self = this;
      let startDate = null;
      let endDate = null;
      let status = self.filter.status;
      if(status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      if(self.filter.dateRange){
        startDate = self.filter.dateRange[0];
        endDate = self.filter.dateRange[1];
      }/* else{
        startDate = moment().subtract(7,'d').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        self.filter.dateRange = [startDate, endDate];
      } */
      this.noContentText = 'Buscando cadastros...';
      
      this.listRegistrationsMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, startDate, endDate, self.filter.idcompany).then((response) => {
        if(self.filter.page == 0){
          self.registrationList = response.registrationList;
        }else{
          self.registrationList = self.registrationList.concat(response.registrationList);
        }
        if(response.registrationList.length != self.filter.pageItems){
          self.filter.canLoadMore = false;
        }else{
          self.filter.canLoadMore = true;
        }
        self.filter.page ++

        if(self.filter.searchText){
          self.filter.searchingText = self.filter.searchText;
        }else{
          self.filter.searchingText = null;
        }

        //remover mascara
        for(let registration of self.registrationList){
          if(registration.Driver.phone.length > 11){
            let phone = registration.Driver.phone.slice(4, 6);
            phone += registration.Driver.phone.slice(7, 17);
            registration.Driver.phone = phone;
          }
        }
        self.noContentText = 'Nenhum cadastro para exibir';

        // self.registrationListCSV = self.prepareCSVRegistrations(self.registrationList)
      });
    },
    exportRegistrationCSV(){
      let self = this;
      this.$swal({
        title: 'Exportação csv',
        text: 'Qual o número limite de cadastros que você deseja?',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        confirmButtonText: 'Exportar',
        cancelButtonText: 'Cancelar',
        }).then((result) => {
        if (result.isConfirmed) {
          
          let startDate = null;
          let endDate = null;
          let status = self.filter.status;
          if(status == '3') status = null;
          let sort = this.fixSort(self.filter.order);
          let order = this.fixOrder(self.filter.order);
          if(self.filter.dateRange){
            startDate = self.filter.dateRange[0];
            endDate = self.filter.dateRange[1];
          }else{
            startDate = moment().subtract(7,'d').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            self.filter.dateRange = [startDate, endDate];
          }
          
          if(result.value < 1 || result.value == '' || !result.value || isNaN(result.value)){
            self.$swal.fire({
              icon: 'warning',
              title: 'Atenção',
              text: 'Digite um número limite',
            })
            return
          }
          
          this.listRegistrationsMixin(result.value, 0, self.filter.searchText, order, sort, status, startDate, endDate, self.filter.idcompany).then((response) => {
            //remover mascara
            for(let registration of response.registrationList){
              if(registration.Driver.phone.length > 11){
                let phone = registration.Driver.phone.slice(4, 6);
                phone += registration.Driver.phone.slice(7, 17);
                registration.Driver.phone = phone;
              }
            }
            self.registrationListCSV = self.prepareCSVRegistrations(response.registrationList)
            // console.log('aqui registrationListCSV', self.registrationListCSV)
            setTimeout(() => {  this.$refs.downloadCSV.$el.click(); }, 1000);
          });
        }
      })
    },
    listCompanies(){
      let self = this;
      this.listCompaniesMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.companyList = response.companyList;
      
      });
    },
    applyFilter(){
      this.filter.page = 0;
      this.listRegistrations()
    },
    removeSearchText(){
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";
#registration-list{
  

}
</style>