<template>
  <main-layout>
    <div class="content-container" >
      <div id="park-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Parques</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="goTo('/parks/new')" v-if="isAdministrator()">Novo registro</button>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input
              :model="filter.searchText"
              @updated="
                (x) => {
                  filter.searchText = x;
                }
              "
              :label="'Pesquisar'"
              :placeholder="' '"
              :id="'searchText'"
              :name="'searchText'"
              @onEnter="applyFilter"
            >
            </ok-input>
            <button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-6">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.status"
                :options="statusOptions2"
                :reduce="(status) => status.value "
                placeholder="Todos"
                @input="applyFilter"
              ></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-with-label">
              <v-select
                label="name"
                v-model="filter.order"
                :options="orderOptions"
                :reduce="(order) => order.value "
                placeholder="Mais recentes"
                @input="applyFilter"
              ></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{filter.searchingText}}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="park in parkList" :key="park.idpark">
            <park-item :parkItem="park"></park-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="parkList && parkList.length == 0">
              <p>{{noContentText}}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="parkList && parkList.length > 0 && filter.canLoadMore" @click="listParks()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import ParkItem from '@/components/ParkItem.vue'

export default {
  mixins: [authMixin, parkMixin],
  components: {
    MainLayout,
    OkInput,
    ParkItem,
  },
  data() {
    return {
      parkList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando parques...',
    };
  },
  mounted() {
    this.listParks();
  },
  methods: {
    listParks(){
      let self = this;
      let status = self.filter.status;
      if(status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando parques...';
      
      this.listParksMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status).then((response) => {
        if(self.filter.page == 0){
          self.parkList = response.parkList;
        }else{
          self.parkList = self.parkList.concat(response.parkList);
        }
        if(response.parkList.length != self.filter.pageItems){
          self.filter.canLoadMore = false;
        }else{
          self.filter.canLoadMore = true;
        }
        self.filter.page ++

        if(self.filter.searchText){
          self.filter.searchingText = self.filter.searchText;
        }else{
          self.filter.searchingText = null;
        }
        
        self.noContentText = 'Nenhum parque para exibir';
      });
    },
    applyFilter(){
      this.filter.page = 0;
      this.listParks()
    },
    removeSearchText(){
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";
#park-list{
  

}
</style>