<template>
  <main-layout>
    <div class="content-container">
      <div id="error-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Log de integração</h1>
          </div>
          <div class="col-md-6 last">
          </div>
          <div class="col-md-4 search-with-btn" style="margin-top: 10px">
            <ok-input :model="filter.searchText" @updated="(x) => {
                filter.searchText = x;
              }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="applyFilter">
            </ok-input><button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-4" style="margin-top: 4px">
            <date-picker v-model="filter.dateRange" value-type="YYYY-MM-DD" format="DD/MM/YYYY" type="date" range
              placeholder="Selecione o período" :lang="daysAndMonths" @input="applyFilter">
            </date-picker>
          </div>
          <div class="col-md-4" style="margin-top: 4px">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.order" :options="orderOptions2" placeholder="Mais recentes"
                :reduce="(order) => order.value" @input="applyFilter"></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.type" :options="integrationsTypes" :reduce="(type) => type.idapp"
                @input="applyFilter" placeholder="Selecione o tipo">
              </v-select>
              <label>Tipo</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.call" :options="integrationsCalls" :reduce="(call) => call.value"
                @input="applyFilter" placeholder="Selecione a chamada">
              </v-select>
              <label>Chamada</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idpark" :options="parkList" placeholder="Selecione o parque"
                :reduce="(park) => park.idpark" @input="applyFilter">
              </v-select>
              <label>Parque</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.status" :options="statusOptionsIntegration"
                :reduce="(status) => status.value" placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{ filter.searchingText }}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="error in errorList" :key="error.iderrorlog">
            <error-item :errorItem="error"></error-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="errorList && errorList.length == 0">
              <p>{{ noContentText }}</p>
            </div>
            <button class="app-btn app-btn-load-more" v-if="errorList && errorList.length > 0 && filter.canLoadMore"
              @click="listErrors()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import errorMixin from "../mixins/error.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import applicationMixin from "../mixins/application.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import ErrorItem from '@/components/ErrorItem.vue'
import moment from 'moment'

export default {
  mixins: [errorMixin, companyMixin, parkMixin, applicationMixin],
  components: {
    MainLayout,
    OkInput,
    ErrorItem,
  },
  data() {
    return {
      errorList: [],
      requestListCSV: [],
      applicationList: [],
      integrationsTypes: [],
      parkList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        from: null,
        to: null,
        idcompany: null,
        type: '/api',
        call: null,
        idpark: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando log de erros...',
    };
  },
  mounted() {
    this.listErrors()
    this.listApplications()
    this.listParks()
  },
  methods: {
    listErrors() {
      let self = this;
      let startDate = null;
      let endDate = null;
      let status = self.filter.status;
      if (status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      sort = 'created_at'
      if (self.filter.dateRange) {
        startDate = self.filter.dateRange[0];
        endDate = self.filter.dateRange[1];
      } else {
        startDate = moment().subtract(14, 'd').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        self.filter.dateRange = [startDate, endDate];
      }
      this.noContentText = 'Buscando log de erros...';

      this.listErrorsMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, startDate, endDate, self.filter.type, self.filter.call, self.filter.idpark).then((response) => {
        if (self.filter.page == 0) {
          self.errorList = response.errorList;
        } else {
          self.errorList = self.errorList.concat(response.errorList);
        }
        if (response.errorList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }

        self.noContentText = 'Nenhuma log de erro para exibir';
      });
    },
    listApplications() {
      let self = this;

      this.listApplicationsMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.integrationsTypes = response.applicationList;
        self.integrationsTypes.push({ name: 'Genetec', idapp: '/api' })
      });

      self.integrationsTypes.push({ name: 'Genetec', idapp: '/api' })
    },
    listCompanies() {
      let self = this;
      this.listCompaniesMixin(500).then((response) => {
        self.companyList = response.companyList;

      });
    },
    listParks() {
      let self = this;
      this.listParksMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.parkList = response.parkList;
      });
    },
    applyFilter() {
      this.filter.page = 0;
      this.listErrors()
    },
    removeSearchText() {
      this.removeSearchTextMixin();
      this.applyFilter();
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#error-list {}
</style>