import axios from 'axios';
import { server } from '../js/variables.js'
import moment from 'moment'

var reportMixin = {
  data: function () {
    return {
      report: {
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
        range: null,
        idpark: null,
      },
    }
  },
  methods: {
    listReportsMixin(limit, page, search, order, sort, startDate, endDate, company) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        from: startDate,
        to: endDate,
        idpark: company,
      };
      
      return axios.get(server.api_url + '/reports', config)
      .then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    getReportMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/reports/' + id, server.config)
      .then((response) => {
        let responseData = response.data.data;

        //remover mascara
        if(responseData.report.Request.Driver.phone && responseData.report.Request.Driver.phone.length > 11){
          responseData.report.Request.Driver.phone = self.removeMaskPhoneDriver(responseData.report.Request.Driver.phone);
        }

        if(responseData.report.Request.Driver.company_cnpj && responseData.report.Request.Driver.company_cnpj.length > 14){
          let cnpj = responseData.report.Request.Driver.company_cnpj.slice(0, 8);
          cnpj += responseData.report.Request.Driver.company_cnpj.slice(-6);
          responseData.report.Request.Driver.company_cnpj = cnpj;
        }
        
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    prepareCSVReports(data){
      let array = JSON.parse(JSON.stringify(data));
      let arrayReady = [];
      for(let item of array){
        item.created_at = moment(String(item.created_at)).format('DD/MM/YYYY')
        item.Request.created_at = moment(String(item.Request.created_at)).format('DD/MM/YYYY')
        item.Request.day = moment(String(item.Request.day)).format('DD/MM/YYYY')

        let objFormated = {
          code: item.idreport,
          created_at: item.created_at,

          // request 
          request_code: item.Request.idrequest,
          request_status: this.userStatus(item.Request.status) ,
          request_created_at: item.Request.created_at,
          request_day: item.Request.day,
          request_hour: item.Request.hour ,
          request_hour_min: item.Request.hour_min ,
          request_hour_max: item.Request.hour_max ,
          request_company_code: item.Request.company_code ,

          // request_driver
          request_driver_name: item.Request.Driver.name,
          request_driver_lastname: item.Request.Driver.lastname,
          request_driver_company_name: item.Request.Driver.phone,
          // request_driver_image_photo: item.Request.Driver.image_photo,

          // request_park
          request_park_name: item.Request.Park.name,

          // user
          approver_name: item.User ? item.User.name + item.User.lastname : "",
          
          // ReportType
          report_type_code: item.idreporttype,
          report_type_name: item.ReportType.name,

        };
        arrayReady.push(objFormated)
      }
      
      // console.log('arrayReady', arrayReady)
      return arrayReady;
    },
  }
};

export default reportMixin;