<template>
  <div class="data-item">
    <!-- <div class="row">
      <div class="col-md-4 data-left">
        <p><span class="highlight">Entrada:</span> {{entryItem.access_in | formatDateTime}}</p>
        <p><span class="highlight">Saída:</span> {{entryItem.access_out | formatDateTime}}</p>
      </div>
      <div class="col-md-4 data-middle">
        <p><span class="highlight">Motorista:</span> {{entryItem.Request.Driver.name}} {{entryItem.Request.Driver.lastname}}</p>
        <p><span class="highlight">Acompanhante:</span> -</p>
      </div>
      <div class="col-md-4 data-right">
        <p><span class="highlight">Empresa do motorista:</span> -</p>
        <p><span class="highlight">Segurança:</span> {{entryItem.User.name}} {{entryItem.User.lastname}}</p>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-5 data-middle">
        <ImageItem :path="entryItem.Request.Driver.image_photo" :isSmall="true"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">{{ entryItem.Request.Driver.name }} {{ entryItem.Request.Driver.lastname }}</p>
          <p>{{ entryItem.Request.Driver.company_name }}</p>
          <p v-if="entryItem.Companion && entryItem.Companion.length > 0">Acompanhantes: {{ entryItem.Companion.length }}
          </p>
          <p v-else>Acompanhantes: 0</p>
        </div>
      </div>
      <div class="col-md-4 data-middle">
        <div class="middle-infos">
          <p class="highlight">{{ entryItem.Request.Park.name }}</p>
          <p>
            Segurança: <span v-if="entryItem.User">{{ entryItem.User.name }} {{ entryItem.User.lastname }}</span> <span v-else> - </span>
          </p>
          <!-- <p>Portaria: -</p> -->
        </div>
      </div>
      <div class="col-md-3 data-right">
        <p>Entrada: {{ entryItem.access_in | formatDateTime }}</p>
        <p>Saída: {{ entryItem.access_out | formatDateTime }}</p>
      </div>
    </div>
    <overlay-item :route="'/entries/' + entryItem.identry"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  props: ["entryItem"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.entry-item {}

@media only screen and (max-width: 600px) {
  .entry-item {}
}
</style>