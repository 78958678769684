<template>
  <div class="companion-item">
    <div class="data-box">
      <div class="row">
        <div class="col-md-10 data-middle">
          <ImageItem :path="companion.image_photo" :isSmall="false"></ImageItem>

          <div class="middle-infos">
            <p class="highlight">Nome: {{ companion.name }} {{ companion.lastname }}</p>
            <!-- <p>Telefone: {{companion.phone | formatPhone}}</p> -->
            <p>E-mail: {{ companion.email }}</p>
            <p>CPF: {{ companion.cpf | formatCpf }}</p>
            <p>Registro: {{ companion.created_at | formatDate }}</p>
            <!-- <p>Status: <span class="highlight" v-bind:class="userStatusClass(companion.status)">{{userStatus(companion.status)}}</span></p> -->
          </div>
        </div>
        <div class="col-md-2 data-right" v-bind:class="{ 'data-right-bigger': showRemove || showAdd }">
          <div class="" v-bind:class="{ 'left-part ': showRemove || showAdd }"></div>

          <div class="data-right-btn data-right-btn-orange" v-if="showRemove" @click="removeCompanion($event)">
            <i class="fas fa-times"></i>
          </div>
          <div class="data-right-btn data-right-btn-blue" v-if="showAdd" @click="addCompanion($event)">
            <i class="fas fa-check"></i>
          </div>
        </div>
      </div>

      <overlay-item :route="'/companions/' + companion.idcompanion" :small="showRemove || showAdd"></overlay-item>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["companion", "showRemove", 'showAdd'],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() {
    // console.log('companion', this.companion)

  },
  watch: {},
  methods: {
    removeCompanion(event) {
      let self = this
      if (event.stopPropagation) event.stopPropagation();
      self.$emit("companionRemove", this.companion);
    },
    addCompanion(event) {
      let self = this
      if (event.stopPropagation) event.stopPropagation();
      self.$emit("companionAdd", this.companion);
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.companion-item {
  padding: 0px;

  .data-box {
    padding: 0px;
    background-color: $white !important;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
    }
  }

  .data-middle {
    height: 105px;
  }

  .image-box {
    height: 105px;
  }

}

@media only screen and (max-width: 600px) {
  .companion-item {}
}
</style>