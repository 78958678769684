<template>
  <main-layout>
    <div class="content-container">
      <div id="user-register" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Pessoa</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteUserMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/users/new') == -1 && isSecurityAdm()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && user.iduser == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveUserMixin(user, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/users/new') == -1 && isSecurityAdm() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveUserMixin(user, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/users/new') != -1 && isSecurityAdm()">Registrar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                usuário</button>
              <button class="app-btn right-btn" @click="changeTab('parks')"
                v-bind:class="{ 'app-btn-primary ': tab == 'parks', 'app-btn-outline ': tab != 'parks' }"
                v-if="this.$route.fullPath.indexOf('users/new') == -1">Parques associados</button>
            </div>
          </div>
        </div>
        <!-- <div class="row fix-right-margin" v-if="tab == 'base'"> -->
        <ValidationObserver ref="validator" class="row fix-right-margin" v-if="tab == 'base'">
          <div class="col-md-3 row">
            <div class="col-md-12">
              <!-- <image-upload v-model="user.image_photo"></image-upload> -->
              <image-upload v-model="user.image_photo" @uploaded="saveUserMixin(user, $route.params.id, true)"
                @deleted="saveUserMixin(user, $route.params.id, true)" v-if="isSecurityAdm()"></image-upload>
              <ImageItem :path="user.image_photo" v-else></ImageItem>
            </div>
          </div>
          <div class="col-md-9 row">
            <div class="col-md-6">
              <ok-input :model="user.name" @updated="(x) => {
                user.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.lastname" @updated="(x) => {
                user.lastname = x;
              }
                " :label="'Sobrenome'" :placeholder="' '" :id="'lastname'" :name="'lastname'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.email" @updated="(x) => {
                user.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'email'" :name="'email'" rules="required|email">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.phone" @updated="(x) => {
                user.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'phone'" :name="'phone'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.birthday" @updated="(x) => {
                user.birthday = x;
              }
                " :label="'Nascimento'" :placeholder="' '" :id="'birthday'" :name="'birthday'" :mask="'##/##/####'"
                :isDate="true">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.gender" @updated="(x) => {
                user.gender = x;
              }
                " :label="'Sexo'" :options="genderOptions" :placeholder="' '" :id="'gender'" :name="'gender'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="user.job" @updated="(x) => {
                user.job = x;
              }
                " :label="'Função'" :options="getJobOpbtionsCreate()" :placeholder="' '" :id="'job'" :name="'job'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6" v-if="user.job === '20' || user.job === '21'">
              <div class="select-with-label">
                <v-select label="name" v-model="user.idcompany" :options="companyList"
                  :reduce="(company) => company.idcompany" placeholder="Seleciona a empresa"></v-select>
                <label>Empresa</label>
              </div>
            </div>
            <div class="col-md-6" v-if="user.job === '40' || user.job === '41'">
              <div class="select-with-label">
                <v-select label="name" v-model="user.idcompany" :options="thirdCompanyList"
                  :reduce="(company) => company.idcompany" placeholder="Seleciona o terceiro"></v-select>
                <label>Terceiro</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="toggle-item" style="margin-top: 8px;">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="user.status" :sync="true" />
              </div>
            </div>
            <div class="" v-bind:class="user.job === '20' || user.job === '21' ? 'col-md-6 last' : 'col-md-12 last'">
              <button class="app-btn app-btn-secondary" v-if="user.iduser" @click="forgotPassword()"
                style="margin-top: 8px;">Reenviar email de alteração de senha</button>
            </div>
          </div>
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h1 class="title-md">Endereço</h1>
          </div>
          <div class="col-md-12 row">
            <address-form :item="user"></address-form>
          </div>
          <!-- </div> -->
        </ValidationObserver>

        <div class="row fix-right-margin" v-if="tab == 'parks'">
          <div class="col-md-12 row" v-if="tab2 == 'list'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab2 = 'select'" v-if="isSecurityAdm()">Associar
                parque</button>
            </div>
            <div class="col-md-12" v-for="park in user.Parks" :key="'r' + park.idpark">
              <park-item :parkItem="park" :showRemove="isGlpMember()" @done="loadUser"></park-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="user.Parks && user.Parks.length == 0">
                Nenhum parque associado ao usuário
              </div>
            </div>
          </div>

          <div class="col-md-12 row" v-if="tab2 == 'select'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="tab2 = 'list'" v-if="isSecurityAdm()">Listar
                parques</button>
            </div>
            <div class="col-md-9" style="width: calc(100% - 175px);">
              <ok-input :model="filter.searchText" @updated="(x) => {
                filter.searchText = x;
              }
                " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="listParks">
              </ok-input>
            </div>
            <div class="col-md-3" style="width: 175px;">
              <button class="app-btn app-btn-text" style="margin-top: 9px" @click="cleanFilter()">Limpar
                resultados</button>
            </div>
            <div class="col-md-12" v-for="park in parksListFiltered(user.Parks)" :key="park.idpark">
              <park-item :parkItem="park" :showAdd="isGlpMember()" @done="loadUser"></park-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="parkList && parkList.length == 0">
                Nenhum parque encontrado
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import userMixin from "../mixins/user.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import ParkItem from '@/components/ParkItem.vue'
import AddressForm from '@/components/AddressForm.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [authMixin, userMixin, parkMixin, companyMixin, thirdCompanyMixin],
  components: {
    MainLayout,
    OkInput,
    ParkItem,
    AddressForm,
    ImageUpload,
    ImageItem,
  },
  data() {
    return {
      // person: {
      //   status: false,
      //   job: null,
      //   street: null,
      //   state: null,
      //   city: null,
      //   district: null,
      //   range: null,
      // },
      filter: {
        searchText: null,
      },
      parkList: [],
      tab: "base",
      tab2: "list",
      companyList: [],
      thirdCompanyList: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadUser();
      this.listParks();
    }

    this.listCompanies();
    this.listThirdCompanies();
  },
  computed: {
    listFiltered: function () {
      if (this.filter.searchText == null) return this.parkList;
      const search = this.filter.searchText.toLowerCase().trim();

      if (!search) return this.parkList;

      return this.parkList.filter(p => p.name.toLowerCase().indexOf(search) > -1);

    }
  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadUser() {
      let self = this;
      const id = this.$route.params.id;
      this.getUserMixin(id).then((response) => {
        self.user = response.user;
      });
    },
    listParks() {
      let self = this;
      this.listParksMixin(500).then((response) => {
        self.parkList = response.parkList;
      });
    },
    listCompanies() {
      let self = this;
      this.listCompaniesMixin(500).then((response) => {
        self.companyList = response.companyList;
      });
    },
    listThirdCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500).then((response) => {
        self.thirdCompanyList = response.thirdList
      });
    },
    forgotPassword() {
      let self = this;
      this.forgotPasswordEmployeeMixin(self.user.email);
    },
    changeTab(value) {
      if (value != 'parks') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#user-register {}
</style>