<template>
  <main >
    
    <div class="content container-fluid"> 
      <div class="row">
        <div class="col-md-12 no-padding">
          <Header/>
        </div>
        <div class="col-md-2 no-padding template-left">
          <Sidebar/>
          <SidebarHide/>
        </div>
        <div class="col-md-10 template-right">
          <div>
            <slot></slot>
          </div>
        </div>
        <div class="col-md-12 no-padding">
          <Footer/>
        </div>
      </div>
      <FloatingWhatsApp/>
    </div>
    <!-- <div class="content container-fluid-mobile d-sm-none "> 
      <div class="row">
        <div class="col-md-12 no-padding">
          <Header/>
        </div>
        <div class="col-md-2 no-padding">
          <SidebarHide/>
        </div>
        <div class="col-md-10">
          <div>
            <slot></slot>
          </div>
        </div>
      </div>
      
    </div> -->
    <load-bar></load-bar>
    <!-- <hr/> -->
    
  </main>
    
</template>

<script>
  import Header from '../components/Header.vue'
  import Sidebar from '../components/Sidebar.vue'
  import SidebarHide from '../components/SidebarHide.vue'
  import Footer from '../components/Footer.vue'
  import LoadBar from "../components/LoadBar.vue";
  import FloatingWhatsApp from '../components/FloatingWhatsApp.vue'

  export default {
    components: {
      Header,
      Sidebar,
      SidebarHide,
      LoadBar,
      Footer,
      FloatingWhatsApp
    },
    data () {
      return {
        sliderPage: -1,
      }
    },
    
  }
</script>

<style lang="scss">
@import "../scss/reset.scss";
@import "../scss/colors.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../scss/fonts.scss";


.list {
  h3{
    font-size: 14pt;
    text-align: left;
  }
}

.template-left{
  width: 210px;
}

.template-right{
  width: calc(100% - 210px);
}

@media only screen and (max-width: 600px) {
  
.template-left{
  width: 100%;
}

.template-right{
  width: auto;
  padding: 0px;
}
}
</style>
