export const localPath = '/'

// const url_local = "http://167.172.195.160:4040";
// const url_dev = "https://63d0770072db.ngrok.io"
// const url_server = "https://glp.oceantek.com.br"
// const url_prod = "http://glpfastpass.com"

const url = process.env.VUE_APP_BASE_URL;
const mediaUrl = process.env.VUE_APP_MEDIA_URL;

export const local = {
  user: {},
  auth: {},
}

export const server = {
  url: url + "/api",
  api_url: url + "/api",
  base_url: url + "/api", //prod
  image_url: url + "/public/", //dev
  media_url: `${mediaUrl}/glp/image/`,
  web_services: url + "/api", //prod

  config: {
    headers: {
      Authorization: "",
      Device: ""
    },
    params: {

    }
  }
}
export const settings = {
  some: 'Settings'
}