import axios from 'axios';
import { getLocalStorage } from "../js/encryption";

axios.interceptors.request.use(async (config) => {
  if (!config.url.endsWith('load')) {
    // console.log("Interceptor - request " + config.url);
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + getLocalStorage('token'),
        Device: getLocalStorage('device')
      }
    } else {
      config.headers.Device = getLocalStorage('device')
    }
  } else {
    console.log("Interceptor - load");
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + getLocalStorage('token'),
        Device: getLocalStorage('device')
      }
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.open("/login", "_self");
    }
    // console.log(config);

  }
  // console.log("Interceptor - ok ");
  return config;
}, (error) => {
  // I cand handle a request with errors here
  return Promise.reject(error);
});



/*

axios.interceptors.request.use(async (config) => {
if (config.url.indexOf("cloromt.com.br") == -1) {
  console.log("RD Station", config);
  return config;
}

if ( !config.url.endsWith('load')  ) {
  // console.log("Interceptor - request " + config.url);
  if (localStorage.token != null) {
    config.headers = {
      Authorization: 'Bearer ' + localStorage.token,
      Device: localStorage.device
    }
  } else {
    // console.log("Interceptor - error ");
    // return Promise.reject(error);
    return;
  }
} else {
  // console.log("Interceptor - load");
  if (localStorage.token != null) {
    config.headers = {
      Authorization: 'Bearer ' + localStorage.token,
      Device: localStorage.device
    }
  } else {
    config.headers = { 
      Device: localStorage.device
    }
  }
  // console.log(config);

}
// console.log("Interceptor - ok ");
return config;
}, (error) => {
// I cand handle a request with errors here
return Promise.reject(error);
});
s
*/