<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['data'],
  data(){
      return {
            chartdata: {
                labels: ['January', 'February'],
                datasets: [
                    {
                    label: 'Data One',
                    backgroundColor: '#f87979',
                    data: [40, 20]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
      }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
   watch: {
       data() {
           this.renderChart(this.data, this.options)
       }
   }
}
</script>

<style>
</style>