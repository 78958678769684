<template>
  <main-layout>
    <div id="login">
      <div class="row">
        <div class="col-md-4 left-part" v-if="showLogin">
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h2>Login</h2>

          <ok-input :model="user.email" @updated="(x) => {
            user.email = x;
          }
            " :label="'E-mail'" :placeholder="'nome@email.com'" :id="'email'" :name="'email'">
          </ok-input>

          <div class="password">
            <ok-input :model="user.password" @updated="(x) => {
              user.password = x;
            }
              " :label="'Password'" :placeholder="' '" :type="passwordInputType" :id="'password'"
              :name="'password'" @onEnter="loginUser()">
            </ok-input>
            <button @click="showHidePassword()" :class="{ 'hidden': !verifyPassword() }">
              <i class="far fa-eye"></i>
              <!-- <i class="far fa-eye-slash" v-else></i> -->
              <!-- <i class="far fa-eye" v-if="passwordInputType == 'password'"></i>
              <i class="far fa-eye-slash" v-if="passwordInputType == 'text'"></i> -->
            </button>
            <button @click="showHidePassword()" :class="{ 'hidden': verifyPassword() }">
              <i class="far fa-eye-slash"></i>
              <!-- <i class="far fa-eye-slash" v-else></i> -->
              <!-- <i class="far fa-eye" v-if="passwordInputType == 'password'"></i>
              <i class="far fa-eye-slash" v-if="passwordInputType == 'text'"></i> -->
            </button>
          </div>

          <button class="app-btn app-btn-text forgot-password-btn" @click="showLogin = false">Esqueci a senha</button>
          <button class="app-btn app-btn-primary login-btn" id="login-btn" @click="loginUser()">Entrar</button>
          <hr class="divider">
          <button class="app-btn app-btn-text" id="login-btn-driver" @click="goTo('/visitante/login')">Acessar como visitante</button>
        </div>
        <div class="col-md-4 left-part-forgot" v-else>
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h2>Esqueci a senha</h2>
          <p style="text-align: center; font-size: 13px">Informe seu email para enviarmos instruções</p>
          <ok-input :model="userForgot.emailToSend" @updated="(x) => {
            userForgot.emailToSend = x;
          }
            " :label="'E-mail'" :placeholder="'nome@email.com'" :id="'emailToSend'" :name="'emailToSend'">
          </ok-input>

          <ok-input :model="userForgot.emailToSendConfirm" @updated="(x) => {
            userForgot.emailToSendConfirm = x;
          }
            " :label="'Confirmar e-mail'" :placeholder="'nome@email.com'" :id="'emailToSendConfirm'"
            :name="'emailToSendConfirm'" @onEnter="emailToSend()">
          </ok-input>

          <button class="app-btn app-btn-text forgot-password-btn" @click="showLogin = true">Voltar</button>
          <button class="app-btn app-btn-primary login-btn" @click="forgotPassword()">Enviar</button>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/glp-main.png" alt="GLP img"> -->
          <div class="image-box" style="background-image: url(&quot;./static/img/glp-main.png&quot;);"></div>
        </div>
      </div>
    </div>
    <!-- <alert-message message="Seja bem vindo" type="success"></alert-message> -->

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import MainLayout from "../layouts/Main.vue";
import OkInput from '@/components/OkInput.vue'
// import AlertMessage from '@/components/AlertMessage.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin],
  components: {
    MainLayout,
    OkInput,
    // AlertMessage,
  },
  data() {
    return {
      slideList: [],
      user: {
        email: "",
        password: "",
      },
      userForgot: {
        emailToSend: '',
        emailToSendConfirm: '',
      },
      passwordInputType: 'password',
      showLogin: true,
    };
  },
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    this.isUuid()
  },
  methods: {
    loginUser() {
      const self = this;
      this.login(self.user).then((response) => {
        if (response.iduser) {
          this.$toasted.show('Seja bem vindo');
          this.$router.push('/dashboard');
        }
      });
    },
    forgotPassword() {
      const self = this;
      this.forgotPasswordMixin(this.userForgot.emailToSend).then((response) => {
        console.log(response);
        self.userForgot.emailToSend = '';
        self.userForgot.emailToSendConfirm = '';

      });
    },
    // showHidePassword(){
    //   this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    // },
    showHidePassword() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
      this.$forceUpdate();
    },
    verifyPassword() {
      let a = this.passwordInputType === 'password' ? true : false
      return a
    },
    isUuid() {
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi //regex to verify if is uuid
      const device = getLocalStorage('device')
      if (!device || !regexExp.test(device)) {
        localStorage.removeItem('device')
        window.open("./login", "_self")
      }
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#login {

  .left-part {
    background-color: $lightgray;
    padding: 0px 70px;
    height: 100vh;

    .divider{
      margin-top: 120px;
      margin-bottom: 60px;

    }

    .logo {
      height: 45px;
      margin-top: 90px;
      margin-bottom: 90px;
    }

    h2 {
      text-align: center;
      margin-bottom: 14px;
    }

    .password {
      position: relative;

      button {
        position: absolute;
        right: 5px;
        top: 9px;
        padding: 8px;
        background-color: transparent;
        border: none;
        border-radius: 0px;
        color: var(--main-text-color);
        width: auto;
      }
    }

    .login-btn {
      float: right;
      margin-top: 15px;
    }

    .forgot-password-btn {
      float: left;
      margin-top: 15px;
    }
    
    #login-btn-driver {
      // margin-top: 50px;
      width: 100%;
      text-decoration: underline;
    }
  }

  .left-part-forgot {
    background-color: $lightgray;
    padding: 0px 70px;
    height: 100vh;

    .logo {
      height: 45px;
      margin-top: 90px;
      margin-bottom: 90px;
    }

    h2 {
      text-align: center;
      margin-bottom: 14px;
    }

    .password {
      position: relative;

      button {
        position: absolute;
        right: 5px;
        top: 9px;
        padding: 8px;
        background-color: transparent;
        border: none;
        border-radius: 0px;
        color: var(--main-text-color);
        width: auto;
      }
    }

    p {
      text-align: center;
      font-size: 13px;
      margin-bottom: 10px;
    }

    .login-btn {
      float: right;
      margin-top: 15px;
    }

    .forgot-password-btn {
      float: left;
      margin-top: 15px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}
</style>