<template>
  <main-layout>
    <div class="content-container">
      <div id="report-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Reporte</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space separator-bottom" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && report.idreport == undefined">Voltar</button>
          </div>
        </div>
        <div class="row" v-if="report.Request">
          <div class="col-md-4">
            <div class="data-box" style="padding: 18px 15px;">
              <div class="row">
                <div class="col-md-12 toggle-photo">
                  <ImageItem :path="report.Request.Driver.image_photo" v-if="photoType"></ImageItem>
                  <ImageItem :path="report.Request.Driver.image_document" v-else></ImageItem>
                </div>
                <div class="col-md-12 last separator-bottom toggle-photo" style="margin-top: 6px;">
                  <button class="left" v-bind:class="{ 'active': photoType }" @click="photoType = true">Foto</button>
                  <button class="right" v-bind:class="{ 'active': !photoType }" @click="photoType = false">CNH</button>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 style="margin-bottom: 10px">{{ report.Request.Driver.name }} {{ report.Request.Driver.lastname }}
                  </h2>
                  <p>Telefone: {{ report.Request.Driver.phone | formatPhone }}</p>
                  <p>E-mail: {{ report.Request.Driver.email }}</p>
                  <p>CPF: {{ report.Request.Driver.cpf | formatCpf }}</p>
                  <p>CNH: {{ report.Request.Driver.cnh }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 class="highlight" v-if="report.Request.Driver.company_name">{{ report.Request.Driver.company_name
                  }}
                  </h2>
                  <hp v-else>Empresa: -</hp>
                  <p>CNPJ: {{ report.Request.Driver.company_cnpj | formatCnpj | verifyIfNull }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <div class="data-box row">
                  <div class="col-md-6">
                    <p><span class="highlight">{{ report.Request.Company.name }} </span></p>
                    <p>{{ report.Request.Park.name }}</p>
                    <p><span class="highlight">Data de solicitação: </span>{{ report.Request.created_at | formatDate }}
                    </p>
                  </div>
                  <div class="col-md-6 last">
                    <p><span class="highlight">{{ report.ReportType.name }}</span></p>
                    <p><span class="highlight">Data: </span>{{ report.created_at | formatDate }}</p>
                    <p><span class="highlight">Hora: </span>{{ report.created_at | getHour }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h2 class="title-sm separator-title">Veículo</h2>
                <vehicle-item :vehicle="report.Request.Vehicle"></vehicle-item>
              </div>
              <div class="col-md-12" @click="goTo('/users/' + report.User.iduser)">
                <h2 class="title-sm separator-title">Segurança</h2>
                <user-item :userItem="report.User" :simple="true"></user-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import reportMixin from "../mixins/report.mixin.js";
import MainLayout from "../layouts/Template.vue";
import VehicleItem from '@/components/VehicleItem.vue'
import UserItem from '@/components/UserItem.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [reportMixin],
  components: {
    MainLayout,
    VehicleItem,
    UserItem,
    ImageItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadEntry();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadEntry() {
      let self = this;
      const id = this.$route.params.id;
      this.getReportMixin(id).then((response) => {
        self.report = response.report;
      });
    },
    changeTab(value) {
      if (value != 'parks') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#report-view {}
</style>