<template>
  <main-layout>
    <div class="content-container">
      <div id="third-company-list" class="content-box">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title-md">Terceiros</h1>
          </div>
          <div class="col-md-6 last">
            <button class="app-btn app-btn-primary" @click="goTo('/third-companies/new')" v-if="isGlpMember()">Novo
              registro</button>
          </div>
          <div class="col-md-12 search-with-btn" style="margin-top: 10px">
            <ok-input :model="filter.searchText" @updated="(x) => {
              filter.searchText = x;
            }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="applyFilter">
            </ok-input>
            <button class="app-btn app-btn-primary" @click="applyFilter">Buscar</button>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.status" :options="statusOptions2" :reduce="(status) => status.value"
                placeholder="Todos" @input="applyFilter"></v-select>
              <label>Filtrar status</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.idcompany" :options="companyList"
                :reduce="(company) => company.idcompany" @input="applyFilter"
                placeholder="Selecione o locatário"></v-select>
              <!-- <v-select label="name" v-model="filter.idpark" :options="parkList" :reduce="(park) => park.idpark"
                @input="applyFilter" placeholder="Selecione o locatário"></v-select> -->
              <label>Locatário</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="select-with-label">
              <v-select label="name" v-model="filter.order" :options="orderOptions" :reduce="(order) => order.value"
                placeholder="Mais recentes" @input="applyFilter"></v-select>
              <label>Ordernar por</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-9" v-if="filter.searchingText">
            <p style="line-height: 36px;">Mostrando resultados para "{{ filter.searchingText }}"</p>
          </div>
          <div class="col-md-3 last separator-bottom" v-if="filter.searchingText">
            <button class="app-btn app-btn-cancel" @click="removeSearchText()">Mostrar todos</button>
          </div>
          <div class="col-md-12" v-for="company in thirdCompanyList" :key="company.iduser">
            <ThirdCompanyItem :thirdCompanyItem="company"></ThirdCompanyItem>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="thirdCompanyList && thirdCompanyList.length == 0">
              <p>{{ noContentText }}</p>
            </div>
            <button class="app-btn app-btn-load-more"
              v-if="thirdCompanyList && thirdCompanyList.length > 0 && filter.canLoadMore"
              @click="listThirdCompanies()">Carregar mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import ThirdCompanyItem from '@/components/ThirdCompanyItem.vue'

export default {
  mixins: [authMixin, companyMixin, thirdCompanyMixin, parkMixin],
  components: {
    MainLayout,
    OkInput,
    ThirdCompanyItem,
  },
  data() {
    return {
      companyList: [],
      thirdCompanyList: [],
      parkList: [],
      filter: {
        pageItems: 15,
        page: 0,
        searchText: null,
        searchingText: null,
        order: null,
        sort: null,
        status: null,
        idpark: null,
        canLoadMore: true,
      },
      noContentText: 'Buscando empresas...',
    };
  },
  mounted() {
    this.listCompanies()
    this.listThirdCompanies()
    this.listParks()
  },
  methods: {
    listThirdCompanies() {
      let self = this;
      let status = self.filter.status;
      if (status == '3') status = null;
      let sort = this.fixSort(self.filter.order);
      let order = this.fixOrder(self.filter.order);
      this.noContentText = 'Buscando empresas...';

      this.listThirdCompaniesMixin(self.filter.pageItems, self.filter.page, self.filter.searchText, order, sort, status, self.filter.idpark).then((response) => {
        if (self.filter.page == 0) {
          self.thirdCompanyList = response.thirdList;
        } else {
          self.thirdCompanyList = self.thirdCompanyList.concat(response.thirdList);
        }
        if (response.thirdList.length != self.filter.pageItems) {
          self.filter.canLoadMore = false;
        } else {
          self.filter.canLoadMore = true;
        }
        self.filter.page++

        if (self.filter.searchText) {
          self.filter.searchingText = self.filter.searchText;
        } else {
          self.filter.searchingText = null;
        }
        self.noContentText = 'Nenhuma empresa para exibir';
      });
    },
    applyFilter() {
      this.filter.page = 0;
      this.listThirdCompanies()
    },
    removeSearchText() {
      this.removeSearchTextMixin();
      this.applyFilter();
    },
    listCompanies() {
      let self = this;
      this.listCompaniesMixin(500, null, null, 'ASC', 'name').then((response) => {
        self.companyList = response.companyList;

      });
    },
    listParks() {
      let self = this;
      this.listParksMixin(500).then((response) => {
        self.parkList = response.parkList;
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#third-company-list {}
</style>