import Vue from 'vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import JsonCSV from 'vue-json-csv'
import VueScrollTo from 'vue-scrollto';
import JsonViewer from 'vue-json-viewer'

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  });

Vue.component('downloadCsv', JsonCSV)
Vue.use(JsonViewer)