<template>
  <div :class="{ 'image-box-large ': !isSmall, 'image-box': isSmall, 'full-image': isFull }" :style="'background-image: url(' + imageUrl + ')'"
    v-if="imageUrl"></div>
  <div :class="{ 'image-box-empty-large': !isSmall, 'image-box-empty': isSmall, 'full-image-empty': isFull }" v-else></div>
</template>

<script>

export default {
  props: {
    path: {
      required: true,
    },
    isSmall: {
      required: false,
      default: false
    },
    isFull: {
      required: false,
      default: false
    },
    isCompanion: {
      required: false,
      default: false
    },
  },
  data() {
    return {
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      imageUrl: ''
    };
  },
  watch: {
    path() {
      this.imageUrl = this.path
    },
  },
  mounted() {
    if (this.path !== undefined) this.imageUrl = this.path
  },
  methods: {
    // checkImage() {
    //   const self = this
    //   if (!this.path) {
    //     this.imageUrl = null
    //     return
    //   }
    //   this.axios.get(this.getImageUrl(this.path))
    //     .then(function () {
    //       self.imageUrl = self.getImageUrl(self.path)
    //     })
    //     .catch(function () {
    //       self.imageUrl = self.getMediaUrl(self.path)
    //     });
    // }
  },
};
</script>

<style lang="scss" scoped>
.image-box-empty-bigger {
  height: 105px;
}

.full-image{
  width: 100%;
  border-radius: initial;
  margin: 0px;
  height: 255px;
}

.full-image-empty{
  width: 100%;
  border-radius: initial;
  margin: 0px;
  height: 255px;
}

@media only screen and (max-width: 600px) {
  .image {}
}
</style>