<template>
  <div class="driver-quest-view-item">
    <div class="request-infos">
      <div class="top" :class="{ 'with-qrcode': isAllOk }">
        <div v-if="isAllOk && qrCodeData" class="qr-code">
          <img :src="qrCodeData" alt="QR Code" />
        </div>
        <i class="far fa-clock clock-icon" v-else></i>
        <div class="infos">
          <p>{{ request.Park.name }}</p>
          <p class="dark-text company-name">
            {{ request.Company.name }}
          </p>
          <p>Data: {{ request.day | formatDate }}</p>
          <p>Entrada: {{ request.hour_min }} - {{ request.hour_max }}</p>
        </div>
      </div>
      <hr class="divider" v-if="!isAllOk" />
      <div class="bottom" v-if="!isAllOk">
        <p>
          Solicitação de acesso aguardando aprovação pela equipe do(a)
          {{ request.Company.name }}
        </p>
      </div>
    </div>
    <div class="request-status" v-if="!isAllOk">
      <div class="item">
        <p>Status do cadastro</p>
        <p class="status" :class="statusRequestColorMap[status.register]">
          {{ statusRegisterMap[status.register] }}
        </p>
      </div>
      <hr class="divider" />
      <div class="item">
        <p>Status da solicitação</p>
        <p class="status" :class="statusRequestColorMap[status.request]">
          {{ statusRegisterMap[status.request] }}
        </p>
      </div>
      <hr class="divider" />
      <div class="item">
        <p>Proximidade local</p>
        <p class="status" :class="statusColorMap[status.proximity]">
          {{ statusProximityMap[status.proximity] }}
        </p>
      </div>
      <hr class="divider" />
      <div class="item">
        <p>Horário de entrada</p>
        <p class="status" :class="statusColorMap[status.hour]">
          {{ statusHourMap[status.hour] }}
        </p>
      </div>
    </div>

    <button
      class="app-btn app-btn-primary get-in-btn"
      id="get-in-btn"
      @click="toggleBlink()"
      v-if="isAllOk"
    >
      Aperte aqui para adentrar a eclusa
    </button>
    <div class="blink-overlay" v-if="isBlinking" @click="toggleBlink()">
      <p>Toque na tela para parar</p>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      required: true,
    },
    isReady: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      qrCodeData: null,
      isAllOk: false,
      isBlinking: false,
      statusRegisterMap: {
        '-1': 'Rejeitado',
        1: 'Esperando',
        2: 'Aprovado',
      },
      statusRequestColorMap: {
        '-1': 'red',
        1: 'orange',
        2: 'green',
      },
      statusProximityMap: {
        0: 'Distante',
        1: 'No local',
      },
      statusHourMap: {
        '-1': 'Atrasado',
        0: 'Esperando',
        1: 'Ativo',
        2: 'Somente saída',
      },
      statusColorMap: {
        '-1': 'red',
        0: 'orange',
        1: 'green',
        2: 'green',
      },
    }
  },
  mounted() {
    this.initializeComponent()
  },
  methods: {
    initializeComponent() {
      this.updateStatus()
      this.status.hour = this.canEnterHour()
      this.checkIsOk()
    },
    updateStatus() {
      this.status.register = this.request.registration.status
      this.status.request = this.request.status
    },
    canEnterHour() {
      if (!this.request.hour_min || this.request.hour_min === '') {
        this.request.hour_min = this.request.hour
      }

      if (!this.request.hour_max || this.request.hour_max === '') {
        this.request.hour_max = this.request.hour
      }

      const day = this.request.day.substring(0, 10)
      let dayMin = `${day} ${this.request.hour_min}`
      let dayMax = `${day} ${this.request.hour_max}`

      let dateMin = new Date(dayMin)
      let dateMax = new Date(dayMax)

      if (dateMax < dateMin) dateMax.setDate(dateMax.getDate() + 1)

      const addHour = 12 * 60 * 60 * 1000
      const dateExit = new Date(dateMax.getTime() + addHour)
      const now = new Date()

      if (dateMin > now) return 0
      if (dateMin <= now && dateMax > now) return 1
      if (dateMax <= now && dateExit > now) return 2
      return -1
    },
    async generateQRCode() {
      if (this.request) {
        try {
          this.qrCodeData = await QRCode.toDataURL(this.request.qr_code)
        } catch (error) {
          console.error('Error generating QR code:', error)
          this.qrCodeData = null
        }
      }
    },
    async checkIsOk() {
      if (
        this.status.register === 2 &&
        this.status.request === 2 &&
        this.status.proximity === 1 &&
        (this.status.hour === 1 || this.status.hour === 2)
      ) {
        await this.generateQRCode()
        this.isAllOk = true
      }
    },
    toggleBlink() {
      this.isBlinking = !this.isBlinking
    },
  },
  watch: {
    request: {
      handler() {
        this.initializeComponent()
      },
      deep: true,
    },
    isReady: {
      handler() {
        this.checkIsOk()
      },
    },
  },
}
</script>

<style lang="scss">
@import '../scss/colors.scss';

.blink-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: blink 1.2s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  p {
    color: white;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }
}

@keyframes blink {
  0%,
  100% {
    background-color: $primary;
  }
  50% {
    background-color: black;
  }
}

.driver-quest-view-item {
  display: grid;
  gap: 20px;

  .request-infos {
    padding: 15px 20px;
    background-color: $white !important;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 1px 4px -3px #000000;

    .top {
      display: flex;
      align-items: center;
      gap: 20px;

      &.with-qrcode {
        flex-direction: column;
        gap: 30px;

        .infos {
          text-align: center;
        }
      }

      .clock-icon {
        color: $gray-dk;
        font-size: 80px;
      }

      .infos {
        color: $span-color;

        .company-name {
          font-weight: 600;
          font-size: 20px;
          padding-block: 8px;
        }
      }
    }

    .dark-text {
      color: $text-color;
    }
  }

  .request-status {
    padding: 15px 20px;
    background-color: $white !important;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 1px 4px -3px #000000;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .status {
        font-weight: 700;

        &.orange {
          color: $orange;
        }

        &.green {
          color: $green;
        }

        &.red {
          color: $red;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .driver-quest-view-item {
  }
}
</style>
