<template>
  <div class=" popup" @click="closePopup()" v-if="localVisible">
    <div class="popup-content" @click.stop="">
      <p class="title">{{ title }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import parkMixin from "../mixins/park.mixin.js";
import companyMixin from "../mixins/company.mixin.js";

export default {
  mixins: [parkMixin, companyMixin],
  props: ['isVisible', 'title'],
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    localVisible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      }
    }
  },
  mounted() {
  },
  watch: {},
  methods: {
    closePopup() {
      this.localVisible = false
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.popup {
  position: fixed;
  background-color: #00000050;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-content {
    background-color: white;
    padding: 35px 40px;
    border-radius: 12px;

    .title {
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .popup {}
}
</style>