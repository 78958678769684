<template>
  <main-layout>
    <div class="content-container">
      <div id="driver-register" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Motorista</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteDriverMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && isAdministrator()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && driver.iddriver == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveDriverMixin(driver, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && isThirdMember() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveDriverMixin(driver, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/drivers/new') != -1 && isThirdMember()">Registrar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                motorista</button>
              <button class="app-btn right-btn" @click="changeTab('vehicles')"
                v-if="this.$route.fullPath.indexOf('/drivers/new') == -1"
                v-bind:class="{ 'app-btn-primary ': tab == 'vehicles', 'app-btn-outline ': tab != 'vehicles' }">Veículos</button>
              <button class="app-btn left-btn" @click="changeTab('companion')"
                v-if="this.$route.fullPath.indexOf('/drivers/new') == -1"
                v-bind:class="{ 'app-btn-primary ': tab == 'companion', 'app-btn-outline ': tab != 'companion' }">Acompanhante</button>
              <button class="app-btn right-btn" @click="changeTab('registers')"
                v-if="this.$route.fullPath.indexOf('/drivers/new') == -1"
                v-bind:class="{ 'app-btn-primary ': tab == 'registers', 'app-btn-outline ': tab != 'registers' }">Cadastros</button>
              <button class="app-btn left-btn" @click="changeTab('requests')"
                v-if="this.$route.fullPath.indexOf('/drivers/new') == -1"
                v-bind:class="{ 'app-btn-primary ': tab == 'requests', 'app-btn-outline ': tab != 'requests' }">Solicitações</button>
              <button class="app-btn right-btn" @click="changeTab('entries')"
                v-if="this.$route.fullPath.indexOf('/drivers/new') == -1"
                v-bind:class="{ 'app-btn-primary ': tab == 'entries', 'app-btn-outline ': tab != 'entries' }">Acessos</button>
              <!-- <button class="app-btn left-btn" @click="changeTab('notifications')" v-bind:class="{ 'app-btn-primary ': tab == 'notifications', 'app-btn-outline ': tab != 'notifications' }">Notificações</button> -->
            </div>
          </div>
        </div>
        <div class="row fix-right-margin" v-if="tab == 'base'">
          <div class="col-md-3 row">
            <div class="col-md-12 toggle-photo">
              <ImageItem :path="driver.image_photo" :isFull="true" v-if="photoType"></ImageItem>
              <ImageItem :path="driver.image_document" :isFull="true" v-else></ImageItem>

              <button class="left" style="margin-top: 6px;" v-bind:class="{ 'active': photoType }"
                @click="photoType = true">Foto</button>
              <button class="right" style="margin-top: 6px;" v-bind:class="{ 'active': !photoType }"
                @click="photoType = false">CNH</button>
            </div>
          </div>
          <!-- <div class="col-md-9 row"> -->

          <ValidationObserver ref="validator" class="col-md-9 row">
            <div class="col-md-6">
              <ok-input :model="driver.name" @updated="(x) => {
                driver.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.lastname" @updated="(x) => {
                driver.lastname = x;
              }
                " :label="'Sobrenome'" :placeholder="' '" :id="'lastname'" :name="'lastname'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.email" @updated="(x) => {
                driver.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'email'" :name="'email'" rules="required|email">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.phone" @updated="(x) => {
                driver.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'phone'" :name="'phone'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.birthday" @updated="(x) => {
                driver.birthday = x;
              }
                " :label="'Nascimento'" :placeholder="' '" :id="'birthday'" :name="'birthday'" :isDate="true">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.gender" @updated="(x) => {
                driver.gender = x;
              }
                " :label="'Sexo'" :options="genderOptions" :placeholder="' '" :id="'gender'" :name="'gender'"
                style="margin-top: 4px;">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.cpf" @updated="(x) => {
                driver.cpf = x;
              }
                " :label="'CPF'" :mask="'###.###.###-##'" :placeholder="' '" :id="'cpf'" :name="'cpf'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="driver.cnh" @updated="(x) => {
                driver.cnh = x;
              }
                " :label="'CNH'" :mask="'###########'" :placeholder="' '" :id="'cnh'" :name="'cnh'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item" style="margin-top: 8px;">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="driver.status" :sync="true" />
              </div>
            </div>
            <div class="col-md-12" v-if="this.$route.fullPath.indexOf('/drivers/new') == -1">
              <h2 class="title-sm" style="margin-top: 10px; margin-bottom: 10px;">Empresa</h2>
            </div>
            <div class="col-md-6" v-if="this.$route.fullPath.indexOf('/drivers/new') == -1">
              <ok-input :model="driver.company_cnpj" @updated="(x) => {
                driver.company_cnpj = x;
              }
                " :label="'CNPJ'" :mask="'##.###.###/####-##'" :placeholder="' '" :id="'company_cnpj'"
                :name="'company_cnpj'">
              </ok-input>
            </div>
            <div class="col-md-6" v-if="this.$route.fullPath.indexOf('/drivers/new') == -1">
              <ok-input :model="driver.company_name" @updated="(x) => {
                driver.company_name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'company_name'" :name="'company_name'">
              </ok-input>
            </div>
            <div class="col-md-6" style="margin-top: 15px;"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && ((isThird && driver.idcompany === currentUser.Company.idcompany) || isAdministrator()) && driver.idcompany && (driver.third_driver_status === 2 || driver.third_driver_status === 0)">
              <ok-input :model="driver.idcompany" @updated="(x) => {
                driver.idcompany = x;
              }
                " :label="'Terceiro'" :options="thirdCompanyList" :placeholder="' '" :id="'idcompany'"
                :name="'idcompany'" style="margin-top: 4px;">
              </ok-input>
            </div>
            <div class="col-md-6" style="margin-top: 15px;"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && ((isThird && driver.idcompany === currentUser.Company.idcompany) || isAdministrator()) && driver.idcompany && (driver.third_driver_status === 2 || driver.third_driver_status === 0)">
              <div class="third-company-actions-binded">
                <span>Status</span>
                <button class="app-btn app-btn-orange" v-if="driver.third_driver_status === 0">Aguardando</button>
                <button class="app-btn app-btn-primary" v-else>Vinculado</button>
                <button class="app-btn app-btn-cancel" @click="unbindThird()"
                  v-if="isAdministrator() || (isThird && driver.idcompany === currentUser.Company.idcompany)">Desligar</button>
              </div>
            </div>
            <div class="col-md-6" style="margin-top: 15px;"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && (isThird || isAdministrator()) && (!driver.idcompany || driver.third_driver_status === -1 || driver.third_driver_status === 1)">
              <div class="third-company-actions-not-binded">
                <button class="app-btn app-btn-border" @click="prepareToBindThird()">{{ driver.third_driver_status === -1
                  ? 'Vincular' : 'É funcionário' }}</button>
                <button class="app-btn app-btn-cancel" @click="unbindThird()">Não
                  é funcionário</button>
              </div>
            </div>
            <!-- <div class="col-md-6" style="margin-top: 15px;"
              v-if="this.$route.fullPath.indexOf('/drivers/new') == -1 && !driver.idcompany && driver.idcompany !== currentUser.Company.idcompany && isThird">
              <p>Motorista vinculado</p>
            </div> -->
          </ValidationObserver>
          <!-- </div> -->
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h1 class="title-md">Endereço</h1>
          </div>
          <div class="col-md-12 row">
            <address-form :item="driver" :isPostalCode="true"></address-form>
          </div>
          <Popup :isVisible="showBindPopup" @update:isVisible="updateVisibility"
            :title="'Selecione a terceira para ser vinculada'" style="text-align: center;">
            <ok-input :style="'min-width: 250px'" :model="thirdToBind" @updated="(x) => {
              thirdToBind = x;
            }
              " :label="'Terceiro'" :options="thirdCompanyList" :placeholder="' '" :id="'thirdToBind'"
              :name="'thirdToBind'" style="margin-top: 4px;">
            </ok-input>
            <button class="app-btn app-btn-primary" @click="bindThird()">Selecionar</button>
          </Popup>
        </div>

        <div class="row" v-if="tab == 'vehicles'">
          <div class="col-md-12" v-for=" vehicle  in  driver.Vehicles " :key="vehicle.idvehicle">
            <vehicle-item :vehicle="vehicle"></vehicle-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="driver.Vehicles && driver.Vehicles.length == 0">
              Este motorista não cadastrou nenhum veículo.
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'companion'">
          <div class="col-md-12" v-for=" companion  in  driver.Companions " :key="companion.idcompanion">
            <companion-item :companion="companion"></companion-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="driver.Companions && driver.Companions.length == 0">
              Este motorista não cadastrou nenhum acompanhante.
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'registers'">
          <div class="col-md-12" v-for=" registration  in  registrationList " :key="registration.idregistration">
            <driver-registration-item :registrationItem="registration"></driver-registration-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="registrationList && registrationList.length == 0">
              {{ noContentRegistrationText }}
            </div>
            <button class="app-btn app-btn-load-more"
              v-if="registrationList && registrationList.length > 0 && filterDriver.canLoadMore"
              @click="listDriverRegistrations()">Carregar mais</button>
          </div>
        </div>

        <div class="row" v-if="tab == 'requests'">
          <div class="col-md-12" v-for=" request  in  requestList " :key="request.idrequest">
            <driver-request-item :requestItem="request"></driver-request-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="requestList && requestList.length == 0">
              {{ noContentRequestText }}
            </div>
            <button class="app-btn app-btn-load-more"
              v-if="requestList && requestList.length > 0 && filterRequest.canLoadMore"
              @click="listDriverRequests()">Carregar mais</button>
          </div>
        </div>

        <div class="row" v-if="tab == 'entries'">
          <div class="col-md-12" v-for=" entry  in  entryList " :key="entry.identry">
            <driver-entry-item :entryItem="entry"></driver-entry-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="entryList && entryList.length == 0">
              {{ noContentEntryText }}
            </div>
            <button class="app-btn app-btn-load-more" v-if="entryList && entryList.length > 0 && filterEntry.canLoadMore"
              @click="listDriverEntries()">Carregar
              mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import driverMixin from "../mixins/driver.mixin.js";
import userMixin from "../mixins/user.mixin.js";
import registrationMixin from "../mixins/registration.mixin.js";
import requestMixin from "../mixins/request.mixin.js";
import entryMixin from "../mixins/entry.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import VehicleItem from '@/components/VehicleItem.vue'
import CompanionItem from '@/components/CompanionItem.vue'
import DriverRegistrationItem from '@/components/DriverRegistrationItem.vue'
import DriverRequestItem from '@/components/DriverRequestItem.vue'
import DriverEntryItem from '@/components/DriverEntryItem.vue'
import AddressForm from '@/components/AddressForm.vue'
import Popup from '@/components/Popup.vue'
import { getLocalStorage } from "../js/encryption";
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [authMixin, driverMixin, userMixin, registrationMixin, requestMixin, entryMixin, thirdCompanyMixin],
  components: {
    MainLayout,
    OkInput,
    VehicleItem,
    CompanionItem,
    DriverRegistrationItem,
    DriverRequestItem,
    DriverEntryItem,
    AddressForm,
    Popup,
    ImageItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
      employee: {},
      parkList: [],
      registrationList: [],
      requestList: [],
      entryList: [],
      thirdCompanyList: [],
      thirdToBind: '',
      noContentRegistrationText: 'Buscando cadastros...',
      noContentRequestText: 'Buscando solicitações...',
      noContentEntryText: 'Buscando acessos...',
      filterDriver: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterRequest: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterEntry: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      showBindPopup: false,
      currentUser: null,
      isThird: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.currentUser = getLocalStorage('user')
    if (this.currentUser.job === '40' || this.currentUser.job === '41') this.isThird = true
    if (id !== 'new') {
      this.loadDriver();
      this.listThirdCompanies();
      // this.listParks();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadDriver() {
      let self = this;
      const id = this.$route.params.id;
      this.getDriverMixin(id).then((response) => {
        self.driver = response.driver;
      });
    },
    changeTab(value) {
      this.tab2 = 'list'
      this.tab = value;
      // console.log('changetab', value)
      if (value == 'registers') this.listDriverRegistrations();
      if (value == 'requests') this.listDriverRequests();
      if (value == 'entries') this.listDriverEntries();
      this.employee = {};
    },
    listEmployees() {
      this.tab2 = 'list'
      this.employee = {};
    },
    selectEmployee(employee) {
      this.employee = employee;
      this.tab2 = 'register'
    },
    listDriverRegistrations() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterDriver.canLoadMore) return
      this.noContentRegistrationText = 'Buscando cadastros...';
      this.listRegistrationsMixin(self.filterDriver.pageItems, self.filterDriver.page, null, null, null, null, null, null, null, id).then((response) => {
        if (self.filterDriver.page == 0) {
          self.registrationList = response.registrationList;
        } else {
          self.registrationList = self.registrationList.concat(response.registrationList);
        }
        if (response.registrationList.length != self.filterDriver.pageItems) {
          self.filterDriver.canLoadMore = false;
        } else {
          self.filterDriver.canLoadMore = true;
        }
        self.filterDriver.page++

        //remover mascara
        for (let registration of self.registrationList) {
          if (registration.Driver.phone.length > 11) {
            let phone = registration.Driver.phone.slice(4, 6);
            phone += registration.Driver.phone.slice(7, 17);
            registration.Driver.phone = phone;
          }
        }
        self.noContentRegistrationText = 'Nenhum cadastro para ser exibido.';
      });
    },
    listDriverRequests() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterRequest.canLoadMore) return
      this.noContentRequestText = 'Buscando solicitações...';

      this.listRequestsMixin(self.filterRequest.pageItems, self.filterRequest.page, null, null, null, null, null, null, null, id).then((response) => {
        if (self.filterRequest.page == 0) {
          self.requestList = response.requestList;
        } else {
          self.requestList = self.requestList.concat(response.requestList);
        }
        if (response.requestList.length != self.filterRequest.pageItems) {
          self.filterRequest.canLoadMore = false;
        } else {
          self.filterRequest.canLoadMore = true;
        }
        self.filterRequest.page++

        //remover mascara
        for (let request of self.requestList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentRequestText = 'Nenhuma solicitação para exibir';
      });
    },
    listDriverEntries() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterEntry.canLoadMore) return
      this.noContentEntryText = 'Buscando acessos...';

      this.listEntriesMixin(self.filterEntry.pageItems, self.filterEntry.page, null, null, null, null, null, null, id).then((response) => {
        if (self.filterEntry.page == 0) {
          self.entryList = response.entryList;
        } else {
          self.entryList = self.entryList.concat(response.entryList);
        }
        if (response.entryList.length != self.filterEntry.pageItems) {
          self.filterEntry.canLoadMore = false;
        } else {
          self.filterEntry.canLoadMore = true;
        }
        self.filterEntry.page++

        //remover mascara
        for (let request of self.entryList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentEntryText = 'Nenhum acesso para exibir';
      });
    },
    listThirdCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500).then((response) => {
        self.thirdCompanyList = response.thirdList.map(third => {
          const obj = {
            name: third.name,
            value: third.idcompany
          }

          return obj
        })
        console.log(self.thirdCompanyList)
      });
    },
    updateVisibility(value) {
      this.showBindPopup = value;
    },
    prepareToBindThird() {
      this.showBindPopup = true
    },
    bindThird() {
      let self = this
      const id = this.$route.params.id;
      if (!this.thirdToBind) {
        this.$toasted.show('Selecione um terceiro')
        return
      }
      const isApprove = self.driver.third_driver_status === 1
      if (isApprove) {
        this.approveDriverToThirdCompanyMixin(this.thirdToBind, id).then((response) => {
          console.log('aqui response', response)
          self.driver = response.third.driver
          self.$emit("done");
          self.$toasted.show(response.message);
          self.showBindPopup = false
        });
      } else {
        this.addDriverToThirdCompanyMixin(this.thirdToBind, id).then((response) => {
          console.log('aqui response', response)
          self.driver = response.data.third.driver
          self.$emit("done");
          self.$toasted.show(response.message);
          self.showBindPopup = false
        });
      }
    },
    unbindThird() {
      let self = this
      const id = this.$route.params.id
      this.deleteDriverFromThirdCompanyMixin(id).then((response) => {
        console.log('AQUI RESPONSTA', response)
        self.driver = response.data.driver
        self.$emit("done")
        self.$toasted.show(response.message)
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#driver-register {
  .third-company-actions-not-binded {
    display: flex;
    justify-content: center;
    height: 100%;
    gap: 18px;

    button {
      flex: 1;
    }
  }

  .third-company-actions-binded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    height: 100%;

    span {
      margin-right: 5px;
    }

    .app-btn-primary,
    .app-btn-orange {
      pointer-events: none;
    }

    button {
      flex: 1;
      max-width: 50%;
    }
  }
}
</style>