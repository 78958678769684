import Vue from 'vue'

Vue.filter("formatCpf", function (value) {
  if(value == null || value == '') return
  let text = new String(value);
  let primeiraParte = text.slice(0, 3);
  let segundaParte = text.slice(3, 6);
  let terceiraParte = text.slice(6, 9);
  let quartaParte = text.slice(-2);

  return primeiraParte
    .concat(".")
    .concat(segundaParte)
    .concat(".")
    .concat(terceiraParte)
    .concat("-")
    .concat(quartaParte);
});