<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import { local, server } from './js/variables.js'
import authMixin from "./mixins/auth.mixin.js";
export default {
  name: "App",
  mixins: [authMixin],
  // components: {

  // },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
/* @import "./scss/global.scss"; */
</style>
