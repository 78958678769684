import { AES, enc } from 'crypto-js';

function setLocalStorage(key, value) {
  const dataString = JSON.stringify(value)
  const encryptedData = AES.encrypt(dataString, process.env.VUE_APP_ENCRYPTION_KEY).toString()
  localStorage.setItem(key, encryptedData)
}
function getLocalStorage(key) {
  const encryptedData = localStorage.getItem(key)
  if (!encryptedData) return null
  try {
    const decryptedBytes = AES.decrypt(encryptedData, process.env.VUE_APP_ENCRYPTION_KEY)
    const decryptedDataString = decryptedBytes.toString(enc.Utf8)
    const decryptedData = JSON.parse(decryptedDataString);
    return decryptedData
  } catch (error) {
    console.error('error decrypting data', error);
    return null
  }
}

export { setLocalStorage, getLocalStorage }
