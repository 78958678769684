import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var companyMixin = {
  data: function () {
    return {
      company: {
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
        CompanyThird: {
          status: 0
        }
      },
    }
  },
  methods: {
    listCompaniesMixin(limit, page, search, order, sort, status, idpark) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        idpark: idpark,
      };

      return axios.get(server.api_url + '/companies', config)
        .then((response) => {
          return response.data.data;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getCompanyMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/companies/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.company.status = self.fixStatus(responseData.company.status);
          responseData.company.third.forEach(element => {
            element.CompanyThird.auto_registration = self.fixStatus(element.CompanyThird.auto_registration)
            element.CompanyThird.auto_request = self.fixStatus(element.CompanyThird.auto_request)
            // element.CompanyThird.status = self.fixStatus(element.CompanyThird.status)
          });
          if (responseData.company.range) {
            responseData.company.range = responseData.company.range.toString();
          }
          if (responseData.company.window_range) responseData.company.window_range = responseData.company.window_range.toString();
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveCompanyMixin(company, param, photo) {
      const self = this;
      if (photo == true && param === 'new') return
      let companyToSend = JSON.parse(JSON.stringify(company));
      if (companyToSend.zipcode) companyToSend.zipcode = self.removeMaskZipcode(companyToSend.zipcode);
      if (companyToSend.cnpj) companyToSend.cnpj = self.removeMaskCNPJ(companyToSend.cnpj);
      if (companyToSend.phone) companyToSend.phone = self.removeMaskPhone(companyToSend.phone);
      // if (!companyToSend.window_range) companyToSend.window_range = 0;
      companyToSend.status = self.fixStatus(companyToSend.status);
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          this.axios.post(server.api_url + '/companies/', {
            company: companyToSend,
          }).then((response) => {
            let responseData = response.data.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/companies/' + responseData.company.idcompany, '_self');
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          this.axios.put(server.api_url + '/companies/' + companyToSend.idcompany, {
            company: companyToSend,
          }).then((response) => {
            if (photo == '' || photo == null) {
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: response.data.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              })
            }
          })
            .catch(function (error) {
              self.showError(error);
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteCompanyMixin(id) {
      const self = this;
      this.$swal.fire({
        title: 'Realmente deseja excluir este locatário?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/companies/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/companies/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },

    //Gatezone
    AddCompanyGatezoneMixin(idcompany, idgatezone) {
      const self = this;
      return axios.post(server.api_url + '/companies/' + idcompany + '/gatezone/' + idgatezone).then((response) => {
        let responseData = response.data.data;
        return responseData;
      })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    deleteCompanyGatezoneMixin(idcompany, idgatezone) {
      const self = this;
      return axios.delete(server.url + '/companies/' + idcompany + '/gatezone/' + idgatezone).then((response) => {
        let responseData = response.data;
        return responseData;
      })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          return null;
        });
    },
    verifyCompanyGatezone(gatezoneArray, idcompany, idgatezone) {
      // console.log('verifyCompanyGatezone', idcompany ,idgatezone)
      for (let gatezone of gatezoneArray) {
        // console.log('antes do 1 if', gatezone.idgatezone, idgatezone)
        if (gatezone.idgatezone === idgatezone) {

          // console.log('passou if 1', gatezone.idgatezone == idgatezone)
          for (let company of gatezone.Companies) {
            if (company.CompanyGatezone.idcompany == idcompany) {
              return true
            }
          }
        }
      }
      return false
    },

    companiesListFiltered(alreadyAddedList) {
      var listFiltered = [];
      var list = [];
      for (let item of this.companyList) {
        if(!alreadyAddedList) list.push(item);
        else if (!alreadyAddedList.some(company => company.idcompany === item.idcompany)) {
          item.name.toLowerCase();
          list.push(item);
        }
      }
      if (this.filter.searchText == null) return list.splice(0, 40);

      for (let item of list) {
        let name = item.name.toLowerCase();
        let searchText = this.filter.searchText.toLowerCase();
        if (name.indexOf(searchText) > -1) {
          listFiltered.push(item);
        }
      }
      return listFiltered;
    },

    getCompaniesForOkInput(company) {
      let array = [];
      for (let c of company) {
        let obj = {
          name: c.name,
          value: c.idcompany
        }
        array.push(obj);
      }
      return array
    },

    getCompanyParks(company) {
      let parksNames = '';
      if (!company.Parks || company.Parks.length == 0) return '-'
      for (let park of company.Parks) {
        if (parksNames === '') parksNames = park.name;
        else parksNames = parksNames + ', ' + park.name;

      }
      if (parksNames === '') return '-'
      else return parksNames
    },

    addApplicationCompanyMixin(id, idcompany) {
      const self = this;
      return axios.post(server.url + '/applications/' + id + '/company/' + idcompany)
        .then(function (response) {
          let responseData = response.data;
          console.log(responseData)
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    deleteApplicationCompanyMixin(id, idcompany) {
      const self = this;
      return this.axios.delete(server.url + '/applications/' + id + '/company/' + idcompany)
        .then(function (response) {
          let responseData = response.data;
          console.log(responseData)
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  }
};

export default companyMixin;