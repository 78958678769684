<template>
  <div class="data-item user-item">
    <div class="row">
      <div class="data-middle"
        v-bind:class="{ 'col-md-12 ': simple, 'col-md-8 ': !simple, 'data-middle-bigger': isDriver && userItem.third_driver_status != -1 }">
        <ImageItem :path="userItem.image_photo" :isSmall="isDriver && userItem.third_driver_status != -1 ? false : true">
        </ImageItem>
        <div class="middle-infos">
          <p class="highlight">Nome: {{ userItem.name }} {{ userItem.lastname }}</p>
          <p>Telefone: {{ userItem.phone | formatPhone }}</p>
          <p>E-mail: {{ userItem.email }}</p>
          <p v-if="isDriver && userItem.third_driver_status != -1">Empresa: {{ userItem.company_name  }}</p>
        </div>
      </div>
      <div class="col-md-4 data-right" v-if="!simple">
        <p>Registro: {{ userItem.created_at | formatDate }}</p>
        <!-- <p style="display: inline-block;">Cargo: </p><p style="display: inline-block;" class="highlight">{{userJob(user.job)}}</p> -->
        <p v-if="!isDriver">Cargo: <span class="highlight">{{ userJob(userItem.job) }}</span></p>
        <p>Status: <span class="highlight" v-bind:class="userStatusClass(userItem.status)">{{
          userStatus(userItem.status)
        }}</span></p>
        <p v-if="isDriver && userItem.third_driver_status != -1">Status vínculo: <span class="highlight"
            v-bind:class="driverThirdStatusClass(userItem.third_driver_status)">{{
              driverThirdStatus(userItem.third_driver_status)
            }}</span></p>
      </div>
    </div>
    <overlay-item :route="userDetails()"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["userItem", "isDriver", 'simple'],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
    userDetails() {
      if (this.isDriver) return '/drivers/' + this.userItem.iddriver
      else return '/users/' + this.userItem.iduser
    }
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors.scss";

.user-item {
  // position: relative;

  .data-middle-bigger{
    height: 105px;
  }
}

@media only screen and (max-width: 600px) {
  .user-item {}
}
</style>