<template>
  <main-layout>
    <div id="login-sms">
      <div class="row">
        <div class="col-md-4 left-part" v-if="showLogin">
          <img
            src="/static/img/glp-logomark@2x.png"
            alt="GLP logo"
            class="logo center"
          />
          <h2>Informe o número de telefone</h2>
          <p class="description"></p>
          <ok-input
            :model="phone"
            @updated="
              (x) => {
                phone = x
              }
            "
            :label="'Telefone'"
            :mask="'(##) #####-####'"
            :placeholder="'(XX) XXXXX-XXXX'"
            :id="'phone'"
            :name="'phone'"
            @onEnter="getSms()"
          >
          </ok-input>

          <button
            class="app-btn app-btn-primary login-btn"
            id="login-btn"
            @click="getSms()"
          >
            Receber SMS
          </button>
        </div>
        <div class="col-md-4 left-part" v-else>
          <img
            src="/static/img/glp-logomark@2x.png"
            alt="GLP logo"
            class="logo center"
          />
          <h2>Validação</h2>
          <p class="description">
            Digite o número que enviamos para você por SMS, para acessar o
            sistema
          </p>
          <ok-input
            :model="smsCode"
            @updated="
              (x) => {
                smsCode = x
              }
            "
            :label="'Código'"
            :mask="'######'"
            :placeholder="'XXXXXX'"
            :id="'smsCode'"
            :name="'smsCode'"
            @onEnter="loginUser()"
          >
          </ok-input>

          <button
            class="app-btn app-btn-primary login-btn"
            id="login-btn"
            @click="loginUser()"
          >
            Entrar
          </button>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/glp-main.png" alt="GLP img"> -->
          <div
            class="image-box"
            style="background-image: url('./static/img/glp-main.png')"
          ></div>
        </div>
      </div>
    </div>
    <!-- <alert-message message="Seja bem vindo" type="success"></alert-message> -->
  </main-layout>
</template>

<script>
import driverMixin from '../../mixins/driver.mixin.js'
import MainLayout from '../../layouts/Main.vue'
import OkInput from '@/components/OkInput.vue'
// import AlertMessage from '@/components/AlertMessage.vue'
import { getLocalStorage, setLocalStorage } from '../../js/encryption.js'

export default {
  mixins: [driverMixin],
  components: {
    MainLayout,
    OkInput,
    // AlertMessage,
  },
  data() {
    return {
      slideList: [],
      phone: '',
      smsCode: '',
      password: '',
      userForgot: {
        emailToSend: '',
        emailToSendConfirm: '',
      },
      passwordInputType: 'password',
      showLogin: true,
      facebookId: null,
    }
  },
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('driverPhone')

    this.isUuid()
    this.facebookId = getLocalStorage('facebookId')
    if (this.facebookId) {
      this.loginUserFacebook()
    }
  },
  methods: {
    getSms() {
      const self = this
      let phoneCopy = JSON.parse(JSON.stringify(this.phone))
      phoneCopy = phoneCopy.replace(/\D/g, '')
      if (phoneCopy.length !== 11) {
        this.$toasted.error('Verifique se o número do celular está correto')
        return
      }

      this.loginGetSMS(phoneCopy).then((response) => {
        if (response) {
          self.showLogin = false
        } else {
          self
            .$swal({
              icon: 'info',
              title: 'Olá!',
              text: 'Não identificamos este telefone em nossos cadastros, deseja cadastrar?',
              showCancelButton: true,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Cadastrar motorista',
              cancelButtonText: 'Cancelar',
            })
            .then((result) => {
              setLocalStorage('driverPhone', phoneCopy)
              if (result.isConfirmed) self.goTo('/visitante/cadastro')
            })
        }
      })
    },
    loginUser() {
      const self = this
      let phoneCopy = JSON.parse(JSON.stringify(this.phone))
      phoneCopy = phoneCopy.replace(/\D/g, '')
      let smsCodeCopy = JSON.parse(JSON.stringify(this.smsCode))
      smsCodeCopy = smsCodeCopy.replace(/\D/g, '')
      if (smsCodeCopy.length !== 6) {
        this.$toasted.error('O código digitado está fora do padrão')
        return
      }

      this.loginWithCode(phoneCopy, smsCodeCopy).then((response) => {
        if (response) {
          self.$router.push('/visitante/home')
        }
      })
    },
    loginUserFacebook() {
      const self = this
      this.loginWithFacebook(this.facebookId).then((response) => {
        if (response) {
          self.$router.push('/visitante/home')
        }
      })
    },
    isUuid() {
      const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi //regex to verify if is uuid
      const device = getLocalStorage('device')
      if (!device || !regexExp.test(device)) {
        localStorage.removeItem('device')
        window.open('./login', '_self')
      }
    },
  },
}
</script>

<style scooped lang="scss">
@import '../../scss/colors.scss';

#login-sms {
  .left-part {
    background-color: $lightgray;
    padding: 0px 70px;
    height: 100vh;

    .logo {
      height: 45px;
      margin-top: 90px;
      margin-bottom: 90px;
      object-fit: contain;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .description {
      margin-bottom: 35px;
      font-weight: 500;
      color: $gray-xdk;
      text-align: center;
    }

    .login-btn {
      width: 100%;
      margin-top: 15px;
    }

    .forgot-password-btn {
      float: left;
      margin-top: 15px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}
</style>
