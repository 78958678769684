import axios from 'axios';
import { server } from '../js/variables.js';

var userMixin = {
  data: function () {
    return {
      user: {
        status: false,
        job: null,
        street: null,
        state: null,
        city: null,
        district: null,
        range: null,
      },
    }
  },
  mounted() {
  },
  methods: {
    listUsersMixin(limit, page, search, order, sort, status, jobs) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        jobs: jobs,
      };

      return axios.get(server.api_url + '/users', config)
        .then((response) => {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getUserMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/users/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.user.status = self.fixStatus(responseData.user.status);

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },

    saveUserMixin(user, param, photo) {
      const self = this;
      if (photo == true && param === 'new') return
      let userToSend = JSON.parse(JSON.stringify(user));
      if (userToSend.zipcode) userToSend.zipcode = self.removeMaskZipcode(userToSend.zipcode);
      if (userToSend.phone) userToSend.phone = self.removeMaskPhone(userToSend.phone);
      userToSend.status = self.fixStatus(userToSend.status);
      const jobsWithCompanyId = ['20', '21', '40', '41']
      if (!jobsWithCompanyId.includes(userToSend.job)) userToSend.idcompany = null;

      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          this.axios.post(server.api_url + '/users/', {
            user: userToSend,
          }).then((response) => {
            let responseData = response.data.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/users/' + responseData.user.iduser, '_self');
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          this.axios.put(server.api_url + '/users/' + userToSend.iduser, {
            user: userToSend,
          }).then((response) => {
            if (photo == '' || photo == null) {
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: response.data.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              })
            }
          })
            .catch(function (error) {
              self.showError(error);
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },

    deleteUserMixin(id) {
      const self = this;
      // console.log('aqui')
      this.$swal.fire({
        title: 'Realmente deseja excluir este usuário?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/users/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/users/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },
    addEmployeeMixin(employee, idcompany) {
      const self = this;
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        let employeeToSend = JSON.parse(JSON.stringify(employee));
        if (employeeToSend.phone) employeeToSend.phone = self.removeMaskPhone(employeeToSend.phone);
        employeeToSend.status = self.fixStatus(employeeToSend.status);
        employeeToSend.status = self.fixStatus2(employeeToSend.status);
        employeeToSend.idcompany = idcompany;

        return axios.post(server.api_url + '/users/', {
          user: employeeToSend,
        }).then((response) => {
          response.data.data.user.status = self.fixStatus(response.data.data.user.status);
          return response.data.data;
        })
          .catch(function (error) {
            self.showError(error);
            return null;
          });
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    updateEmployeeMixin(employee) {
      const self = this;
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        let employeeToSend = JSON.parse(JSON.stringify(employee));
        if (employeeToSend.phone) employeeToSend.phone = self.removeMaskPhone(employeeToSend.phone);
        employeeToSend.status = self.fixStatus(employeeToSend.status);
        employeeToSend.status = self.fixStatus2(employeeToSend.status);
        return axios.put(server.api_url + '/users/' + employeeToSend.iduser, {
          user: employeeToSend,
        }).then((response) => {
          response.data.data.user.status = self.fixStatus(response.data.data.user.status);
          return response.data.data;
        })
          .catch(function (error) {
            self.showError(error);
            return null;
          });
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteEmployeeMixin(employee) {
      const self = this;
      return axios.delete(server.url + '/users/' + employee.iduser)
        .then(function (response) {
          let responseData = response.data;
          console.log(responseData);
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          return null;
        });
    },
    forgotPasswordEmployeeMixin(email) {
      const self = this;
      this.axios.post(server.url + '/forgot-password/', {
        email: email
      })
        .then(function (response) {
          let responseData = response.data;
          self.$swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },




    validateCreateForm() {
      if (!this.user.name) {
        return {
          validated: false,
          message: "Por favor insira seu nome"
        };
      }
      if (this.user.name.length < 3) {
        return {
          validated: false,
          message: "Não é permitido nome com menos de 3 letras."
        };
      }

      if (!this.user.lastname) {
        return {
          validated: false,
          message: "Por favor insira seu sobrenome"
        };
      }
      if (this.user.lastname.length < 3) {
        return {
          validated: false,
          message: "Não é permitido sobrenome com menos de 3 letras."
        };
      }
      if (!this.user.email) {
        return {
          validated: false,
          message: "Por favor insira seu e-mail."
        };
      }
      if (this.user.email.length < 5) {
        return {
          validated: false,
          message: "E-mail inválido."
        };
      }

      return {
        validated: true
      };
    },
    validateUpdateForm() {
      if (!this.user.name) {
        return {
          validated: false,
          message: "Por favor insira seu nome"
        };
      }
      if (this.user.name.length < 3) {
        return {
          validated: false,
          message: "Não é permitido nome com menos de 3 letras."
        };
      }

      if (!this.user.lastname) {
        return {
          validated: false,
          message: "Por favor insira seu sobrenome"
        };
      }
      if (this.user.lastname.length < 3) {
        return {
          validated: false,
          message: "Não é permitido sobrenome com menos de 3 letras."
        };
      }

      if (!this.user.birthday) {
        return {
          validated: false,
          message: "Por favor insira sua data de nascimento"
        };
      }

      if (this.user.birthday.length < 10) {
        return {
          validated: false,
          message: "Data de nascimento inválida."
        };
      }
      var age = this.calculateAge(this.user.birthday);
      if (age < 18 || age > 120) {
        return {
          validated: false,
          message: "Data de nascimento inválida."
        };
      }

      if (!this.user.document) {
        return {
          validated: false,
          message: "Por favor insira seu CPF."
        };
      }
      if (this.user.document.length < 11) {
        return {
          validated: false,
          message: "CPF inválido."
        };
      }

      if (!this.user.gender) {
        return {
          validated: false,
          message: "Por favor insira seu gênero."
        };
      }

      if (!this.user.email) {
        return {
          validated: false,
          message: "Por favor insira seu e-mail."
        };
      }
      if (this.user.email.length < 5) {
        return {
          validated: false,
          message: "E-mail inválido."
        };
      }

      if (!this.user.phone) {
        return {
          validated: false,
          message: "Por favor insira seu telefone."
        };
      }
      if (this.user.phone.length < 5) {
        return {
          validated: false,
          message: "Telefone inválido."
        };
      }

      if (!this.user.password) {
        return {
          validated: false,
          message: "Por favor insira sua senha."
        };
      }
      if (this.user.password.length < 5) {
        return {
          validated: false,
          message: "Sua senha deve ter 6 ou mais letras (e/ou números)."
        };
      }

      return {
        validated: true
      };

    },
    validateAddressForm(address) {
      if (address.zipcode.length != 8) {
        return {
          validated: false,
          message: "CEP inválido."
        };
      }

      if (address.street.length < 3) {
        return {
          validated: false,
          message: "Logradouro inválido."
        };
      }

      if (address.number.length < 1 || address.number.length > 10) {
        return {
          validated: false,
          message: "Número da casa/edifício inválido."
        };
      }

      // if (address.complement.length < 1 ) {
      //     return false;
      // }

      if (address.apto.length > 10) {
        return {
          validated: false,
          message: "Número do apartamento inválido."
        };
      }

      // if (address.district.length < 3 ) {
      //     return false;
      // }

      if (address.city.length < 3) {
        return {
          validated: false,
          message: "Nome da cidade inválida."
        };
      }

      if (address.state.length != 2) {
        return {
          validated: false,
          message: "Informe a sigla do seu estado (RJ, SP, MG, BA ...)."
        };
      }
      console.log("validateAddressForm 10");

      return {
        validated: true
      };

    },
    showError(error) {
      console.log(error);
      if (error.response) {
        console.log("Response", error.response.data);
        console.log("Response", error.response.status);
        console.log("Response", error.response.headers);

        var answer = error.response.data;
        console.log(answer);
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
  }
};

export default userMixin;